import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast } from 'react-toastify';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import '../../../styles/Editor.css';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from "react-redux";
const SubCareerMapping = () => {
  const [formData, setFormData] = useState({
    sub_career_master_id: '',
    about: '',
    eligibility: '',
    job_roles: '',
    work_description: '',
    salary: '',
    prep_books: ''
  });

  const [subCareerMasterData, setSubCareerMasterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);
 

  useEffect(() => {
    const fetchSubCareerMaster = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/SubCareerMaster`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setSubCareerMasterData(response.data);
      } catch (error) {
        console.error('Error fetching sub-career master data:', error);
        toast.error('Failed to load sub-career data');
      }
    };

    fetchSubCareerMaster();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${Base_URL}/create/SubCareerMapping`, 
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      toast.success('Sub-career mapping successfully created!');
      setFormData({
        sub_career_master_id: '',
        about: '',
        eligibility: '',
        job_roles: '',
        work_description: '',
        salary: '',
        prep_books: ''
      });
    } catch (error) {
      console.error('Error submitting sub-career mapping data:', error);
      if (error.response?.status === 401) {
        toast.error('Unauthorized. Please login again.');
      } else {
        toast.error('Failed to create sub-career mapping');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderSection title="Create Sub Career Mapping" breadcrumb={['Career', 'Create Sub Career Mapping']} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Add Sub Career Mapping</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Select Sub Career */}
            <div className="flex flex-col">
              <label htmlFor="sub_career_master_id" className="mb-2 font-medium text-sm">Select Sub Career</label>
              <select
                id="sub_career_master_id"
                name="sub_career_master_id"
                value={formData.sub_career_master_id}
                onChange={handleChange}
                className="border border-gray-300 p-2 rounded"
                required
              >
                <option value="">-- Select Sub Career --</option>
                {subCareerMasterData.map((subCareer) => (
                  <option key={subCareer.id} value={subCareer.id}>
                    {subCareer.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Salary - Changed to simple input field */}
            <div className="flex flex-col">
              <label htmlFor="salary" className="mb-2 font-medium text-sm">Salary</label>
              <input
                type="text"
                id="salary"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter salary details"
                required
              />
            </div>

            {/* Eligibility */}
            <div className="flex flex-col">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Eligibility</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.eligibility}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, eligibility: editor.getData() }));
                }}
              />
            </div>

            {/* Job Roles */}
            <div className="flex flex-col">
              <label htmlFor="job_roles" className="mb-2 font-medium text-sm">Job Roles</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.job_roles}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, job_roles: editor.getData() }));
                }}
              />
            </div>

            {/* Preparation Books */}
            <div className="flex flex-col">
              <label htmlFor="prep_books" className="mb-2 font-medium text-sm">Preparation Books</label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.prep_books}
                onChange={(event, editor) => {
                  setFormData(prev => ({ ...prev, prep_books: editor.getData() }));
                }}
              />
            </div>
          </div>

          {/* About */}
          <div className="flex flex-col mt-4">
            <label htmlFor="about" className="mb-2 font-medium text-sm">About</label>
            <CKEditor
              editor={ClassicEditor}
              data={formData.about}
              onChange={(event, editor) => {
                setFormData(prev => ({ ...prev, about: editor.getData() }));
              }}
            />
          </div>

          {/* Work Description */}
          <div className="flex flex-col mt-4">
            <label htmlFor="work_description" className="mb-2 font-medium text-sm">Work Description</label>
            <CKEditor
              editor={ClassicEditor}
              data={formData.work_description}
              onChange={(event, editor) => {
                setFormData(prev => ({ ...prev, work_description: editor.getData() }));
              }}
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md flex items-center justify-center"
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SubCareerMapping;
