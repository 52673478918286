import axios from 'axios';
import { fetchNewsByCollege } from '../../../api/services/NewsEventService';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Base_URL, Img_URL } from '../../../apiConfig';

const NewsEvents = ({ collegeDetails, logoPath }) => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  // Fetch data from the API when the component mounts


  
  useEffect(() => {
    const fetchNews = async () => {
      
      const response = await fetchNewsByCollege(collegeDetails.university_name)
     setNews(response)
   
    }
    fetchNews();
  }, [collegeDetails.university_name]);
  const handleNewsClick = (news_title) => {
    const formattedTitle = news_title.trim().replace(/\s+/g, "-");
    navigate(`/college/particularNews/${formattedTitle}`);
  };


  // Utility function to split text into paragraphs and fetch the required ones
  const getParagraphs = (content) => {
    const paragraphs = content.split('. '); // Split by sentences
    const firstTwo = paragraphs.slice(0, 2).join('. ') + '.';
    const lastTwo = paragraphs.slice(-2).join('. ') + '.';
    return { firstTwo, lastTwo };
  };

  // Utility to safely parse and format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
  };


  return (
    <div className="py-8 px-5 bg-[#DCFFC4] overflow-x-hidden">
      {/* University Header */}
      <div className="text-black text-base font-medium flex flex-col gap-5">
        <div className="flex flex-col md:flex-row items-center justify-center gap-3 py-2">
          <div className="h-[80px] w-[80px]">
            <img
              src={logoPath}
              alt="college-logo"
              className="h-full w-full object-cover"
            />
          </div>
          <p className="text-3xl text-black font-semibold text-center ">
            {collegeDetails.university_name}
          </p>
        </div>

        <p className="text-lg text-black font-semibold mb-5 text-center ">
          News and Events
        </p>
      </div>

      {/* Render News Items in a Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {news.map((item) => (
            <div
              key={item.id}
              onClick={()=>handleNewsClick(item.title)}
              className="bg-white p-4 rounded-lg shadow-md cursor-pointer h-80 flex flex-col justify-start"
             
            >
              {/* Event Image */}
              {item.picture ? (
                <img
                  src={`${Img_URL}${item.picture}`}
                  alt={item.title}
                  className="h-32 w-full object-cover rounded-md mb-2"
                />
              ) : (
                <div className="h-32 bg-gray-300 rounded-md mb-2"></div>
              )}

              {/* Event Title (Fixed height for alignment) */}
              {item.title && (
                <h3
                  className="text-[14px] text-gray-800 font-bold mt-2 mb-2"
                  style={{ minHeight: "40px" }}
                >
                  {item.title}
                </h3>
              )}

              {/* Event Content (Fixed height and truncated) */}
              <p
                className="text-gray-600 text-sm overflow-hidden line-clamp-3"
                style={{ minHeight: "56px" }}
              >
                {item.content}
              </p>
            </div>
          ))}
        </div>

        {/* View All News Button */}
        {/* <div className="mt-6 text-left">
          <button
            onClick={() => navigate("/newsDetail")}
            className="font-bold text-xs"
            style={{
              color: "#468EFF",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "14px",
              padding: "6px 12px",
              borderRadius: "4px",
              display: "inline-block",
            }}
          >
            View All News &rarr;
          </button>
          </div> */}
    </div>
  );
};

export default NewsEvents;
