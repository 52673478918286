import React, { useEffect, useState } from "react";
import { FaFireAlt } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchNewsAdminByTitle,
  fetchNewsAnnouncements,
  fetchNewsByTitle,
} from "../api/services/NewsEventService";
import { Img_URL } from "../apiConfig";
import HatCircleLoader from "../components/Loader/HatCircleLOader";

const ParticularNews = ({ newsItem }) => {
  const { id } = useParams();
  const [currentNewsItem, setCurrentNewsItem] = useState(newsItem);
  const [trendingNews, setTrendingNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const title = id.replace(/-/g, " ");
        setLoading(true); // Start the global loader

        let newsByIdResponse, newsByCollegeResponse; // Declare variables for responses

        // Fetch news item by ID or by college based on pathname
        if (location.pathname.includes("college")) {
          newsByCollegeResponse = await fetchNewsAdminByTitle(title);

          setCurrentNewsItem(newsByCollegeResponse[0]); // Set current news item
        } else {
          newsByIdResponse = await fetchNewsByTitle(title);

          setCurrentNewsItem(newsByIdResponse[0]); // Set current news item
        }

        // Fetch trending news
        const trendingNewsResponse = await fetchNewsAnnouncements();

        // Set the state with trending news response
        const trendingItems = trendingNewsResponse
          .filter((news) => news.trending_news === 1)
          .slice(0, 4);
        setTrendingNews(trendingItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop the global loader
      }
    };

    fetchData();
  }, [id, location.pathname]);

  const handleTrendingNewsClick = (news) => {
    setCurrentNewsItem(news);
  };

  const formattedUpdatedDate = currentNewsItem
    ? new Date(currentNewsItem.updated_at).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
    : "";

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <HatCircleLoader /> {/* Show loader during API calls */}
      </div>
    );
  }

  return (
    <div className='w-full'>
      {/* Section Below Header */}
      {currentNewsItem && (
        <div className='w-full py-8 bg-[rgba(0,0,0,0.5)] mt-[40px]'>
          <h1 className='text-left text-white text-[16px] font-bold px-6'>
            {currentNewsItem.title}
          </h1>
          <p className='text-left text-white text-sm px-6 mt-2'>
            Created on {formattedUpdatedDate}
          </p>
        </div>
      )}

      {/* Main Content */}
      <div className='w-full max-w-7xl mx-auto py-12 flex flex-wrap lg:flex-nowrap mt-8 px-4 gap-8'>
        {/* Particular News Section */}
        {currentNewsItem && (
          <div className='flex-1 space-y-6'>
            <p className='text-green-600 font-bold text-lg'>
              {currentNewsItem.editor_name}
            </p>
            <p className='text-gray-500 text-sm mb-4'>
              Updated on: {formattedUpdatedDate}
            </p>

            <div
              className='text-gray-700 font-semibold p-4'
              style={{
                borderLeft: "5px solid #58CD03",
              }}
            >
              <p className='text-[24px] text-black font-[600]'>
                {currentNewsItem.title}
              </p>
            </div>

            {currentNewsItem.picture ? (
              <img
                src={`${Img_URL}${currentNewsItem.picture}`}
                alt={currentNewsItem.title}
                className='w-full max-w-[800px] h-[400px] object-cover rounded-md'
              />
            ) : (
              <div className='w-full max-w-[800px] h-[400px] bg-gray-300 rounded-md flex items-center justify-center'>
                No Image
              </div>
            )}

            <p className='text-gray-700 font-semibold leading-relaxed break-words'>
              {currentNewsItem.content}
            </p>
            <p className='text-gray-700 font-semibold leading-relaxed break-words'>
              {currentNewsItem.sub_content}
            </p>
          </div>
        )}

        {/* Trending News Section */}
        <div className='w-full lg:w-[350px] space-y-6'>
          <div className='flex items-center space-x-2'>
            <FaFireAlt className='text-black text-2xl' />
            <h2 className='text-black text-xl font-bold'>Trending News</h2>
          </div>

          <div className='space-y-4'>
            {trendingNews.map((item) => (
              <div
                key={item.id}
                onClick={() => handleTrendingNewsClick(item)}
                className='flex flex-col items-start p-4 bg-white rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow'
              >
                <div className='flex items-center'>
                  <img
                    src={`${Img_URL}/files${item.picture}`}
                    alt={item.title}
                    className='h-[100px] w-[100px] object-cover rounded-lg mr-4'
                  />
                  <div className='flex-1'>
                    <h3 className='text-black text-sm font-semibold line-clamp-2 mb-1'>
                      {item.title}
                    </h3>
                    <p className='text-gray-500 text-xs mb-2'>
                      By - {item.author_name} | {formattedUpdatedDate}
                    </p>
                  </div>
                </div>
                <p className='text-black text-[500] text-xs mt-auto'>
                  {item.sub_content}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticularNews;
