import React, { useEffect, useRef } from 'react';

// Sample notifications data
const notifications = [
  { iconBg: 'bg-green-100', iconBorder: 'border-green-500', name: 'Martin', text: 'has added custom text that is really long and should be truncated', time: '3:20 am' },
  { iconBg: 'bg-red-100', iconBorder: 'border-red-500', name: 'Jane Smith', text: 'commented on your post', time: '15m ago' },
  { iconBg: 'bg-blue-100', iconBorder: 'border-blue-500', name: 'Bob Johnson', text: 'liked your photo', time: '30m ago' },
  { iconBg: 'bg-yellow-100', iconBorder: 'border-yellow-500', name: 'Alice Davis', text: 'started following you', time: '1h ago' },
  { iconBg: 'bg-purple-100', iconBorder: 'border-purple-500', name: 'Michael Brown', text: 'shared your post', time: '3h ago' },
];

const NotificationCard = ({ onClose }) => {
  const cardRef = useRef();

  // Close the notification card when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div
      ref={cardRef}
      className="absolute top-16 right-12 w-96 bg-white border border-gray-300 rounded-lg shadow-lg"
      style={{
        right: 'calc(1rem + 2.5rem)', // Adjust this value to align with the icon's right edge
      }}
    >
      <div className="p-4">
        {notifications.map((notification, index) => (
          <div
            key={index}
            className={`flex items-center space-x-4 p-2 border-b border-gray-200 hover:bg-gray-100`}
          >
            {/* User Icon with Circle */}
            <div className={`ml-2 flex-shrink-0 w-10 h-10 rounded-full ${notification.iconBorder} ${notification.iconBg} flex items-center justify-center`}>
              {/* Classy border-only user icon */}
              <div
                className={`w-8 h-8 rounded-full border-2 ${notification.iconBorder} flex items-center justify-center`}
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                {/* Replace the following SVG with a more detailed or different one if needed */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-gray-700"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="8" r="4"></circle>
                  <path d="M4 21s1-4 8-4 8 4 8 4"></path>
                </svg>
              </div>
            </div>
            {/* Notification Text */}
            <div className="flex-grow overflow-hidden">
              <div className="flex justify-between items-center">
                {/* Name and Message Text */}
                <div
                  className="font-semibold text-black opacity-100 truncate"
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '13.125px',
                    fontWeight: 700,
                    lineHeight: '21px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {notification.name}{" "}
                  <span
                    className="font-normal"
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {notification.text}
                  </span>
                </div>
                {/* Time */}
                <div
                  className="text-sm text-gray-500 ml-2 whitespace-nowrap"
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '13.125px',
                    fontWeight: 400,
                    lineHeight: '21px',
                    marginRight: '10px',
                  }}
                >
                  {notification.time}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          className="text-center py-2 cursor-pointer"
          style={{
            color: 'rgb(106, 115, 250)',
            fontSize: '12.25px',
            fontWeight: 400,
            lineHeight: '18.25px',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          See all notifications
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
