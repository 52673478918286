import apiClient from "../apiClient"; // Import the configured Axios instance
import { API_ENDPOINT } from "../../utils/constant"; // Adjust the path as needed

/**
 * Service to send referral details.
 * @param {Object} data - Referral data to send.
 * @param {string} data.referrer_name - Name of the referrer.
 * @param {string} data.referrer_number - Referrer's phone number.
 * @param {string} data.upi_id - Referrer's UPI ID.
 * @param {string} data.city - Referrer's city.
 * @param {string} data.student_name - Student's name.
 * @param {string} data.student_number - Student's phone number.
 * @param {string} data.student_city - Student's city.
 * @returns {Promise<Object>} API response.
 */
export const referAndEarnService = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.REFER_AND_EARN, data);
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error in referAndEarnService:", error.response || error.message);
    throw error; // Rethrow the error for caller handling
  }
};
