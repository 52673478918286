import React, { useState } from 'react';
import { HiAcademicCap, HiBriefcase, HiCalendar, HiChevronRight, HiNewspaper, HiUserGroup, HiUsers } from 'react-icons/hi';
import { MdLeaderboard } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import './CmsSidebar.css';

const Sidebar = ({ isSidebarOpen }) => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0); // Add selected state
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(selectedMenuItem===0?0:null); // Add selected sub-menu state

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index); // Set selected menu item
    setSelectedSubMenuItem(null); // Reset sub-menu selection when a new menu is selected
  };

  const handleSubMenuItemClick = (subIndex) => {
    setSelectedSubMenuItem(subIndex); // Set selected sub-menu item
  };

  const menuItems = [
    { title: 'Dashboard', icon: HiAcademicCap, path: '/dashboard', items: [{title:'Analytics',path:'/dashboard/analytics'}] },
    { title: 'Lead Management', icon: MdLeaderboard, items: [
      { title: 'All Leads', path: '/dashboard/leads/all' },
      { title: 'Add Leads', path: '/dashboard/leads/add' },
   
    
      // { title: 'Edit Event', path: '/dashboard/events/edit/:id' }
    ]},
    { title: 'Events', icon: HiCalendar, items: [
      { title: 'All Events', path: '/dashboard/events/all' },
      { title: 'Add Event', path: '/dashboard/events/add' },
   
    
      // { title: 'Edit Event', path: '/dashboard/events/edit/:id' }
    ]},
    { title: 'Professors', icon: HiUserGroup, items: [
      { title: 'All Professors', path: '/dashboard/professors/all' },
      { title: 'Add Professor', path: '/dashboard/professors/add' },
    
      
      // { title: 'Edit Professor', path: '/dashboard/professors/edit' },
      // { title: 'Professor Profile', path: '/dashboard/professors/profile' }
    ]},
    { title: 'Student Testimonial', icon: HiUsers, items: [
      { title: 'All Testimonial', path: '/dashboard/testimonials/all' },
      { title: 'Add Testimonial', path: '/dashboard/testimonials/add' },
   
    ]},
    { title: 'Placements', icon: HiBriefcase, items: [
      { title: 'All Placements', path: '/dashboard/placements/all' },
      { title: 'Add Placement', path: '/dashboard/placements/add' },
  
    ]},
    // { title: 'Prospect Students', icon: HiUsers, items: [
    //   { title: 'Admission Prospect', path: '/dashboard/students/admission' },
    //   { title: 'Interested Prospect', path: '/dashboard/students/interested' },

    // ]},
    { title: 'News & Announcements', icon: HiNewspaper, items: [
      { title: 'All News', path: '/dashboard/news/all' },
      { title: 'Add News', path: '/dashboard/news/add' },

    ]},
    // { title: 'FAQs', icon: HiQuestionMarkCircle, items: [
    //   { title: 'All Faq', path: '/dashboard/faqs/all' },
    //   { title: 'Add Faq', path: '/dashboard/faqs/add' },
  
    // ]},
    // { title: 'Ambassadors', icon: HiStar, items: [
    //   { title: 'All Ambassador', path: '/dashboard/ambassadors/all' },
    //   { title: 'Add Ambassador', path: '/dashboard/ambassadors/add' },

    // ]}
  ];

  return (
    <div className={`fixed top-0 left-0 h-screen z-50 text-gray-500 transition-all duration-300 ${isSidebarOpen ? 'w-64' : 'w-20 '} scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-white`}
      style={{ transition: 'width 0.3s ease ', fontFamily: 'Poppins, sans-serif' }}
    >
      <div className="flex items-center justify-center h-16 bg-[#41B923]">
        <div  />
        {isSidebarOpen? <span className="text-white  text-xl">College Dwarka</span>:<span className="text-white  text-xl">CD</span>}
     
      </div>
      <nav className={`flex-1 !overflow-visible  py-4 space-y-1 sidebar`}>
        {menuItems.map((item, index) => (
          <div key={index} className={`${isSidebarOpen ? 'px-0' : 'px-2'} dashboard_sidebar_element`}>
            <div key={index} className="dashboard_sidebar_element ">
              <div
                className={`flex items-center justify-center px-4 py-2 text-[12.25px] font-lightbold ${isSidebarOpen ? null : 'rounded-md '} ${isSidebarOpen ? selectedMenuItem === index ? 'bg-[#f0f0fe] text-[#41B923] border-r-4 border-[#41B923] ' : '' : selectedMenuItem === index ? 'bg-[#f0f0fe] text-[#41B923] ' : ''} hover:${isSidebarOpen ? 'border-r-4 ' : 'border-r-0'} hover:border-[#41B923]  font-medium menu cursor-pointer`}
                onClick={() => {
                  handleDropdownToggle(index);
                  handleMenuItemClick(index); // Mark item as selected
                }}
              >
                <item.icon className={`mr-2 transition-all duration-300 ${isSidebarOpen ? 'text-xl' : 'text-2xl'}  ${selectedMenuItem === index ? 'text-[#41B923]' : 'text-gray-500'} `} />
                {isSidebarOpen ? item.title : null}
                {isSidebarOpen ? item.items.length >= 0 && (
                  <HiChevronRight
                    className={`ml-auto  transition-transform duration-300 ${openDropdown === index ? 'rotate-90' : ''} ${selectedMenuItem === index ? 'text-[#41B923]' : 'text-gray-500'} `}
                  />
                ) : null}
              </div>
              {isSidebarOpen && openDropdown === index && item.items.length > 0 && (
                <div className="pl-6 mt-1">
                  {item.items.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.path}
                      className={`flex items-center px-3 py-2 z-10 text-[12.25px] font-medium block ${location.pathname === subItem.path ? 'text-[#41B923]' : 'text-gray-500'} hover:text-[#41B923]`}
                      state={{ mainTitle: item.title, subTitle: subItem.title }}
                      onClick={() => handleSubMenuItemClick(subIndex)}
                    >
                      <HiChevronRight className={`mr-2 ${location.pathname === subItem.path ? 'text-[#41B923]' : 'text-gray-500'} `} />
                      {subItem.title}
                    </Link>
                  ))}
                </div>
              )}
              {!isSidebarOpen && item.items.length > 0 && (
                <div className="submenu">
                  <ul>
                    {item.items.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          className={`block rounded-md px-3 py-1 text-sm font-medium ${location.pathname === subItem.path ? 'text-[#41B923]' : 'text-[#737b8b]'} hover:text-[#41B923]`}
                          state={{ mainTitle: item.title, subTitle: subItem.title }}
                          onClick={() => handleSubMenuItemClick(subIndex)}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    ))
                    }
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
