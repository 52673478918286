import React, { useState } from 'react';
import './qa-card.css';

const QACard = ({ number, question, answer }) => {
    const [showAnswer, setShowAnswer] = useState(false);

    return (
         <div className="qa_element mb-5 relative pl-8"> {/* Add `pl-8` to give space for the serial number */}
            <div className="flex justify-between items-center">
                {/* Serial number */}
                <span className="absolute left-0 text-[#3ACA2E] font-medium">{number}.</span>
                {/* Question */}
                <p className="text-base text-[#3ACA2E] font-medium mb-2">{question}</p>
                {/* Toggle Button */}
                <div className="cursor-pointer" onClick={() => setShowAnswer(!showAnswer)}>
                    {showAnswer ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="#3ACA2E" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="#3ACA2E" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                </div>
            </div>
            {showAnswer && (
                <div className="pb-2 text-base">
                    <p>{answer}</p>
                </div>
            )}
        </div>
    );
}

export default QACard;
