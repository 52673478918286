import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Base_URL, Img_URL } from '../../../apiConfig';
import college from '../../../assets/landing/college.png';

import EnquiryForm from '../../Forms/EnquiryForm';

const Colleges = () => {
    const [colleges, setColleges] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCollege, setSelectedCollege] = useState('');
    const navigate = useNavigate();
    const sliderRef = useRef(null);

    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const response = await fetch(`${Base_URL}/universities/popular/colleges`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    const processedData = data.map(item => ({
                        ...item,
                        pictures: JSON.parse(item.pictures),
                    }));
                    setColleges(processedData);
                } else {
                    setColleges([]);
                }
            } catch (error) {
                console.error("Error fetching universities:", error);
            }
        };

        fetchUniversities();
    }, []);

    const handleExploreClick = (college) => {
        const universityNameSlug = encodeURIComponent(college.university_name.trim().toLowerCase().replace(/\s+/g, '-'));
        navigate(`/college/${universityNameSlug}`);
    };

    const openModal = (collegeName) => {
        setSelectedCollege(collegeName);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const goToPrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    function SamplePrevArrow({ className, style, onClick }) {
        return (
            <div
                className={`${className} prev-arrow`}
                style={{
                    ...style,
                    display: "block",
                    position: "absolute",
                    left: 0,
                    zIndex: 10,
                    cursor: "pointer",
                }}
                onClick={onClick || goToPrev}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000000">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
            </div>
        );
    }

    function SampleNextArrow({ className, style, onClick }) {
        return (
            <div
                className={`${className} next-arrow`}
                style={{
                    ...style,
                    display: "block",
                    position: "absolute",
                    right: 0,
                    zIndex: 10,
                    cursor: "pointer",
                }}
                onClick={onClick || goToNext}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#000000">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </div>
        );
    }

    const settings = {
        customPaging: (i) => (
            <a>
                <div className="dot"></div>
            </a>
        ),
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        ref: sliderRef,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2, centerMode: false, centerPadding: '0' } },
            { breakpoint: 768, settings: { slidesToShow: 1, centerMode: true, centerPadding: '0' } },
        ],
        appendDots: (dots) => (
            <div>
                <ul className="custom-dots flex justify-center items-center space-x-2 mt-5">
                    {dots}
                </ul>
            </div>
        ),
    };

    return (
        <div className="overflow-hidden">
            <div className="bg-[#002147] p-5 text-center">
                <p className="text-white font-medium text-base mb-8">POPULAR UNIVERSITIES</p>
                <p className="text-white font-semibold text-lg mb-24">List of Popular Universities to KICK-START your JOURNEY</p>
            </div>

            <div className="relative -translate-y-32 mt-12">
                <Slider {...settings}>
                    {colleges.filter((collegeItem) => collegeItem.is_active === 1).length > 0 ? (
                        colleges
                            .filter((collegeItem) => collegeItem.is_active === 1)
                            .map((collegeItem, index) => (
                                <div className="flex justify-center items-center px-10 py-2" key={index}>
                                    <div className="relative w-full h-[250px] rounded-[20px] overflow-hidden bg-gray-200">
                                        <img
                                            src={`${Img_URL}${collegeItem.pictures[0]}`}
                                            alt={collegeItem.name}
                                            onClick={() => handleExploreClick(collegeItem)}
                                            className="w-full h-full object-cover cursor-pointer"
                                            onError={(e) => {
                                                e.target.src = college;
                                            }}
                                        />
                                        <div className="absolute bottom-0 left-0 w-full p-3 bg-[rgb(0,33,71)] bg-opacity-75">
                                            <p
                                                className="bg-white rounded-[20px] px-3 py-1 mb-3 font-medium text-center cursor-pointer"
                                                onClick={() => handleExploreClick(collegeItem)}
                                            >
                                                {collegeItem.university_name}
                                            </p>
                                            <button
                                                className="bg-[#3ACA2E] text-white rounded-[20px] px-3 py-1 w-full"
                                                onClick={() => openModal(collegeItem.university_id)}
                                            >
                                                Apply Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                    ) : (
                        <div className="text-center p-5 text-gray-500">No popular universities available at the moment.</div>
                    )}
                </Slider>
                <p
                    className="text-[#57CC02] font-dm-sans sm:text-[12px] md:text-[10px] lg:text-[16px] font-[500] leading-[20.83px] cursor-pointer text-center flex justify-center mt-4"
                    onClick={() => navigate('/colleges/popular-colleges')}
                >
                    View More
                </p>
            </div>

            {isModalOpen && (
                <EnquiryForm
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    selectedCollege={selectedCollege}
                />
            )}
        </div>
    );
};

export default Colleges;
