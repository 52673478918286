import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux"; // Import useSelector


const EditAdminNews = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const news = location.state; // Get the news data passed from AllAdminNews
  const [picture, setPicture] = useState(null);
  const [formData, setFormData] = useState({
    university_name: news?.university_name || "",
    title: news?.title || "",
    content: news?.content || "",
    event_date: news?.event_date ? news.event_date.split('T')[0] : ""
  });
  const [loading, setLoading] = useState(false); // Track loading state
  const { token } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'picture') {
      setPicture(files[0]);
    } 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    try {
      const data = new FormData();
      data.append('title', formData.title);
      data.append('content', formData.content);
      data.append('event_date', formData.event_date);
      if (picture) data.append('picture', picture);

      await axios.put(`${Base_URL}/admin-news-announcements/${news.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
       'authorization': `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });

      toast.success("News updated successfully!");
      navigate("/admin/news/all");
    } catch (error) {
      console.error("Failed to update news:", error.response?.data || error.message);
      toast.error("Failed to update news. Please try again.");
      setLoading(false)
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div className="bg-gray-100 p-6 min-h-screen">
      <HeaderTitle mainTitle="News And Announcements" subTitle="Edit News" />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Edit News</h2>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Text Input Fields */}
          
            <div className="flex flex-col">
                <label htmlFor="title" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="content" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Content</label>
                <textarea
                  id="content"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="announcement_date" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Announcement Date</label>
                <input
                  type="date"
                  id="announcement_date"
                  name="event_date"
                  value={formData.event_date}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                  required
                />
              </div>
          
           

            {/* Logo and Pictures Upload */}
           
              <div  className="flex flex-col md:col-span-2">
              <label htmlFor="picture" className="mb-2 font-medium text-sm">Upload Picture</label>
                <input
                  type="file"
                  id="picture"
                  name="picture"
                  onChange={handleFileChange}
                  className="border border-gray-300 rounded"
      
                />
              </div>
          
          </div>

          <button type="submit" className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
            {loading ? ( // Show spinner when loading
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Update News'
            )}
          </button>
        </form>

        {/* Toast Container */}
        <ToastContainer />
      </div>
    </div>
  )
}

export default EditAdminNews