import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import newsletter from '../../../assets/landing/newsletter.png';

const Newsletter = () => {
  const [number, setNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async () => {
    if (isSubmitting) return;

    // Validate phone number: Must be exactly 10 digits
    if (!number || number.length !== 10) {
      toast.error('Please enter a valid 10-digit phone number', {
       
        autoClose: 2000,
        // Add unique ID to prevent duplicate toasts
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${Base_URL}/subscribe`, {
        sub_Phone: number,
      });

      if (response.data) {
        toast.success('Successfully subscribed to newsletter!', {
          
          autoClose: 2000,
          // Add unique ID to prevent duplicate toasts
        });
        setNumber('');
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Failed to subscribe. Please try again later.';
      toast.error(errorMessage, {
      
        autoClose: 2000,
        // Add unique ID to prevent duplicate toasts
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="relative h-auto md:h-[400px] flex flex-col md:flex-row justify-center items-center py-8 px-5 overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full z-[-1]">
          <img src={newsletter} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col items-center">
          <p className="text-white text-lg font-semibold mb-5">NEWSLETTER</p>
          <p className="text-white text-base text-center mb-6 w-[346px] h-[42px] overflow-hidden text-ellipsis md:mb-10 md:w-auto md:h-auto">
            Stay in the Loop: Get the Latest Buzz on Campus News and Upcoming Events!
          </p>

          <div className="relative flex justify-center items-center bg-white rounded-[10px] mb-12 overflow-hidden w-[350px]">
            <input
              type="tel"
              placeholder="Enter Contact No."
              value={number}
              onChange={(e) => setNumber(e.target.value.replace(/[^0-9]/g, ''))}
              maxLength={10}
              className="w-[300px] h-[40px] border-0 px-3 focus:outline-none"
              required
            />
            <button
              className="text-white text-base bg-[#57CC02] h-[40px] flex items-center px-3 hover:bg-[#4bb802] transition-colors cursor-pointer"
              onClick={handleSubscribe}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Subscribing...' : 'Subscribe'}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        
      />
    </>
  );
};

export default Newsletter;