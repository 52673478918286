import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';

const AddTestimonial = ({ onTestimonialAdded }) => {
  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id;

  const [formData, setFormData] = useState({
    university_id: login_id || 1, // Use login_id or default to 1
    student_name: "",
    course: "",
    testimonial_text: "",
  });
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("No file chosen");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Track loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    } else {
      setImageName("No file chosen");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Set loading to true

    try {
      const data = new FormData();
      data.append("university_id", login_id); // Use login_id dynamically
      data.append("student_name", formData.student_name);
      data.append("course", formData.course);
      data.append("testimonial_text", formData.testimonial_text);
      if (image) {
        data.append("profile_picture", image);
      }

      const response = await axios.post(
        `${Base_URL}/student-testimonials`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const newTestimonialId = response.data.id; // Capture the ID from the response

      // Pass the ID to the parent or other components
      if (onTestimonialAdded) {
        onTestimonialAdded(newTestimonialId);
      }

      // Delay before resetting form state and stopping loading
      setTimeout(() => {
        toast.success("Testimonial added successfully!");
        setFormData({
          student_name: "",
          course: "",
          testimonial_text: "",
        });
        setImage(null);
        setImageName("No file chosen");
        setLoading(false); // Stop loading
      }, 2000); // Adjust delay as needed
    } catch (error) {
      console.error(
        "Failed to add testimonial:",
        error.response?.data || error.message
      );
      toast.error("Failed to add testimonial.");
      setLoading(false); // Stop loading if an error occurs
    }
  };

  return (
    <div className='bg-gray-100 p-6 rounded-lg shadow-md h-screen'>
      <HeaderSection
        title='Add Testimonial'
        breadcrumb={["Testimonial", "Add Testimonial"]}
      />
      <div className='border border-gray-300 bg-white p-4 rounded-md'>
        <div className='flex justify-between items-center border-b border-gray-300 pb-2 mb-4'>
          <h2 className='text-[#312a2a] text-[17.25px] font-[500] font-poppins'>
            Basic Info
          </h2>
        </div>

        {error && <div className='text-red-600 mb-4'>{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <label
                htmlFor='student_name'
                className='text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]'
              >
                Student Name
              </label>
              <input
                type='text'
                id='student_name'
                name='student_name'
                value={formData.student_name}
                onChange={handleChange}
                className='appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none'
                required
              />
            </div>
            <div className='flex flex-col'>
              <label
                htmlFor='course'
                className='text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]'
              >
                Course
              </label>
              <input
                type='text'
                id='course'
                name='course'
                value={formData.course}
                onChange={handleChange}
                className='appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none'
                required
              />
            </div>
            <div className='flex flex-col'>
              <label
                htmlFor='testimonial_text'
                className='text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[7px]'
              >
                Testimonial
              </label>
              <textarea
                id='testimonial_text'
                name='testimonial_text'
                value={formData.testimonial_text}
                onChange={handleChange}
                className='appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none'
                required
              />
            </div>
            <div className='flex flex-col'>
              <label
                htmlFor='profile_picture'
                className='text-[#312a2a] cursor-default inline-block font-poppins text-[0.875rem] font-[500] mb-[6px]'
              >
                Upload Profile Picture
              </label>
              <div className='flex border border-gray-300 rounded-lg overflow-hidden'>
                <label className='flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins'>
                  <span className='text-[12.25px]'>Choose File</span>
                  <input
                    type='file'
                    id='profile_picture'
                    name='profile_picture'
                    onChange={handleImageChange}
                    className='hidden'
                  />
                </label>
                <div className='flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]'>
                  {imageName || "No file chosen"}
                </div>
              </div>
            </div>
          </div>
          <button
            type='submit'
            className='mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md font-poppins flex items-center justify-center'
            disabled={loading} // Disable the button while loading
          >
            {loading ? (
              <div className='flex justify-center items-center'>
                <svg
                  className='animate-spin h-5 w-5 text-white mr-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddTestimonial;
