
import React from 'react'
import {  HiChevronRight } from 'react-icons/hi';

const HeaderTitle = ({mainTitle,subTitle}) => {
  return (
   <div className=" flex flex-wrap justify-between  bg-white p-4 my-2 rounded-md">
 
    <div className="div">
    <h2 className="text-[#41B923] text-lg font-semibold">{subTitle}</h2>
    </div>

        <div className="flex items-center justify-center">
            <h6 className='text-xs font-semibold text-gray-600'>{mainTitle}</h6> 
            <HiChevronRight color='gray' />
            <h6 className='text-xs font-semibold text-[#41B923]'>{subTitle}</h6> 
        </div>

   </div>
  )
}

export default HeaderTitle