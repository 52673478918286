import 'chart.js/auto'; // Import chart.js for charts
import React from 'react';
import { Doughnut, Line } from 'react-chartjs-2';

const Analytics = () => {
  // Data for Doughnut Charts (New Leads and Lead Interactions)
  const totalLeads = 50;
  const doughnutDataNewLeads = {
    labels: ['Unassigned', 'Assigned', 'Accepted'],
    datasets: [
      {
        data: [12, 16, 20], // Dummy data
        backgroundColor: ['#F44336', '#FFEB3B', '#4CAF50'],
        hoverBackgroundColor: ['#E53935', '#FDD835', '#43A047'],
      },
    ],
  };

  const doughnutDataLeadInteractions = {
    labels: ['Today', 'Missed', 'Upcoming'],
    datasets: [
      {
        data: [12, 16, 20], // Dummy data
        backgroundColor: ['#4CAF50', '#F44336', '#FFEB3B'],
        hoverBackgroundColor: ['#43A047', '#E53935', '#FDD835'],
      },
    ],
  };

  // Data for Line Chart (Leads Engagement)
  const lineDataEngagement = {
    labels: ['2', '4', '6', '8', '10', '12', '14', '16', '18', '20'], // Dummy days
    datasets: [
      {
        label: 'Leads Engagement',
        data: [30, 45, 35, 64, 48, 55, 60, 70, 65, 58], // Dummy data
        fill: true,
        backgroundColor: 'rgba(66, 165, 245, 0.2)',
        borderColor: 'rgba(66, 165, 245, 1)',
      },
    ],
  };
  const doughnutOptionsWithTotal = {
    plugins: {
      legend: {
        display: false // Hide the legend
      },
      // Custom plugin to add the center text
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";

        var text = `${totalLeads} Total`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
    cutout: '50%', // To make the center area larger
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Page Title */}
      <h1 className="text-3xl text-center font-semibold mb-6">CRM Dashboard</h1>

      {/* Cards Row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* New Leads */}
        <div>
          <div className="rounded-t-lg bg-white p-2 flex items-center shadow-md "> <h2 className="text-lg font-semibold ">New Leads</h2></div>
          <hr/>
        <div className="bg-white shadow-md rounded-b-lg p-4">

          <div className="flex items-center justify-between">
            <div className="w-1/2 p-6 flex justify-center items-center">
              <Doughnut data={doughnutDataNewLeads} options={doughnutOptionsWithTotal} />
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                <div className="w-[110px] border border-red-400 rounded p-2 "><p className="text-gray-700 text-xs">Unassigned <span className="font-bold">12</span></p></div>

              </div>
              <div className="flex items-center space-x-2">
                <div className="w-[110px] border border-yellow-400 rounded p-2"><p className="text-gray-700 text-xs">Assigned <span className="font-bold">16</span></p></div>

              </div>
              <div className="flex items-center space-x-2">
                <div className="w-[110px] border border-green-400 rounded p-2 "> <p className="text-gray-700 text-xs">Accepted <span className="font-bold">20</span></p></div>

              </div>
            </div>
          </div>
        </div>
        </div>
    

        {/* Lead Interactions */}
        <div>
        <div className="rounded-t-lg bg-white p-2 flex items-center shadow-md "> <h2 className="text-lg font-semibold ">Leads Interactions</h2></div>
        <hr/>
        <div className="bg-white shadow-md rounded-b-lg p-4">

          <div className="flex items-center justify-between">
             <div className="w-1/2 p-6 flex justify-center items-center">
              <Doughnut data={doughnutDataLeadInteractions} options={doughnutOptionsWithTotal} />
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
              <div className="w-[110px] border border-green-400 rounded p-2 "> <p className="text-gray-700 text-xs">Today <span className="font-bold">20</span></p></div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-[110px] border border-red-400 rounded p-2 "><p className="text-gray-700 text-xs">Missed <span className="font-bold">16</span></p></div>
              </div>
              <div className="flex items-center space-x-2">
              <div className="w-[110px] border border-yellow-400 rounded p-2 "><p className="text-gray-700 text-xs">Upcoming <span className="font-bold">12</span></p></div>
              </div>
            </div>
          </div>
        </div>
        </div>
        

        {/* October Revenue */}
<div>
<div className="rounded-t-lg bg-white p-2 flex items-center  justify-between "> <h2 className="text-lg font-semibold ">October Revenue</h2> <div className="text-lg font-bold mb-2">$22,370</div></div>
<div className="bg-white shadow-md rounded-lg p-4">

         
          <p className="text-green-600">+0.45%</p>
          <Line data={lineDataEngagement} />
        </div>
</div>

       
      </div>

      {/* Leads Engagement Line Chart */}
      <div className="bg-white shadow-md rounded-lg p-4 mt-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Leads Engagement</h2>
          <div className="relative">
            <select className="border border-gray-300 rounded-md p-2">
              <option>October</option>
              <option>November</option>
              <option>December</option>
            </select>
          </div>
        </div>
        <Line data={lineDataEngagement} />
      </div>
    </div>
  );
};

export default Analytics;
