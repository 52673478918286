import React, { useState ,useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux"; // Import useSelector

const EditPlacements = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const placement = location.state;

  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);

  // State for the form data
  const [formData, setFormData] = useState({
    university_name: placement?.university_name || "",
    placement_desc: placement?.placement_desc || "",
    company_master_id: placement?.company_master_ids || [],
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [loading, setLoading] = useState(false); // Track loading state

  // Fetch companies and pre-select existing companies for the placement
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        // Fetch all companies
        const response = await axios.get(`${Base_URL}/get/company_master`);
        const companyOptions = response.data.map((comp) => ({
          value: comp.id,
          label: comp.name,
        }));
        setCompanies(companyOptions);

        // Pre-select companies based on the placement's company_details
        const preSelected = placement.company_details.map((comp) => ({
          value: comp.id,
          label: comp.name,
        }));
        setSelectedCompanies(preSelected);
      } catch (error) {
        toast.error("Failed to load companies");
      }
    };

    fetchCompanies();
  }, [placement]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCompanyChange = (selectedOptions) => {
    setSelectedCompanies(selectedOptions || []); // Update selected companies
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updatedPlacement = {
        placement_desc: formData.placement_desc,
        company_master_id: selectedCompanies.map((company) => company.value) // Comma-separated IDs
      };

      // Make PUT request to update placement with authorization and credentials
      await axios.put(`${Base_URL}/placement-admin/${placement.id}`, updatedPlacement, {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });

      toast.success("Placement updated successfully!");
      setTimeout(() => {
        navigate("/admin/placement/all"); // Redirect after success
      }, 2000);
    } catch (error) {
      toast.error("Failed to update placement.");
      console.error("Failed to update placement:", error.response?.data || error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle="Placements" subTitle="Edit Placement" />

      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="university_name" className="mb-2 font-medium text-sm">
                University Name
              </label>
              <input
                type="text"
                id="university_name"
                name="university_name"
                value={formData.university_name}
                className="border border-gray-300 p-2 rounded bg-gray-200"
                disabled
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="company_master_id" className="mb-2 font-medium text-sm">
                Edit Companies
              </label>
              <Select
                options={companies}
                value={selectedCompanies}
                onChange={handleCompanyChange}
                placeholder="Select one or more companies"
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
              />
            </div>

            {/* Placement Description (CKEditor) */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="placement_desc" className="mb-2 font-medium text-sm">
                Placement Description
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.placement_desc}
                onChange={(event, editor) => {
                  setFormData({ ...formData, placement_desc: editor.getData() });
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </form>

        {/* Toast for notifications */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditPlacements;
