import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // Import useSelector
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import HeaderTitle from "../../dashboard/HeaderTitle";

const AddUniversityProgram = () => {
  const navigate = useNavigate();
  const [programs, setPrograms] = useState([]); // To store the fetched program values
  const [universities, setUniversities] = useState([]); // To store the fetched university values
  const [formData, setFormData] = useState({
    program_fees: "",
    program_duration: "",
    study_mode: "",
    exams_accepted: [],
    eligibility: "",
    program_details: "",
    university_id: null,
    program_master_id: null,
  });

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [selectedStreamName, setSelectedStreamName] = useState(""); // To store the selected stream name

  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get-join-where`, {
          params: {
            options: JSON.stringify({
              table: "program_master",
              columns: [
                "program_master.*",
                "streams.id as stream_id",
                "streams.stream_name",
              ],
              joins: [
                {
                  table: "streams",
                  on: "streams.id = program_master.stream_id",
                  type: "LEFT",
                },
              ],
            }),
          },
        });
        const programOptions = response.data.map((program) => ({
          value: program.id,
          label: program.program_master_name,
          streamName: program.stream_name, // Include stream name in the options
        }));
        setPrograms(programOptions);
      } catch (error) {
        toast.error("Failed to fetch programs", error.message);
        console.error("Failed to fetch programs:", error);
      }
    };

    const fetchUniversities = async () => {
      try {
        const response = await axios.get(
          `${Base_URL}/get-values-array/universities`
        );
        const universityOptions = response.data.map((university) => ({
          value: university[0],
          label: university[1],
        }));
        setUniversities(universityOptions);
      } catch (error) {
        toast.error("Failed to fetch universities");
        console.error("Failed to fetch universities:", error);
      }
    };

    fetchPrograms();
    fetchUniversities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProgramChange = (selectedOption) => {
    setFormData({ ...formData, program_master_id: selectedOption.value });
    setSelectedStreamName(selectedOption.streamName); // Set the stream name
  };

  const handleUniversityChange = (selectedOption) => {
    setFormData({ ...formData, university_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newProgram = {
      ...formData,
      exams_accepted: formData.exams_accepted
        .split(",")
        .map((exam) => exam.trim()),
    };

    try {
      await axios.post(`${Base_URL}/programs`, newProgram, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`, // Add token to authorization header
        },
        withCredentials: true, // Enable withCredentials
      });
      toast.success("University Program added successfully!");
    } catch (error) {
      toast.error(
        `Failed to add university program..${error.response.data.message}`
      );

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 min-h-screen'>
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />

        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label className='mb-2 font-medium text-sm'>
                  Select Program Master
                </label>
                <Select
                  options={programs}
                  value={programs.find(
                    (program) => program.value === formData.program_master_id
                  )}
                  onChange={handleProgramChange}
                  placeholder='Select Program'
                  className='basic-single'
                />
              </div>
              <div className='flex flex-col'>
                <label className='mb-2 font-medium text-sm'>Stream Name</label>
                <input
                  type='text'
                  value={selectedStreamName}
                  disabled
                  className='border border-gray-300 p-2 rounded bg-gray-100'
                />
              </div>

              <div className='flex flex-col'>
                <label className='mb-2 font-medium text-sm'>
                  Select University
                </label>
                <Select
                  options={universities}
                  value={universities.find(
                    (university) => university.value === formData.university_id
                  )}
                  onChange={handleUniversityChange}
                  placeholder='Select University'
                  className='basic-single'
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='program_fees'
                  className='mb-2 font-medium text-sm'
                >
                  Program Fees
                </label>
                <input
                  type='text'
                  id='program_fees'
                  name='program_fees'
                  value={formData.program_fees}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='program_duration'
                  className='mb-2 font-medium text-sm'
                >
                  Program Duration
                </label>
                <input
                  type='text'
                  id='program_duration'
                  name='program_duration'
                  value={formData.program_duration}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='study_mode'
                  className='mb-2 font-medium text-sm'
                >
                  Study Mode
                </label>
                <input
                  type='text'
                  id='study_mode'
                  name='study_mode'
                  value={formData.study_mode}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='exams_accepted'
                  className='mb-2 font-medium text-sm'
                >
                  Exams Accepted
                </label>
                <textarea
                  id='exams_accepted'
                  name='exams_accepted'
                  value={formData.exams_accepted}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  rows='4'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='eligibility'
                  className='mb-2 font-medium text-sm'
                >
                  Eligibility
                </label>
                <textarea
                  id='eligibility'
                  name='eligibility'
                  value={formData.eligibility}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  rows='4'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='program_details'
                  className='mb-2 font-medium text-sm'
                >
                  Program Details
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.program_details}
                  onChange={(event, editor) =>
                    setFormData({
                      ...formData,
                      program_details: editor.getData(),
                    })
                  }
                />
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            >
              {loading ? (
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                "Add University Program"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUniversityProgram;
