// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  data: null,  // We'll store the entire response here
};

const collegeAuth = createSlice({
  name: 'collegeAuth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.data = action.payload;  // Save the entire response in the state
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.data = null;
    },
  },
});

export const { loginSuccess, logout } = collegeAuth.actions;
export default collegeAuth.reducer;