import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Base_URL } from "../../../apiConfig";
import HeaderSection from "../../../dashboard/sidebarpages/headerSection/HeaderSection";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditSubCareerForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [careers, setCareers] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    career_master_id: "",
    created_by: 1,
  });

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("No file chosen");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCareers = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/career_master`);
        setCareers(response.data);
      } catch (error) {
        console.error("Error fetching careers:", error);
        toast.error("Failed to fetch careers");
      }
    };

    const subCareerData = location.state;
    if (subCareerData) {
      setFormData({
        name: subCareerData.name,
        career_master_id: subCareerData.career_master_id,
        created_by: subCareerData.created_by,
      });
    }

    fetchCareers();
  }, [location]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append("picture", image);
      }

      const response = await axios.put(
        `${Base_URL}/update-upload/SubCareerMaster/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Sub Career data updated successfully!");
      setTimeout(() => {
        navigate("/admin/career/allSubCareer");
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error(
        "Failed to update sub career data:",
        error.response?.data || error.message
      );
      toast.error("Failed to update sub career data. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className='bg-gray-100 p-6 rounded-lg shadow-md'>
      <HeaderSection
        title='Edit Sub Career'
        breadcrumb={["Career", "Edit Sub Career"]}
      />
      <div className='border border-gray-300 bg-white p-4 rounded-md'>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <label
                htmlFor='name'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Sub Career Name
              </label>
              <input
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='career_master_id'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Career
              </label>
              <select
                id='career_master_id'
                name='career_master_id'
                value={formData.career_master_id}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              >
                <option value=''>Select Career</option>
                {careers.map((career) => (
                  <option key={career.id} value={career.id}>
                    {career.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='picture'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Upload Picture
              </label>
              <div className='flex border border-gray-300 rounded-md mt-2 overflow-hidden'>
                <label className='flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]'>
                  <span>Choose File</span>
                  <input
                    type='file'
                    id='picture'
                    name='picture'
                    onChange={handleImageChange}
                    className='hidden'
                  />
                </label>
                <div className='flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]'>
                  {imageName}
                </div>
              </div>
            </div>
          </div>

          <button
            type='submit'
            className='mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md flex items-center justify-center'
            disabled={loading}
          >
            {loading ? (
              <div className='flex justify-center items-center'>
                <svg
                  className='animate-spin h-5 w-5 text-white mr-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                  ></path>
                </svg>
                Updating...
              </div>
            ) : (
              "Update"
            )}
          </button>
          {error && <div className='text-red-600 mt-2'>{error}</div>}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditSubCareerForm;
