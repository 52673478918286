import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

const EditUniPrograms = () => {
  const navigate = useNavigate();
  const { programId } = useParams();
  const location = useLocation();
  const { item, mainTitle, subTitle } = location.state || {};
  const [formData, setFormData] = useState({
    program_master_name: item?.program_master_name || '',
    stream_id: item?.stream_id || ''
  });
  const [streams, setStreams] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth); // Access token from Redux store

  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get-values-array/streams`, {
          headers: {
            Authorization: `Bearer ${token}` // Add Authorization header
          }
        });
        const streamOptions = response.data.map((stream) => ({
          value: stream[0],
          label: stream[1],
        }));
        setStreams(streamOptions);
      } catch (error) {
        console.error('Failed to fetch streams:', error);
        toast.error('Failed to load stream options.');
      }
    };

    fetchStreams();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleStreamChange = (selectedOption) => {
    setFormData({ ...formData, stream_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const updatedProgram = { ...formData };
      await axios.put(`${Base_URL}/update/program_master/${programId}`, updatedProgram, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}` // Add Authorization header
        },
      });
      toast.success('Program updated successfully!');
      setTimeout(() => {
        navigate('/admin/universityMaster/allMaster', { state: { selectedType: "program_master" } });
      }, 2000);
    } catch (error) {
      toast.error('Failed to update program.');
      console.error('Failed to update program:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="program_master_name" className="mb-2 font-medium text-sm">Program Name</label>
                <input
                  type="text"
                  id="program_master_name"
                  name="program_master_name"
                  value={formData.program_master_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="stream_id" className="mb-2 font-medium text-sm">Select Stream</label>
                <Select
                  options={streams}
                  value={streams.find(stream => stream.value === formData.stream_id)}
                  onChange={handleStreamChange}
                  placeholder={'Select Stream'}
                  className="basic-single"
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
              {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Updating...
                </div>
              ) : (
                'Update Program'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditUniPrograms;
