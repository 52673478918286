import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import LoginSignupService from '../../api/services/LoginSignupService';// Import the service

// Async thunk for login/signup
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ formData, actionType }, { rejectWithValue }) => {
    try {
      // Use AuthenticationService for login or signup
      const response = await LoginSignupService.loginOrSignup(formData, actionType);
      return response; // Return the response (which includes user data and token)
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    registration_id: null,
    reg_type: null,
    name: null,
    email: null,
    mobilenumber: null,
    token: Cookies.get('token') || null, // Retrieve token from cookies on initial load
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.registration_id = null;
      state.reg_type = null;
      state.token = null;
      state.name = null;
      state.email = null;
      state.mobilenumber = null;
    LoginSignupService.logout(); // Clear token using the service
    },
    clearAuthError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;

        // Extract data from the response
        const { token, registration_id, reg_type, name, email, mobilenumber } = action.payload;

        state.token = token;
        state.registration_id = registration_id;
        state.reg_type = reg_type;
        state.name = name;
        state.email = email;
        state.mobilenumber = mobilenumber;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, clearAuthError } = authSlice.actions;
export default authSlice.reducer;
