import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    selectedDegree: [],
    selectedCity: [],
    selectedSpecialization: [],
    selectedState: [],
  },
  reducers: {
    toggleFilter: (state, action) => {
      const { filterType, value } = action.payload;

      switch (filterType) {
        case "Degree":
          if (!Array.isArray(state.selectedDegree)) {
            state.selectedDegree = []; // Safeguard for unexpected types
          }
          state.selectedDegree = state.selectedDegree?.includes(value)
            ? state.selectedDegree.filter((item) => item !== value)
            : [...state.selectedDegree, value];
          break;

        case "City":
          if (!Array.isArray(state.selectedCity)) {
            state.selectedCity = [];
          }
          state.selectedCity = state.selectedCity.includes(value)
            ? state.selectedCity.filter((item) => item !== value)
            : [...state.selectedCity, value];
          break;

        case "Specialization":
          if (!Array.isArray(state.selectedSpecialization)) {
            state.selectedSpecialization = [];
          }
          state.selectedSpecialization = state.selectedSpecialization.includes(value)
            ? state.selectedSpecialization.filter((item) => item !== value)
            : [...state.selectedSpecialization, value];
          break;

        case "State":
          if (!Array.isArray(state.selectedState)) {
            state.selectedState = [];
          }
          state.selectedState = state.selectedState.includes(value)
            ? state.selectedState.filter((item) => item !== value)
            : [...state.selectedState, value];
          break;

        default:
          break;
      }
    },
    resetFilters: (state) => {
      state.selectedDegree = [];
      state.selectedCity = [];
      state.selectedSpecialization = [];
      state.selectedState = [];
    },
    setFiltersFromURL: (state, action) => {
      const { selectedDegree = [], selectedCity = [], selectedSpecialization = [], selectedState = [] } = action.payload;

      state.selectedDegree = Array.isArray(selectedDegree) ? selectedDegree : [];
      state.selectedCity = Array.isArray(selectedCity) ? selectedCity : [];
      state.selectedSpecialization = Array.isArray(selectedSpecialization)
        ? selectedSpecialization
        : [];
      state.selectedState = Array.isArray(selectedState) ? selectedState : [];
    },
  },
});

export const { toggleFilter, resetFilters, setFiltersFromURL } = filterSlice.actions;

export default filterSlice.reducer;
