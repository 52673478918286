import React, { useState, useEffect, useMemo } from 'react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { FaSort } from "react-icons/fa";
import { useSelector } from 'react-redux';

const AllUniversity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchUniversities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/universities`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sortedUniversities = response.data.sort((a, b) => {
          const dateA = new Date(a.updated_at || a.created_at);
          const dateB = new Date(b.updated_at || b.created_at);
          return dateB - dateA; // Sort by latest first
        });
        if (Array.isArray(sortedUniversities)) {
          const processedData = sortedUniversities.map(item => ({
              ...item,
              pictures: JSON.parse(item.pictures) // Parse the pictures string as JSON
          }));
          setUniversities(processedData);
      } else {
          console.error('Unexpected data format:');
          setUniversities([]); // Set fallback if not an array
      }
        // setUniversities(sortedUniversities);
      } catch (err) {
        toast.error('Failed to fetch universities');
      } finally {
        setLoading(false);
      }
    };
    fetchUniversities();
  }, [token]);

  // Sorting logic
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color="lightgrey" />;
  };

  const sortedUniversities = useMemo(() => {
    let sortableData = [...universities];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [universities, sortConfig]);

  // Filter and Paginate universities based on search term and current page
  const filteredUniversities = sortedUniversities.filter(university =>
    university.university_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalEntries = filteredUniversities.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const displayedUniversities = filteredUniversities.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () => setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
  const handleNext = () => setCurrentPage(prev => (prev < totalPages ? prev + 1 : prev));

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this university?");
    if (!confirmDelete) return;

    try {
      await axios.put(
        `${Base_URL}/update/delete/universities/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('University deleted successfully');
      setUniversities((prev) => prev.filter((university) => university.university_id !== id));
    } catch (err) {
      toast.error('Failed to delete university');
    }
  };

  const handleUniversityClick = (college) => {
    const universityNameSlug = encodeURIComponent(college.university_name.trim().toLowerCase().replace(/\s+/g, '-')); // Convert name to URL-safe string
    navigate(`/college/${universityNameSlug}`, { state: { college } });
};


  return (
    <>
      <ToastContainer />
      <div className="bg-gray-100 p-6 min-h-screen overflow-auto">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className="bg-white p-4 rounded-md shadow-md">
          <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
            <h2 className="text-xl font-semibold mb-4">{subTitle}</h2>
            <button
              className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
              onClick={() =>
                navigate('/admin/university/add', {
                  state: { subTitle: 'All University', mainTitle: 'University' },
                })
              }
            >
              + Add New
            </button>
          </div>

          <div className="flex justify-between items-center mb-4">
  {/* Total Count */}
  <div className="text-sm  font-semibold text-tableTextColor">
    Total Universities: <span className="font-semibold">{filteredUniversities.length}</span>
  </div>
  
  {/* Search Input */}
  <div className="flex justify-end mb-4">
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search"
            />
          </div>
</div>


         

          <div className="grid grid-cols-8 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
            <div onClick={() => handleSort('university_id')} className="cursor-pointer flex items-center">
              University Id
               {/* {getSortIcon('university_id')} */}
            </div>
            <div onClick={() => handleSort('university_name')} className="cursor-pointer flex items-center">
              University Name {getSortIcon('university_name')}
            </div>
            <div>Featured</div>
            <div>Popular</div>
            <div onClick={() => handleSort('created_on')} className="cursor-pointer flex items-center">
              Created On {getSortIcon('created_on')}
            </div>
            <div onClick={() => handleSort('user_name')} className="cursor-pointer flex items-center">
              Created By {getSortIcon('user_name')}
            </div>
            <div onClick={() => handleSort('is_active')} className="cursor-pointer flex items-center">
              Status {getSortIcon('is_active')}
            </div>
            <div>Action</div>
          </div>

          {displayedUniversities.length > 0 ? (
            displayedUniversities.map((university) => (
              <div
                key={university.university_id}
                className="grid grid-cols-8 gap-2 text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100"
                style={{ color: "rgb(115, 123, 139)" }}
              >
                <div>{university.university_id}</div>
                <div className="cursor-pointer text-cmsgreen hover:text-cmsgreen" onClick={() => handleUniversityClick(university)} >{university.university_name}</div>
                <div className="truncate">{university.featuredcollege === 1 ? 'Yes' : 'No'}</div>
                <div className="truncate">{university.popularcollege === 1 ? 'Yes' : 'No'}</div>
                <div>{university.created_on ? university.created_on.split('T')[0] : 'N/A'}</div>
                <div>{university.user_name}</div>
                <div>
                  <span
                    className={`inline-block py-1 px-3 rounded-full text-sm ${
                      university.is_active === 1
                        ? "bg-green-100 text-green-600 border border-green-500"
                        : "bg-red-100 text-red-600 border border-red-500"
                    }`}
                  >
                    {university.is_active === 1 ? "Active" : "Inactive"}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() =>
                      navigate(`/admin/university/edit/${university.university_id}`, { state: university })
                    }
                    className="bg-cmsgreen hover:bg-hovergreen text-white p-2 rounded"
                  >
                    <HiPencilAlt />
                  </button>
                  <button
                    onClick={() => handleDelete(university.university_id)}
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded"
                  >
                    <HiTrash />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-4">No universities found</div>
          )}

          {/* Pagination */}
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <span className="text-sm text-gray-600 mr-2">Show:</span>
              <select
                value={entriesPerPage}
                onChange={(e) => {
                  setEntriesPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
                className="border border-gray-300 rounded"
              >
                {[5, 10, 20].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={handlePrevious}
                className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="text-sm text-gray-600">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNext}
                className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </div>
    </>
  );
};

export default AllUniversity;
