import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Img_URL } from "../../apiConfig";
import { ReactComponent as Back } from "../../assets/header/Back.svg";

const MobileSearch = ({
  isLoading,
  setOverlayOpen,
  setSearchResults,
  handleSearchChange,
  handleSearchSelect,
  handleSearchSubmit,
  universityName = [],
  setUniversityName,
  setMobileSearch,
  searchResults,
  debouncedFetchUniversities,
  recentSearches = [],
  removeFromRecentSearches
}) => {
  const MIN_LENGTH = 3;
  const RECENT_MIN_LENGTH = 3;
  const skeletonLoader = () => (
    <div className='space-y-4'>
      {Array.from({ length: 5 }).map((_, index) => (
        <div className='flex space-x-4 items-center '>
          <div key={index} className='space-x-4'>
            <Skeleton square={true} height={60} width={60} />
          </div>
          <div key={index} className='space-x-4' style={{ width: "100%" }}>
            <Skeleton height={36} width='100%' />
          </div>
        </div>
      ))}
    </div>
  );
  const closeSearch = () => {
    setMobileSearch(false);

    setSearchResults([]);
  };

  const navigate = useNavigate();

  const handleNavigation = (program) => {
    const programSlug = program.toLowerCase().replace(/\s+/g, "-"); // Convert program name to slug
    navigate(`/filter/${programSlug}-colleges-in-india`); // Pass program name as state
  };

  return (
    <div className='fixed inset-0 z-50 bg-white overflow-y-auto lg:hidden '>
      <div className='p-4 flex items-center border-b'>
        <button
          className='text-lg font-medium flex items-center space-x-2'
          onClick={closeSearch}
        >
          <Back className='text-2xl' />
        </button>
        <div className='flex w-full justify-between items-center'>
          <div className='flex w-full justify-evenly p-2'>
            <input
              type='text'
              value={universityName}
              onChange={handleSearchChange}
              onKeyDown={handleSearchSubmit}
              style={{ border: "none", width: "90%", fontSize: "14px" }}
              className='w-full px-4 py-2 border border-gray-300 font-bold text-gray-600'
              placeholder='Search'
              autoFocus
            />
            <button
              onClick={closeSearch}
              className='ml-4 text-gray-500 text-xl font-bold'
            >
              ✕
            </button>
          </div>
        </div>
      </div>

      <div className='w-full mx-auto px-4 py-3 bg-gray-100'>
        {/* Top Section: Search Bar */}
        {universityName.length < MIN_LENGTH && recentSearches.length > 0 ? (
          <div className='mt-2 px-8'>
            <h3 className='text-primaryText font-semibold mb-2'>
              RECENT SEARCHES
            </h3>
            <ul className='text-gray-700 space-y-2'>
              {recentSearches.map((term, index) => (
                <li
                key={index}
                className="flex justify-between items-center cursor-pointer"
              >
                   <span className='font-bold text-gray-600 text-md'
                  onClick={() => {
                    setUniversityName(term);
                    debouncedFetchUniversities(term);
                  }}
                >
                  • {term}
                </span>
                <button
                  className="text-red-500 hover:text-red-700 text-sm ml-4"
                  onClick={() => removeFromRecentSearches(term)}
                >
                  ✕
                </button>
              </li>
              ))}
            </ul>
          </div>
        ) : null}
        {/* Trending Searches */}

        {/* Search Results */}
        {universityName.length >= MIN_LENGTH && (
          <div
            className='m max-h-full overflow-y-auto border-t pt-2'
            style={{ borderTop: "1px solid #eee" }}
          >
            {isLoading ? (
              skeletonLoader()
            ) : searchResults.length > 0 ? (
              <ul className='space-y-2'>
                {searchResults.map((result) => (
                  <li
                    key={result.university_id}
                    className='py-2 px-4 bg-gray-100 rounded-md hover:bg-gray-200 cursor-pointer flex items-center space-x-4 '
                    onClick={() => handleSearchSelect(result)}
                  >
                    {result.logo && (
                      <img
                        src={`${Img_URL}/${result.logo}`} // Use the logo URL from the result
                        alt={`${result.university_name} logo`}
                        className='h-[50px] w-[50px]  ' // Adjust size as needed
                      />
                    )}
                    <span className='font-bold text-gray-600 text-md'>
                      {result.university_name}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className='text-gray-500 mt-4'>No results found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSearch;
