import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Base_URL, Img_URL } from "../apiConfig";
const LatestUpdates = () => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate();

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/news_announcements`);

        // Sort by created_at and take the most recent 4
        const sortedNews = response.data
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, 4);
        setNews(sortedNews); // Get only the most recent 4 news
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNews();
  }, []);

  // Navigate to news detail page on click
  const handleNewsClick = (id) => {
    navigate(`/particularNews/${id}`);
  };

  return (
    <div className='py-4 px-2'>
      <div className='max-w-6xl mx-auto'>
        {/* Title Section */}
        <div className='text-left mb-4'>
          <h2
            className='font-bold text-[20px] inline-block px-4 py-2 rounded-md'
            style={{
              color: "#468EFF",
            }}
          >
            Get All Latest Updates
          </h2>
        </div>

        {/* News Cards */}
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4'>
          {news.map((item) => (
            <div
              key={item.id}
              className='bg-white p-4 rounded-lg shadow-md cursor-pointer h-80 flex flex-col justify-start'
              onClick={() => handleNewsClick(item.id)}
            >
              {/* Event Image */}
              {item.picture ? (
                <img
                  src={`${Img_URL}${item.picture}`}
                  alt={item.title}
                  className='h-32 w-full object-cover rounded-md mb-2'
                />
              ) : (
                <div className='h-32 bg-gray-300 rounded-md mb-2'></div>
              )}

              {/* Event Title (Fixed height for alignment) */}
              {item.title && (
                <h3
                  className='text-[14px] text-gray-800 font-bold mt-2 mb-2'
                  style={{ minHeight: "40px" }}
                >
                  {item.title}
                </h3>
              )}

              {/* Event Content (Fixed height and truncated) */}
              <p
                className='text-gray-600 text-sm overflow-hidden line-clamp-3'
                style={{ minHeight: "56px" }}
              >
                {item.content}
              </p>
            </div>
          ))}
        </div>

        {/* View All News Button */}
        <div className='mt-6 text-left'>
          <button
            onClick={() => navigate("/newsDetail")}
            className='font-bold text-xs'
            style={{
              color: "#468EFF",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "14px",
              padding: "6px 12px",
              borderRadius: "4px",
              display: "inline-block",
            }}
          >
            View All News &rarr;
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestUpdates;
