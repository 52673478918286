import React from "react";
import Career from "../assets/landing/Career.png";

function CareerTop() {
  return (
    <div className="w-full h-[456px] bg-white flex justify-center items-center relative">
      <img
        src={Career}
        alt="Centered Image"
        className="w-full h-full object-contain object-center"
      />

      <div
        className="absolute text-[#75E36B] font-bold md:top-[17%] md:right-[5%] lg:top-[15%] lg:right-[5%] top-[32%] right-[5%] 
        text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl"
      >
        CAREER
      </div>
    </div>
  );
}

export default CareerTop;
