import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Header/Navbar";
import BottomNavigation from "./components/Header/BottomNavigation";

const Layout = ({ children }) => {
  const location = useLocation();

  // Define routes where the header should be excluded
  const excludedRoutes = ["/admin", "/dashboard"];

  // Check if the current route starts with any excluded route
  const isExcludedRoute = excludedRoutes.some((route) =>
    location.pathname.startsWith(route)
  );
  const isMobile = window.innerWidth <= 768;
  return (
    <div>
      {/* Conditionally render the header */}
      {!isExcludedRoute && <Navbar />}

      {/* Main content */}
      <main>{children}</main>
      {!isExcludedRoute && <BottomNavigation/> }
      {/* Footer can also be conditionally rendered */}
      {!isExcludedRoute && <Footer />}
    </div>
  );
};

export default Layout;
