import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Base_URL } from "../apiConfig";
import { useNavigate } from "react-router-dom";
const StudyGoals = ({ degrees }) => {
  const [streams, setStreams] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [courses, setCourses] = useState([]); // State for courses
  const [universities, setUniversities] = useState([]); // State for universities
  const [selectedProgram, setSelectedProgram] = useState(""); // Track selected program// Set the default selected program
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (programs.length > 0) {
      setSelectedProgram(programs[0].program_master_name);
      fetchCourses(programs[0].program_master_name);
      fetchUniversities(programs[0].program_master_name);
      fetchExams(programs[0].id);
    }
  }, [programs]);

  // Fetch streams data
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/streams`);
        setStreams(response.data);
      } catch (error) {
        console.error("Error fetching streams:", error);
      }
    };

    fetchStreams();
  }, []);

  // Fetch program master data
  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/program_master`);
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    fetchPrograms();
  }, []);

  // Helper function to filter programs for a given stream
  const getProgramsForStream = (streamId) => {
    if (!programs || programs.length === 0) return [];
    return programs
      .filter((program) => program.stream_id === streamId)
      .map((program) => program.program_master_name)
      .slice(0, 3); // Show only the first three programs
  };

  // Fetch courses based on selected program
  const fetchCourses = async (programName) => {
    try {
      const response = await axios.get(`${Base_URL}/get/course_master`);
      const filteredCourses = response.data.filter(
        (course) =>
          course.program_id ===
          programs.find((p) => p.program_master_name === programName)?.id
      );
      setCourses(filteredCourses);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  // Fetch universities based on selected program
  const fetchUniversities = async (programName) => {
    try {
      // Create the filter JSON string dynamically
      const filters = JSON.stringify([[programName]]);
      const response = await axios.get(
        `${Base_URL}/universities/filter/name?filters=${encodeURIComponent(
          filters
        )}`
      );

      // Limit the results to the first 3 universities
      const limitedUniversities = response.data.slice(0, 3);
      setUniversities(limitedUniversities); // Set the universities in state
    } catch (error) {
      console.error("Error fetching universities:", error);
    }
  };

  // Handler for clicking on a program
  const fetchExams = async (programId) => {
    try {
      const response = await axios.get(
        `${Base_URL}/exam-master/filter?filter=[{"program_id":[${programId}]}]`
      );
      setExams(response.data);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };
  // Handler for clicking on a program
  const handleProgramClick = (programName) => {
    setSelectedProgram(programName); // Update selected program
    const program = programs.find((p) => p.program_master_name === programName);
    if (program) {
      fetchCourses(programName); // Fetch courses
      fetchUniversities(programName); // Fetch universities for the program
      fetchExams(program.id); // Fetch exams for the selected program
    }
  };

  const handleDegreeClick = (program) => {
    const programSlug = program.toLowerCase().replace(/\s+/g, "-");
    // Navigate to LandingUniversity with degree as a query param
    navigate(`/filter/${programSlug}-colleges-in-india`);
  };
  const handleUniversityClick = (university) => {
    const universityNameSlug = encodeURIComponent(
      university.university_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    // Navigate to the university college page
    navigate(`/college/${universityNameSlug}`);
  };
  const handleCourseClick = (course) => {
    const courseNameSlug = encodeURIComponent(
      course.course_master_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    navigate(`/courses/details/${courseNameSlug}`);
  };
  const handleExamClick = (exam) => {
    const examNameSlug = encodeURIComponent(
      exam.exam_master_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    navigate(`/exams/filter/${examNameSlug}`);
  };
  return (
    <div className='p-4 sm:p-6 lg:p-8'>
      {/* Top Heading */}
      <h1 className='text-3xl sm:text-3xl font-bold leading-snug lg:leading-[46.87px] lg:mt-4 lg:ml-4'>
        Select your Study Goal
      </h1>

      {/* Stream Selection */}
      <div
        className='flex overflow-x-auto whitespace-nowrap  lg:grid-cols-4 gap-12  mt-6 p-4'
        style={{
          WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
          scrollbarWidth: "none", // Hides scrollbar for Firefox
          msOverflowStyle: "none", // Hides scrollbar for IE and Edge
          overflowX: "auto",
        }}
      >
        {streams.length > 0 ? (
          streams.slice(0, 4).map((stream) => (
            <div
              key={stream.id}
              className='w-full sm:w-[300px] min-w-[250px] h-[300px] bg-white rounded-lg flex flex-col flex-shrink-0'
              style={{
                boxShadow: "1px 2px 11px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Stream Header */}
              <div className='bg-[#3ACA2E] h-[80px] flex items-center justify-center rounded-t-lg'>
                <h2 className='text-lg font-bold text-white'>
                  {stream.stream_name}
                </h2>
              </div>

              {/* Programs for the selected stream */}
              <div className='flex flex-col mt-auto mb-auto items-center px-6 space-y-4'>
                {getProgramsForStream(stream.id).length > 0 ? (
                  getProgramsForStream(stream.id).map((program, idx) => (
                    <div
                      key={idx}
                      className='w-[188px] h-[38px] bg-[#FFFFFF] rounded-[10px] px-2 flex items-center font-dm-sans text-[14px] font-normal leading-[18.23px] text-left'
                      style={{
                        border: "1px solid #00000040",
                        whiteSpace: "normal", // Allows text to wrap
                        wordWrap: "break-word", // Ensures long words break into the next line
                        overflowWrap: "break-word", // Ensures proper word wrapping in modern browsers
                        cursor: "pointer",
                      }}
                      onClick={() => handleDegreeClick(program)} // Add click handler
                    >
                      {program}
                    </div>
                  ))
                ) : (
                  <p className='text-gray-600 text-sm'>No programs available</p>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No streams available.</p>
        )}

        {/* "See All" Card */}
        {/* <div
    className="w-full sm:w-[300px] min-w-[250px] h-[300px] bg-white rounded-lg flex flex-col justify-center items-center flex-shrink-0"
    style={{
      boxShadow: "1px 2px 11px 4px rgba(0, 0, 0, 0.1)",
    }}
  >
    <h2 className="text-lg font-bold text-[#3ACA2E]"><Link to ='/careers'> See All</Link></h2>
  </div> */}
      </div>

      {/* Explore Programs Section */}
      <h1 className='mt-10 text-3xl sm:text-3xl font-bold leading-snug lg:leading-[46.87px] lg:mt-12'>
        Explore Programs
      </h1>
      <div className='flex flex-wrap items-center gap-4 mt-6'>
        {programs.length > 0 ? (
          programs.slice(0, 5).map((program, idx) => (
            <div
              key={idx}
              className={`px-4 py-2 border rounded-md text-sm font-medium ${
                selectedProgram === program.program_master_name
                  ? "bg-gray-100"
                  : "bg-white"
              } cursor-pointer`}
              style={{
                border: "1px solid #00000040",
                cursor: "pointer",
              }}
              onClick={() => handleProgramClick(program.program_master_name)}
            >
              {program.program_master_name}
            </div>
          ))
        ) : (
          <p>No programs available</p>
        )}
      </div>
      <div className='grid grid-cols-1 gap-4 p-2 sm:grid-cols-2 md:grid-cols-3 md:p-4 md:gap-10 xl:gap-6 mt-10 md:overflow-x-auto'>
        {/* Card 1: Find Colleges */}
        <div
          className='w-full bg-white rounded-lg flex flex-col'
          style={{ boxShadow: "1px 2px 11px 4px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className='flex justify-between items-center px-4 h-[73px] rounded-t-lg'
            style={{
              background: "#3ACA2E",
              fontSize: "18px",
              fontWeight: "600",
              color: "white",
            }}
          >
            <span>Find Colleges</span>
            <span style={{ fontSize: "36px" }}>🏫</span>
          </div>

          <div className='flex flex-wrap px-4 py-2 gap-2'>
            {universities.slice(0, 3).map((university, idx) => (
              <div
                key={idx}
                className='flex items-center justify-center bg-white rounded-md border text-sm font-medium'
                style={{
                  height: "41px",
                  border: "1px solid rgba(0, 0, 0, 0.25)",
                  width: `${university.university_name.length * 12}px`,
                  cursor: "pointer",
                }}
                onClick={() => handleUniversityClick(university)}
              >
                {university.university_name}
              </div>
            ))}
          </div>

          <div
            className='flex justify-between items-center px-4 py-3 mt-auto'
            style={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "23.44px",
              color: "#3ACA2E",
            }}
          >
            Discover Colleges
            <Link to='/colleges'>
              <span>→</span>
            </Link>
          </div>
        </div>

        {/* Card 2: Exams */}
        <div
          className='w-full bg-white rounded-lg flex flex-col'
          style={{ boxShadow: "1px 2px 11px 4px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className='flex justify-between items-center px-4 h-[73px] rounded-t-lg'
            style={{
              background: "#3ACA2E",
              fontSize: "18px",
              fontWeight: "600",
              color: "white",
            }}
          >
            <span>Find Exams</span>
            <span style={{ fontSize: "36px" }}>📝</span>
          </div>
          <div className='flex flex-wrap px-4 py-2 gap-2'>
            {exams.slice(0, 3).map((exam, idx) => (
              <div
                key={idx}
                className='flex items-center justify-center bg-white rounded-md border text-sm font-medium'
                style={{
                  height: "41px",
                  border: "1px solid rgba(0, 0, 0, 0.25)",
                  width: `${exam.exam_master_name.length * 12}px`,
                  cursor: "pointer",
                }}
                onClick={() => handleExamClick(exam)}
              >
                {exam.exam_master_name}
              </div>
            ))}
          </div>

          <div
            className='flex justify-between items-center px-4 py-3 mt-auto'
            style={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "23.44px",
              color: "#3ACA2E",
            }}
          >
            Discover Exams
            <Link to='/exam-preparation'>
              <span>→</span>
            </Link>
          </div>
        </div>

        {/* Card 3: Find Courses */}
        <div
          className='w-full bg-white rounded-lg flex flex-col'
          style={{ boxShadow: "1px 2px 11px 4px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className='flex justify-between items-center px-4 h-[73px] rounded-t-lg'
            style={{
              background: "#3ACA2E",
              fontSize: "18px",
              fontWeight: "600",
              color: "white",
            }}
          >
            <span>Find Courses</span>
            <span style={{ fontSize: "36px" }}>📚</span>
          </div>

          <div className='flex flex-wrap px-4 py-2 gap-2'>
            {courses.slice(0, 3).map((course, idx) => (
              <div
                key={idx}
                className='flex items-center justify-center bg-white rounded-md border text-sm font-medium'
                style={{
                  height: "41px",
                  border: "1px solid rgba(0, 0, 0, 0.25)",
                  width: `${course.course_master_name.length * 12}px`,
                  cursor: "pointer",
                }}
                onClick={() => handleCourseClick(course)}
              >
                {course.course_master_name}
              </div>
            ))}
          </div>

          <div
            className='flex justify-between items-center px-4 py-3 mt-auto'
            style={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "23.44px",
              color: "#3ACA2E",
            }}
          >
            Discover Courses
            <Link to='/courses/all'>
              <span>→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyGoals;
