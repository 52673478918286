import apiClient from "../apiClient"; // Assuming this is configured with interceptors
import { API_ENDPOINT } from "../../utils/constant";

const ExamListService = {
  /**
   * Fetch all exams.
   * @returns {Promise<Array>} List of exams.
   */
  fetchExams: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.EXAM_MASTER);
      return response.data;
    } catch (error) {
      console.error("Error fetching exams:", error.message);
      throw new Error(error.response?.data?.message || "Unable to fetch exams");
    }
  },

  /**
   * Fetch exam details with join and where conditions.
   * @param {Object} options Query options for join and where.
   * @returns {Promise<Object>} Exam details.
   */
  fetchExamDetailsWithJoin: async (options) => {
    try {
      const url = `${API_ENDPOINT.JOIN_WHERE}?options=${encodeURIComponent(
        JSON.stringify(options)
      )}`;
      const response = await apiClient.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching exam details:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch exam details"
      );
    }
  },
};

export default ExamListService;
