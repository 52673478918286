
import apiClient from '../apiClient';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { API_ENDPOINT } from '../../utils/constant';

const LoginSignupService = {
  /**
   * Logs in or signs up a user based on the action type.
   * @param {Object} formData - The data submitted by the user (e.g., email, password).
   * @param {number} actionType - 0 for login, 1 for signup.
   * @returns {Object} Response data with token.
   * @throws {Error} Throws an error if the request fails.
   */
  loginOrSignup: async (formData, actionType) => {
    const endpoint = actionType === 0 ? API_ENDPOINT.LOGIN : API_ENDPOINT.SIGNUP
    try {
      const response = await apiClient.post(endpoint, formData);
      const { token } = response.data;

      if (typeof token !== 'string' || token.trim() === '') {
        throw new Error('Invalid token received from the server.');
      }

      // Decode token to extract user information
      const decodedToken = jwtDecode(token);

      // Save token in cookies and localStorage for session persistence
      Cookies.set('token', token);
      localStorage.setItem('token', token);

      return {
        token,
        registration_id: decodedToken.registration_id,
        reg_type: decodedToken.reg_type,
        name: decodedToken.name,
        email: decodedToken.email,
        mobilenumber: decodedToken.mobilenumber,
      };
    } catch (error) {
      console.error('Error during login or signup:', error.response?.data?.message || error.message);
      throw new Error(error.response?.data?.message || 'An unexpected error occurred.');
    }
  },

  /**
   * Logs out the user by clearing the token from cookies and localStorage.
   */
  logout: () => {
    Cookies.remove('token');
    localStorage.removeItem('token');
  },

  /**
   * Retrieves the current token from cookies or localStorage.
   * @returns {string|null} The token or null if not found.
   */
  getToken: () => {
    return localStorage.getItem('token') || Cookies.get('token') || null;
  },

  /**
   * Decodes the stored token to get user information.
   * @returns {Object|null} Decoded token data or null if no valid token is found.
   */
  getUserInfoFromToken: () => {
    const token = localStorage.getItem('token') || Cookies.get('token');
    if (token) {
      try {
        return jwtDecode(token);
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    }
    return null;
  },
};

export default LoginSignupService;
