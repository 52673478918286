import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Img_URL } from "../../../apiConfig";
import Digital from "../../../assets/header/Digital.gif";
import { ReactComponent as Partner } from "../../../assets/header/Partner.svg";
import { ReactComponent as Polygon } from "../../../assets/header/Polygon.svg";
const MoreDropdown = ({ onClose }) => {
  const dropdownItems = [
    { name: "About Us", route: "/about-us" },
    { name: "Privacy Policy", route: "/privacy-policy" },
    { name: "Terms and Conditions", route: "/terms-and-conditions" },
    { name: "Contact Us", route: "/contact-us" },
  ];

  const colleges = useSelector((state) => state.colleges?.featuredColleges);
  const { news } = useSelector((state) => state.newsEvents);
  const navigate = useNavigate();

  const handleNewsDetail = () => {
    onClose();
    navigate("/newsDetail");
  };

  const handleViewMore = () => {
    onClose();
    navigate("/colleges/featured-colleges");
  };

  const handleQuickLink = (route) => {
    onClose(); // Close the dropdown
    navigate(route); // Navigate to the respective route
  };

  const handleNewsClick = (id) => {
    onClose(); // Close the dropdown
    navigate(`/particularNews/${id}`);
  };

  const handleCollegeClick = (college) => {
    const universityNameSlug = encodeURIComponent(
      college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    onClose(); // Close the dropdown
    navigate(`/college/${universityNameSlug}`);
  };

  useEffect(() => {});

  return (
    <div
      className='absolute top-[90px] right-[-100px] bg-white shadow-lg border rounded-md p-4 text-black text-sm z-50 flex'
      style={{ minWidth: "1000px" }}
    >
      {/* Triangle */}
      <div className='absolute top-[-28px] right-[115px]'>
        <Polygon className='w-[30px] h-[30px]' />
      </div>

      {/* Left Section */}
      <div className='flex-[3] pr-6'>
        <h3 className='text-lg font-semibold mb-4'>Explore More</h3>
        <div className='grid grid-cols-2 gap-y-2 gap-x-6'>
          <div>
            <span className='font-bold'>Quick Links</span>
            {dropdownItems?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleQuickLink(item.route)} // Call handleQuickLink with the route
                className='cursor-pointer mt-2 hover:text-primaryText pb-2 text-lightTextColor border-b border-gray-300'
              >
                {item.name}
              </div>
            ))}
          </div>
          <div>
            <span className='font-bold'>Featured Colleges</span>
            {colleges?.slice(0, 5).map((college, index) => (
              <div
                key={index}
                onClick={() => handleCollegeClick(college)}
                className='cursor-pointer text-lightTextColor mt-2 hover:text-primaryText pb-2 border-b border-gray-300'
              >
                {college.university_name}
              </div>
            ))}
            {/* View More Link */}
            <div
              onClick={() => handleViewMore()} // Navigate to featured colleges page
              className='cursor-pointer mt-2 text-primaryText hover:underline'
            >
              View More &rarr;
            </div>
          </div>
        </div>
      </div>

      <div className={`h-[400px] w-[2px] bg-[#3FB72866] mx-4 `} />

      {/* Middle Section */}
      <div className='flex flex-col'>
        <div className='flex'>
          {news?.slice(0, 1).map((item) => (
            <div
              key={item.id}
              className='bg-white p-4 rounded-lg cursor-pointer max-w-[300px] h-80 flex flex-col justify-start'
              onClick={() => handleNewsClick(item.id)}
            >
              {/* Event Image */}
              {item.picture ? (
                <img
                  src={`${Img_URL}${item.picture}`}
                  alt={item.title}
                  className='h-32 w-full object-cover rounded-md mb-2'
                />
              ) : (
                <div className='h-32 bg-gray-300 rounded-md mb-2'></div>
              )}

              {/* Event Title (Fixed height for alignment) */}
              <div className='flex flex-col justify-evenly h-full'>
                {item.title && (
                  <h3
                    className='text-[14px] text-gray-800 font-bold mt-2 mb-2'
                    style={{ minHeight: "40px" }}
                  >
                    {item.title}
                  </h3>
                )}

                {/* Event Content (Fixed height and truncated) */}
                <p
                  className='text-gray-600 text-sm overflow-hidden line-clamp-3'
                  style={{ minHeight: "56px" }}
                >
                  {item.content}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* View All News Button */}
        <div className='mt-0 pl-4 text-left'>
          <button
            onClick={() => handleNewsDetail()}
            className='cursor-pointer mt-2 text-primaryText hover:underline'
          >
            View All News &rarr;
          </button>
        </div>
      </div>
      <div className={`h-[400px] w-[2px] bg-[#3FB72866] mx-4 `} />

      {/* Right Section */}
      <div className='flex-[2] p-4 text-center'>
        {/* GIF */}
        <div className='flex justify-center mb-4'>
          <img
            src={Digital} // Path to the digital GIF
            alt='Digital Marketing GIF'
            className='w-[41px] h-[41px]'
          />
        </div>

        {/* Heading */}
        <h3 className='text-sm font-semibold text-primaryText mb-4'>
          Become our Marketing Partner
        </h3>

        {/* Description */}
        <p className='text-xs text-gray-700 mb-4'>
          Join us in transforming the educational journey for students while
          earning rewards. As a Marketing Partner, you'll play a crucial role in
          connecting aspiring students with their dream colleges, making a
          difference in their lives while growing your own network and success.
        </p>

        {/* Bullet Points */}
        <ul className='list-disc list-inside mb-6  max-w-[300px]'>
          <li>
            <span className='font-semibold text-xs'>
              Earn as You Impact Lives
            </span>
          </li>
          <li>
            <span className='font-semibold text-xs '>Expand Your Reach</span>{" "}
          </li>
          <li>
            <span className='font-semibold text-xs '>
              Be Part of a Revolution
            </span>
          </li>
        </ul>

        {/* Join Us Button */}
        <div className='w-full flex items-center justify-center'>
          <button className=' flex justify-center space-x-2 items-center mt-4 px-12 py-2 bg-primary text-white rounded-3xl'>
            <Partner className='ml-[-12px]' />
            <div className='text-md'>Join Us</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MoreDropdown;
