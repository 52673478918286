import React from 'react';
import user_img from '../../../../assets/user1.png';

const ChatCard = () =>{
    return(
        <div className="flex flex-col items-center w-[300px] bg-white rounded-xl overflow-hidden flex-shrink-0 border-2">
            <div className="h-[50px] w-full bg-black"></div>
            <div className="w-[60px] h-[60px] transform -translate-y-5"><img src={user_img} alt="user-image" className="h-full w-full" /></div>
            <div><p className="text-center text-sm font-semibold text-black -mt-2 mb-3">George</p></div>
            <div><button className="px-3 py-[6px] text-white text-sm bg-[#3ACA2E] rounded-2xl mb-3">Chat with George</button></div>
            <div className="w-full p-3 flex flex-col gap-3 mb-5">
                <div>
                    <p className="font-semibold text-base text-black">Department</p>
                    <p className="text-black text-sm">Engineering</p>
                </div>
                <div>
                    <p className="font-semibold text-base text-black">Experience (years)</p>
                    <p className="text-black text-sm">5</p>
                </div>
                <div>
                    <p className="font-semibold text-base text-black">Qualification</p>
                    <p className="text-black text-sm">B.E - Mechanical Engineering, M.E - Factory Machine Designs</p>
                </div>
            </div>
        </div>
    )
}

export default ChatCard;