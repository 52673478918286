import React from 'react';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../../../assets/landing/Arrow.png';

const NewsCard = ({ title, desc, date, image, id }) => {
  const navigate = useNavigate();

  const handleArrowClick = () => {
    navigate(`/particularNews/${id}`, { 
      state: { 
        newsId: id,
        title: title,
        content: desc,
        date: date,
        picture: image
      } 
    });
  };

  return (
    <div className="flex items-center mx-4  md:mx-0 lg:h-[230px]">
      {/* Image container */}
      <div className="bg-gray-500 flex-shrink-0 h-[140px] w-[140px] md:h-[200px] md:w-[200px] lg:h-[200px] lg:w-[200px] rounded-[20px] border-2 ">
        <img src={image} alt="news-image" className="w-full h-full rounded-[20px]" />
      </div>

      {/* Text content container */}
      <div className="transform -translate-x-5 bg-white rounded-r-[20px] md:rounded-xl lg:p-5 md:p-2 border-2 h-[140px] w-[200px] md:h-[150px] md:w-[450px] lg:w-[350px] lg:h-[160px] xl:w-[400px]">
        <div className="flex mb-2">
          <p className="text-xs text-white bg-[#3ACA2E] py-1 px-3 mt-1 mx-2 rounded-xl">
            RECENT NEWS
          </p>
          <img
            src={Arrow}
            alt="Arrow Icon"
            className="arrow-icon absolute right-0 top-0 mt-2 mr-2 cursor-pointer"
            onClick={handleArrowClick}
          />
        </div>
        <div>
          <p className="text-black text-sm font-semibold mx-2 truncate">
            {title}
          </p>
          <p className="text-xs md:text-sm font-medium mb-3 mx-2 truncate">{date}</p>
          <p className="text-xs md:text-sm news_display_content mx-2 truncate">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;


