import React from "react";
import HatImage from "../../assets/loader/hat.png";
import "./HatCircleLoader.css";

const HatCircleLoader = () => {
  return (
    <div className="expanding-loader-container">
    <img src={HatImage} alt="Hat" className="hat-image" />
    <div className="expanding-circle"></div>
  </div>
  );
};

export default HatCircleLoader;
