import React, { useState, useEffect, useRef } from 'react';
import { FaArrowLeft, FaArrowRight, FaEllipsisH } from 'react-icons/fa';
import { useSocket } from '../../socket/SocketContext'; 
import { Base_URL } from '../../../apiConfig'; 

const ChatWindow = ({ user, senderId, receiverId, onBack }) => {
  const socket = useSocket(); 
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(''); 
  const endOfMessagesRef = useRef(null); 

  useEffect(() => {
    if (!socket) return;

    // Fetch chat history when the component mounts
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${Base_URL}/messages/${senderId}/${receiverId}`);
        const data = await response.json();
        setMessages(data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

    
    socket.on('receiveMessage', (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      socket.off('receiveMessage'); 
    };
  }, [socket, senderId, receiverId]);

  useEffect(() => {
   
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSend = async () => {
    if (message.trim() === '') return;

    const newMessage = {
      sender_id: senderId,
      receiver_id: receiverId,
      sender_type: 'user',
      message: message,
      incoming: false, 
      timestamp: new Date(),
      profilePicture: 'https://randomuser.me/api/portraits/men/1.jpg', 
    };

    // Emit the new message to the server
    socket.emit('sendMessage', newMessage);

    // Save the message to the backend
    try {
      await fetch(`${Base_URL}/chat/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newMessage),
      });

      // Update the local state with the new message
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setMessage('');

      // Simulate a reply from the receiver
      setTimeout(() => {
        const replyMessage = {
          sender_id: receiverId,
          receiver_id: senderId,
          sender_type: 'user', // or 'admin', depending on the receiver's role
          message: `Auto-reply to "${message}"`,
          incoming: true, // Simulated incoming message
          timestamp: new Date(),
          profilePicture: 'https://randomuser.me/api/portraits/women/1.jpg', // Replace with actual receiver's profile picture
        };

        // Emit the simulated reply to the server (optional)
        socket.emit('sendMessage', replyMessage);

        // Add the simulated reply to the local state
        setMessages((prevMessages) => [...prevMessages, replyMessage]);
      }, 1000); // Simulate a delay before the reply

    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="flex flex-col w-full h-[90vh] max-h-[90vh] bg-white">
      {/* Header */}
      <div className="flex items-center justify-between p-4 bg-white border-b border-gray-300">
        <button onClick={onBack} className="flex items-center bg-gray-300 p-2 rounded-full">
          <FaArrowLeft className="text-gray-600" />
        </button>
        <div className="flex-grow text-center font-semibold" style={{ fontFamily: 'Poppins, sans-serif' }}>
          {user.name}
        </div>
        <button className="flex items-center bg-gray-300 p-2 rounded-full">
          <FaEllipsisH className="text-gray-600" />
        </button>
      </div>
      
      {/* Chat Messages */}
      <div className="flex-grow p-4 overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        <div className="flex flex-col space-y-4">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex ${msg.incoming ? 'justify-start' : 'justify-end'}`}
            >
              {msg.incoming && (
                <img
                  src={msg.profilePicture}
                  alt="Profile"
                  className="w-8 h-8 rounded-full mr-2"
                />
              )}
              <div
                className={`flex items-start p-3 rounded-lg shadow-sm max-w-xs ${msg.incoming ? 'bg-gray-200 text-gray-800' : 'bg-blue-500 text-white'} ${msg.incoming ? 'ml-2' : 'mr-2'}`}
                style={{ wordBreak: 'break-word' }}
              >
                <div>
                  <p>{msg.message}</p>
                  <span className="text-xs text-gray-500">{new Date(msg.timestamp).toLocaleTimeString()}</span>
                </div>
              </div>
              {!msg.incoming && (
                <img
                  src={msg.profilePicture}
                  alt="Profile"
                  className="w-8 h-8 rounded-full ml-2"
                />
              )}
            </div>
          ))}
          {/* Scroll to this element */}
          <div ref={endOfMessagesRef} />
        </div>
      </div>
      
      {/* Message Input */}
      <div className="flex items-center p-4 border-t border-gray-300 bg-gray-100">
        <input
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-grow p-2 rounded-full border-none outline-none text-sm"
          style={{ fontFamily: 'Poppins, sans-serif' }}
        />
        <button onClick={handleSend} className="ml-2 bg-blue-500 text-white p-2 rounded-full">
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
