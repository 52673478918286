import React, { useState } from "react";

const CareerSearch = ({ onSearchChange }) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearchChange(value); // Communicate the search query to the parent
  };

  return (
    <div className="w-full flex bg-[#F2FCEB] flex-col items-center justify-center px-4 mt-10 lg:mt-16">
      <h1 className="text-center font-bold md:text-[32px] lg:text-[32px] text-[20px] leading-[41.66px] font-[DM Sans] mt-10">
        What Career are you looking for?
      </h1>

      <div className="flex w-full max-w-[735px] mt-6">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search career..."
          className="w-full h-[50px] border-2 border-gray-300 rounded-l-[20px] pl-4"
        />
        <button
          className="w-[152px] h-[50px] bg-[#5BDE50] text-white font-bold rounded-r-[20px] flex items-center justify-center hover:bg-green-500 transition-all"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default CareerSearch;
