import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../apiConfig";

const ExpertHelp = ({ isOpen, onClose }) => {
  const modalRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    mobilenumber: "",
    description: "",
  });

  const [isToastShown, setIsToastShown] = useState(false);

  // Handle click outside modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const resetForm = () => {
    setFormData({
      name: "",
      mobilenumber: "",
      description: "",
    });
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
      setIsToastShown(false); // Reset the toast state when modal closes
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const nameRegex = /^[a-zA-Z\s]+$/; // Only alphabets and spaces
    const mobileRegex = /^[0-9]{10}$/; // Valid 10-digit number
    const { name, mobilenumber, description } = formData;

    if (!nameRegex.test(name)) {
      toast.error("Name should only contain alphabets and spaces.");
      return false;
    }

    if (!mobileRegex.test(mobilenumber)) {
      toast.error("Mobile number must be a valid 10-digit number.");
      return false;
    }

    if (description.trim().length < 10) {
      toast.error("Description must be at least 10 characters long.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Run validation before submission

    const payload = {
      name: formData.name,
      mobilenumber: formData.mobilenumber,
      description: formData.description,
    };

    try {
      await axios.post(`${Base_URL}/create/expert_help`, payload);

      // Show success toast
      toast.success("Application submitted successfully!");

      // Wait for a few seconds before closing the modal
      setTimeout(() => {
        resetForm();
        onClose();
      }, 500); // Adjust delay (in milliseconds) as needed
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Submission failed! Please try again.");
      }
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
        <div className='absolute inset-y-[150px] flex items-center justify-center w-full px-4'>
          <div
            ref={modalRef}
            className='bg-white rounded-lg p-6 w-full max-w-md mx-auto relative'
          >
            <div className='flex justify-between items-center mb-6'>
              <h2 className='text-xl font-semibold text-gray-800'>
                Expert Help
              </h2>
            </div>

            <form onSubmit={handleSubmit} className='space-y-6'>
              <div>
                <label className='block text-sm font-medium text-gray-700 mb-1'>
                  Name
                </label>
                <input
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm '
                  required
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700 mb-1'>
                  Contact Number
                </label>
                <input
                  type='tel'
                  name='mobilenumber'
                  value={formData.mobilenumber}
                  onChange={handleInputChange}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm '
                  required
                />
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700 mb-1'>
                  Description
                </label>
                <input
                  type='text'
                  name='description'
                  value={formData.description}
                  onChange={handleInputChange}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm '
                  required
                />
              </div>

              <button
                type='submit'
                className='w-full bg-cmsgreen text-white py-2 px-4 rounded-md hover:bg-green-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ExpertHelp;
