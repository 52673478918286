// Login.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PortalImage from "../../assets/Authentication/portal.png";
import { loginUser } from "../../redux/slice/authSlice"; // Import loginUser action

const Login = ({ toggleMode }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });


  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const { error ,loading } = useSelector((state) => state.auth);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(loginUser({ formData, actionType: 0 }));
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);

      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className='md:flex contents h-full rounded-2xl overflow-hidden'style={{minHeight:'inherit'}}>
      {/* Left Section - Login Form */}
      <div className='w-full md:w-[50%] bg-white flex flex-col justify-center px-6 py-4 overflow-y-auto'>
        <form onSubmit={handleSubmit} className='w-full max-w-sm mx-auto'>
          <h2 className='text-3xl font-bold mb-4'>Sign In</h2>
          <div className='space-y-3'>
            <div>
              <label htmlFor='email' className='block text-xs font-normal mb-1'>
                Email
              </label>
              <input
                id='email'
                type='email'
                name='email'
                placeholder='Enter Your Email'
                value={formData.email}
                onChange={handleInputChange}
                className='w-full p-2 text-sm border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-green-400'
              />
            </div>
            <div>
              <label
                htmlFor='password'
                className='block text-xs font-normal mb-1'
              >
                Password
              </label>
              <input
                id='password'
                type='password'
                name='password'
                placeholder='Enter Your Password'
                value={formData.password}
                onChange={handleInputChange}
                className='w-full p-2 text-sm border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-green-400'
              />
            </div>
            <div className='text-right'>
              <a href='#' className='text-xs text-hovergreen hover:underline'>
                Forgot Password?
              </a>
            </div>
          </div>
          <div className='mt-4'>
            <button
              type='submit'
              className='w-full px-4 py-2 bg-buttonbg text-[#000] rounded-md font-poppins flex items-center justify-center'
              disabled={loading}
            >
              {loading ? (
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Signing In...
                </div>
              ) : (
                "Sign In"
              )}
            </button>
          </div>
          {/* Error Message */}
          {errorMessage && (
            <div className='mt-2 text-red-500 text-sm text-center'>
              {errorMessage}
            </div>
          )}
          <p className='mt-4 text-center text-xs text-gray-600'>
            Don't have an account?{" "}
            <button
              type='button'
              onClick={toggleMode}
              className='text-hovergreen hover:underline'
            >
              Sign Up
            </button>
          </p>
        </form>
      </div>

      {/* Right Section - Image */}
      <div className='hidden md:flex md:w-[50%] bg-loginbg bg-cover flex-col justify-center items-center p-10'>
        <div className='flex flex-col mb-10 self-start'>
          <h1 className='font-bold text-[40px] text-black'>Welcome to</h1>
          <p className='text-[40px] font-light leading-[20px] tracking-[0.5px] m-0 text-black z-15'>
            Student Portal
          </p>
          <p className='text-xs font-light mt-4 ml-1 text-black z-15'>
            Sign in to access your account
          </p>
        </div>
        <div className='z-10'>
          <img
            src={PortalImage}
            alt='Portal'
            className='w-[95%] h-[95%] object-contain'
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
