import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Base_URL } from "../apiConfig";

const SubCareerMaster = () => {
  const { careerId } = useParams(); // Get careerId from the URL
  const [subCareers, setSubCareers] = useState([]); // State to store sub-careers
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchSubCareers = async () => {
      try {
        const response = await axios.get(
          `${Base_URL}/get/SubCareerMaster`
        );

        // Filter the sub-careers based on career_master_id
        const filteredSubCareers = response.data.filter(
          (subCareer) => subCareer.career_master_id === parseInt(careerId)
        );

        setSubCareers(filteredSubCareers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sub-careers:", error);
        setError("Failed to fetch sub-careers");
        setLoading(false);
      }
    };

    fetchSubCareers();
  }, [careerId]);

  if (loading) return <div>Loading...</div>;
  if (error) return (
    
      
      <div className="flex-grow p-6 mt-20 bg-[#F2FCEB] flex justify-center items-center">
        <div className="bg-red-100 text-red-700 p-6 rounded-md shadow-md max-w-lg text-center">
          
          <p>{error}</p>
        </div>
      </div>
     
   
  );

  // Function to handle button click and navigate
  const handleSubCareerClick = (subCareerId, subCareerName) => {
    navigate(`/career/${subCareerName}/${subCareerId}`); // Pass subCareerId and name
  };

  return (
    <>
      <div className='flex flex-col min-h-screen '>
        {/* Fixed Header with shadow and border */}
        <div
          style={{
            height: "120px",
          }}
        ></div>

        {/* Main Content with background and padding */}
        <div className='flex flex-col min-h-screen mt-15 bg-[#F2FCEB]'>
          <div className='flex-grow p-6'>
            <div className='container mx-auto'>
              <h2 className='text-2xl font-bold mb-6 font-dm-sans text-center mt-5'>
                Sub-Careers for Career ID {careerId}
              </h2>

              <div className='grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                {subCareers.length > 0 ? (
                  subCareers.map((subCareer) => (
                    <div
                      key={subCareer.id}
                      className='border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col'
                    >
                      <div className='flex-grow' />
                      <div className='absolute bottom-0 w-full'>
                        <button
                          className='w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2'
                          onClick={() =>
                            handleSubCareerClick(subCareer.id, subCareer.name)
                          }
                        >
                          {subCareer.name}
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No sub-careers found for this career ID.</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
      </div>
    </>
  );
};

export default SubCareerMaster;
