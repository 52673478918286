import { Parser } from 'htmlparser2';
import React, { useEffect, useState } from 'react';
import { Base_URL } from '../../../apiConfig';

const Admission = ({ collegeDetails, logoPath }) => {
    const [admissionData, setAdmissionData] = useState(null);
    const [admissionSteps, setAdmissionSteps] = useState([]);
    const [showDocuments, setShowDocuments] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAdmissionData = async () => {
            if (!collegeDetails?.university_id) return;

            try {
                const response = await fetch(`${Base_URL}/admission/university/${collegeDetails.university_id}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch admission data");
                }
                const data = await response.json();
                if (data.length > 0) {
                    setAdmissionData(data[0]);
                    parseAdmissionDesc(data[0].admission_desc);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAdmissionData();
    }, [collegeDetails?.university_id]);

    const parseAdmissionDesc = (html) => {
        const steps = [];
        let currentStep = {};

        const parser = new Parser(
            {
                onopentag(name) {
                    if (name === "h4") {
                        currentStep = { title: "", description: "" };
                    }
                },
                ontext(text) {
                    if (currentStep && !currentStep.title) {
                        currentStep.title = text.trim();
                    } else if (currentStep && currentStep.title) {
                        currentStep.description += text;
                    }
                },
                onclosetag(tagname) {
                    if (tagname === "h4") {
                        steps.push(currentStep);
                    }
                },
            },
            { decodeEntities: true }
        );

        parser.write(html);
        parser.end();

        setAdmissionSteps(steps);
    };



    return (
        <div className="px-5 py-8 bg-[#DCFFC4]">
            {/* Header with logo and university name */}
            <div className="text-black text-base font-medium flex flex-col gap-5 mb-8 items-center">
                <div className="flex flex-col md:flex-row items-center gap-3">
                    <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px]">
                        <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
                    </div>
                    <p className="md:text-3xl text-black font-[700] text-center">
                        {collegeDetails?.university_name}
                    </p>
                </div>

                {/* Admission Steps */}
                <div className="w-full">
                    <div className="flex flex-col items-center md:items-start">
                        <div className="w-full md:w-auto">
                            <div className="flex flex-col gap-4">
                                {admissionSteps.length > 0 ? (
                                    admissionSteps.map((step, index) => (
                                        <div key={index} className="flex flex-col md:flex-row items-start py-2 w-full">
                                            <div className="flex-shrink-0">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="white"
                                                    className="size-8"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.49 12L20.24 15.75M20.24 15.75L16.49 19.5M20.24 15.75H3.74V4.5" />
                                                </svg>
                                            </div>
                                            <div className="bg-white py-1 my-1 px-2 flex-shrink-0">
                                                <p className="text-base font-medium text-center md:text-left">
                                                    {step.title}
                                                </p>
                                            </div>
                                            <div className="px-2 py-2">
                                                {step.description && <p>{step.description}</p>}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-center">No admission steps available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Toggle Documents Section - Modified for responsive alignment */}
                <div className="w-full py-5">
                    <div className="flex justify-center md:justify-start">
                        <button 
                            onClick={() => setShowDocuments(!showDocuments)} 
                            className="text-white bg-[#3ACA2E] px-4 py-2 rounded-md w-full md:w-auto"
                        >
                            {showDocuments ? "- Documents Required for Admission" : "+ Documents Required for Admission"}
                        </button>
                    </div>

                    {showDocuments && (
                        <div className="mt-4 bg-white p-4 rounded-md shadow-md mx-auto md:mx-0 w-full md:max-w-lg">
                            <p className="text-lg font-semibold mb-2">List of Documents Required:</p>
                            {admissionData?.document_list ? (
                                <ul className="list-disc pl-5">
                                    {admissionData.document_list.map((doc, idx) => (
                                        <li key={idx}>{doc}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No documents required information available.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Admission;
