import React, { useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'; // Import useSelector

const AddCompany = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth); // Retrieve token from Redux store
  const [formData, setFormData] = useState({
    name: '',
  });
  const location = useLocation();
  const [picture, setPicture] = useState(null); // State to store the logo file
  const { mainTitle, subTitle } = location.state || {};
  const [loading, setLoading] = useState(false); // Track loading state

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleFileChange = (e) => {
    setPicture(e.target.files[0]); // Store the selected file
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting
    try {
      const data = new FormData();
      data.append('name', formData.name); // Add text field
      data.append('picture', picture); // Add file field
      await axios.post(`${Base_URL}/create-upload/company_master`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'authorization': `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });
      toast.success('Company added successfully!');
    } catch (error) {
      toast.error('Failed to add company.');
      console.error('Failed to add company:', error.response?.data || error.message);
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="company_name" className="mb-2 font-medium text-sm">Company Name</label>
                <input
                  type="text"
                  id="company_name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="company_logo" className="mb-2 font-medium text-sm">Company Logo</label>
                <input
                  type="file"
                  id="company_logo"
                  name="picture"
                  onChange={handleFileChange}
                  accept="image/*" // Accept only image files
                  className="border border-gray-300  rounded"
                  required
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
              {loading ? ( // Show spinner when loading
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Add Company'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCompany;
