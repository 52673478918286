import React, { useState } from "react";
import CareerSearch from "./CareerSearch";
import CareersGrid from "./CareersGrid";
import CareerTop from "./CareerTop";

function CareerPage() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div>
      <div className='pt-[100px] bg-[#F2FCEB]'>
        <CareerTop />
        <CareerSearch onSearchChange={setSearchQuery} />
        <CareersGrid searchQuery={searchQuery} />
        <div className='mt-20'></div>
      </div>
    </div>
  );
}

export default CareerPage;
