import axios from 'axios';
import { Parser } from 'htmlparser2';
import React, { useEffect, useState } from 'react';
import { Base_URL, Img_URL } from '../../../apiConfig';

const Placements = ({ collegeDetails }) => {
    const [placements, setPlacements] = useState([]);
    const [placementDesc, setPlacementDesc] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlacements = async () => {
            try {
                const response = await axios.get(`${Base_URL}/placement-admin/university/${collegeDetails.university_id}`);
                const placementData = response.data;

                setPlacements(placementData);
                setPlacementDesc(placementData[0]?.placement_desc || '');
            } catch (err) {
                setError('Failed to fetch placement data.');
            } finally {
                setLoading(false);
            }
        };

        fetchPlacements();
    }, [collegeDetails.university_id]);

    const parseHtml = (html) => {
        const result = [];
        let currentElement = null;
        const elementStack = [];

        const parser = new Parser(
            {
                onopentag(name, attributes) {
                    const newElement = { name, attributes, children: [] };
                    if (currentElement) {
                        currentElement.children.push(newElement);
                        elementStack.push(currentElement);
                    } else {
                        result.push(newElement);
                    }
                    currentElement = newElement;
                },
                ontext(text) {
                    if (currentElement) {
                        currentElement.children.push(text);
                    } else {
                        result.push(text);
                    }
                },
                onclosetag(tagname) {
                    if (elementStack.length > 0) {
                        currentElement = elementStack.pop();
                    } else {
                        currentElement = null;
                    }
                },
            },
            { decodeEntities: true }
        );

        parser.write(html);
        parser.end();

        return result;
    };

    const renderContent = (content) => {
        return content.map((item, index) => {
            if (typeof item === "string") {
                return item;
            }

            const Tag = item.name;

            const isVoidElement = [
                "br",
                "img",
                "hr",
                "input",
                "meta",
                "link",
            ].includes(Tag);

            const props = {
                ...item.attributes,
                key: index,
                className: Tag === "table" 
                    ? "min-w-full border-collapse border border-gray-300 my-4"
                    : "",
            };

            if (Tag === "tr") {
                props.className = index % 2 === 0 ? "bg-gray-100" : "bg-white";
            }

            if (Tag === "td" || Tag === "th") {
                props.className = "border border-gray-300 px-4 py-2";
            }

            if (isVoidElement) {
                return React.createElement(Tag, props);
            }

            return React.createElement(
                Tag,
                props,
                item.children && renderContent(item.children)
            );
        });
    };


    const parsedHtml = placementDesc ? parseHtml(placementDesc) : [];

    return (
        <div className="py-8 px-5 bg-[#DCFFC4] min-h-screen flex flex-col items-center">
            <div className="text-black text-base font-medium flex flex-col gap-5 items-center">
                <div className="flex items-center justify-center gap-3 py-2">
                    <p className="md:text-3xl text-black font-semibold text-[20px]">
                        {collegeDetails.university_name}
                    </p>
                </div>

                <p className="text-center text-lg text-black font-[500] mb-5">Placements</p>

                {/* Display the placement description using HTML parser */}
                <div className="mb-8 prose max-w-none">
                    {placementDesc ? renderContent(parsedHtml) : <p>No placement information available.</p>}
                </div>
            </div>
             {/* Company Details - Grid Display */}
             <div className="w-full">
                <h2 className="text-center text-lg font-semibold mb-4">Top Companies</h2>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4 p-4">
                    {placements[0]?.company_details?.map((company) => (
                        <div key={company.id} className="flex flex-col items-center mt-8">
                            <img
                                src={`${Img_URL}/${company.picture}`}
                                alt={company.name}
                                className="w-72 h-24 object-contain rounded-md"
                            />
                            {/* <p className="text-center text-sm font-medium mt-2">{company.name}</p> */}
                        </div>
                    ))}
                </div>
            </div>

           
        </div>
    );
};

export default Placements;

