import React, { useState, useEffect, useMemo } from "react";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { FaSort } from "react-icons/fa";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux";

const AllCredentials = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [credentials, setCredentials] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // Sorting state

  const token = useSelector((state) => state.auth.token); // Get the token from Redux store

  useEffect(() => {
    const fetchCredentials = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_URL}/universities_credentials/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add token in headers
            },
          }
        );
        setCredentials(response.data);
      } catch (err) {
        toast.error("Failed to fetch credentials");
      } finally {
        setLoading(false);
      }
    };
    fetchCredentials();
  }, [token]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color='lightgrey' />;
  };

  const sortedCredentials = useMemo(() => {
    let sortableCredentials = [...credentials];
    if (sortConfig.key) {
      sortableCredentials.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableCredentials;
  }, [credentials, sortConfig]);

  const filteredCredentials = sortedCredentials.filter((credential) =>
    credential.university_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalEntries = filteredCredentials.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const displayedCredentials = filteredCredentials.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () =>
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleNext = () =>
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this credential?"
    );
    if (!confirmDelete) return;

    try {
      await axios.put(
        `${Base_URL}/update/delete/universities_credentials/${id}`,
        {}, // Empty body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token in headers
          },
        }
      );
      toast.success("Credential deleted successfully");
      setCredentials((prev) =>
        prev.filter((credential) => credential.university_id !== id)
      );
    } catch (err) {
      toast.error(`Failed to delete credential!! ${err.response.statusText}`);
    }
  };

  return (
    <div className='bg-gray-100 p-6 min-h-screen overflow-auto'>
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className='bg-white p-4 rounded-md shadow-md'>
        <div className='flex justify-between items-center mb-4 pb-2 border-b border-gray-200'>
          <h2 className='text-xl font-semibold mb-4'>{subTitle}</h2>
          <button
            className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            onClick={() =>
              navigate("/admin/university/addCredentials", {
                state: { mainTitle, subTitle: "Add Credentials" },
              })
            }
          >
            + Add New
          </button>
        </div>

        <div className='flex justify-end mb-4'>
          <input
            type='text'
            className='px-3 py-1 border border-gray-300 rounded'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Search by University Name'
          />
        </div>

        {/* Table Headers with Sorting */}
        <div className='grid grid-cols-7 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md'>
          <div onClick={() => handleSort("id")}>
            <div className='flex items-center space-x-1'>
              <span>S.No</span>
              {getSortIcon("id")}
            </div>
          </div>
          <div onClick={() => handleSort("university_name")}>
            <div className='flex items-center space-x-1'>
              <span>University Name</span>
              {getSortIcon("university_name")}
            </div>
          </div>
          <div onClick={() => handleSort("email")}>
            <div className='flex items-center space-x-1'>
              <span>Username</span>
              {getSortIcon("email")}
            </div>
          </div>
          <div onClick={() => handleSort("phone_number")}>
            <div className='flex items-center space-x-1'>
              <span>Contact No</span>
              {getSortIcon("phone_number")}
            </div>
          </div>
          <div onClick={() => handleSort("created_on")}>
            <div className='flex items-center space-x-1'>
              <span> Created On</span>
              {getSortIcon("created_on")}
            </div>
          </div>
          <div onClick={() => handleSort("created_by")}>
            <div className='flex items-center space-x-1'>
              <span> Created By</span>
              {getSortIcon("created_by")}
            </div>
          </div>
          <div>Action</div>
        </div>

        {/* Table Body */}
        {displayedCredentials.length > 0 ? (
          displayedCredentials.map((credential, index) => (
            <div
              key={index}
              className='grid grid-cols-7 gap-2 text-tableTextColor text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100'
            >
              <div>{index + 1}</div>
              <div className='truncate'>{credential.university_name}</div>
              <div className='truncate'>{credential.email}</div>
              <div className='truncate'>{credential.phone_number}</div>
              <div>
                {credential.created_on
                  ? credential.created_on.split("T")[0]
                  : "N/A"}
              </div>
              <div>{credential.created_by}</div>
              <div className='flex space-x-2'>
                <button
                  onClick={() =>
                    navigate(
                      `/admin/university/editCredentials/${credential.university_id}`,
                      {
                        state: credential,
                      }
                    )
                  }
                  className='bg-cmsgreen hover:bg-hovergreen text-white p-2 rounded'
                >
                  <HiPencilAlt />
                </button>
                <button
                  className='bg-red-500 hover:bg-red-700 text-white p-2 rounded'
                  onClick={() => handleDelete(credential.university_id)}
                >
                  <HiTrash />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className='text-center py-4'>No Credentials found</div>
        )}

        {/* Pagination */}
        <div className='flex justify-between items-center mt-4'>
          <div className='flex items-center'>
            <span className='text-sm text-gray-600 mr-2'>Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => {
                setEntriesPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              className='border border-gray-300 rounded'
            >
              {[5, 10, 20].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className='flex items-center space-x-2'>
            <button
              onClick={handlePrevious}
              className='px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm'
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='text-sm text-gray-600'>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className='px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm'
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AllCredentials;
