import React, { useState, useEffect } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux"; // Import useSelector

const AddSubCourse = () => {
  const [formData, setFormData] = useState({
    sub_course_fees: "",
    sub_course_duration: "",
    sub_study_mode: "",
    sub_exams_accepted: [],
    sub_eligibility: "",
    sub_course_details: "",
    available_seats: "",
  });

  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [subCourses, setSubCourses] = useState([]);
  const [selectedSubCourse, setSelectedSubCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSubCourseDetails, setSelectedSubCourseDetails] = useState({
    courseMasterName: "",
    programMasterName: "",
  });
  const [courseMasterId, setCourseMasterId] = useState(null);

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(
          `${Base_URL}/get-values-array/universities`
        );
        const universityOptions = response.data.map((university) => ({
          value: university[0],
          label: university[1],
        }));
        setUniversities(universityOptions);
      } catch (error) {
        toast.error("Failed to load universities");
      }
    };
    fetchUniversities();
  }, []);

  useEffect(() => {
    const fetchSubCourses = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get-join-where`, {
          params: {
            options: JSON.stringify({
              table: "sub_courses_master",
              columns: [
                "sub_courses_master.*",
                "course_master.course_master_name",
                "program_master.program_master_name",
              ],
              joins: [
                {
                  table: "course_master",
                  on: "course_master.id = sub_courses_master.course_master_id",
                  type: "INNER",
                },
                {
                  table: "program_master",
                  on: "program_master.id = course_master.program_id",
                  type: "INNER",
                },
              ],
            }),
          },
        });
        const subCoursesOptions = response.data.map((sub) => ({
          value: sub.id,
          label: sub.sub_course_name,
          course_master_name: sub.course_master_name, // Include course name
          program_master_name: sub.program_master_name, // Include course name
          course_master_id: sub.course_master_id,
        }));
        setSubCourses(subCoursesOptions);
      } catch (error) {
        toast.error("Failed to load sub courses");
      }
    };
    fetchSubCourses();
  }, [selectedUniversity]);

  // const handleSubCourseChange = (selectedOption) => {
  //
  //   const subCourse = subCourses.find((sub) => sub.value === selectedOption?.value);

  //   if (subCourse) {
  //     setSelectedSubCourseDetails({
  //       courseMasterName: subCourse.course_master_name,
  //       programMasterName: subCourse.program_master_name,
  //       courseMasterId:subCourse.course_master_id
  //     });
  //     setCourseMasterId(subCourse.courseMasterId);
  //
  //   } else {
  //     setSelectedSubCourseDetails({
  //       courseMasterName: "",
  //       programMasterName: "",
  //     });
  //     setCourseMasterId(null)
  //   }

  //   setSelectedSubCourse(selectedOption);
  // };

  const handleSubCourseChange = (selectedOption) => {
    const subCourse = subCourses.find(
      (sub) => sub.value === selectedOption?.value
    );

    if (subCourse) {
      // Set selected sub-course details and course master id
      setSelectedSubCourseDetails({
        courseMasterName: subCourse.course_master_name,
        programMasterName: subCourse.program_master_name,
        courseMasterId: subCourse.course_master_id,
      });

      // Set the courseMasterId state with the selected course's courseMasterId
      setCourseMasterId(subCourse.course_master_id);

      // Log the courseMasterId directly from subCourse (not from state)
    } else {
      // Reset sub-course details and courseMasterId if no sub-course is selected
      setSelectedSubCourseDetails({
        courseMasterName: "",
        programMasterName: "",
      });
      setCourseMasterId(null);
    }

    // Set the selected sub-course
    setSelectedSubCourse(selectedOption);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSubmit = {
      ...formData,
      university_id: selectedUniversity.value,
      sub_course_master_id: selectedSubCourse.value,
      sub_exams_accepted: formData.sub_exams_accepted
        .split(",")
        .map((exam) => exam.trim()),
      course_master_id: courseMasterId,
    };

    try {
      await axios.post(`${Base_URL}/sub-courses`, dataToSubmit, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`, // Add token to authorization header
        },
        withCredentials: true, // Enable withCredentials
      });

      toast.success("Sub Course created successfully!");
      // // setFormData({
      // //   sub_course_fees: '',
      // //   sub_course_duration: '',
      // //   sub_eligibility: '',
      // //   available_seats: '',
      // //   sub_course_details: '',
      // //   sub_study_mode: ''
      // // });
      // setSelectedUniversity(null);
      // setSelectedSubCourse(null);
    } catch (error) {
      toast.error(
        `Failed to create sub course..${error.response.data.message}`
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg-gray-100 p-6'>
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className='bg-white p-4 rounded-md'>
        <h2 className='text-xl font-semibold mb-4'>Add Sub Course</h2>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            {/* Select University */}
            <div className='flex flex-col'>
              <label
                htmlFor='university_id'
                className='mb-2 font-medium text-sm'
              >
                Select University
              </label>
              <Select
                options={universities}
                value={selectedUniversity}
                onChange={(selectedOption) =>
                  setSelectedUniversity(selectedOption)
                }
                placeholder='Select a university'
                className='basic-single'
                classNamePrefix='select'
                isClearable
              />
            </div>

            {/* Select Sub Course */}
            <div className='flex flex-col'>
              <label htmlFor='subcourse' className='mb-2 font-medium text-sm'>
                Select Sub Course
              </label>
              <Select
                options={subCourses}
                value={selectedSubCourse}
                onChange={handleSubCourseChange}
                placeholder='Select a Sub Course'
                className='basic-single'
                classNamePrefix='select'
                isClearable
              />
            </div>

            {/* Program Name (Disabled) */}
            <div className='flex flex-col'>
              <label
                htmlFor='programMasterName'
                className='mb-2 font-medium text-sm'
              >
                Program Name
              </label>
              <input
                type='text'
                id='programMasterName'
                value={selectedSubCourseDetails.programMasterName}
                disabled
                className='border border-gray-300 p-2 rounded bg-gray-100 text-gray-500'
              />
            </div>

            {/* Course Name (Disabled) */}
            <div className='flex flex-col'>
              <label
                htmlFor='courseMasterName'
                className='mb-2 font-medium text-sm'
              >
                Course Name
              </label>
              <input
                type='text'
                id='courseMasterName'
                value={selectedSubCourseDetails.courseMasterName}
                disabled
                className='border border-gray-300 p-2 rounded bg-gray-100 text-gray-500'
              />
            </div>

            {/* Sub Course Fees */}
            <div className='flex flex-col'>
              <label htmlFor='course_fees' className='mb-2 font-medium text-sm'>
                Sub Course Fees
              </label>
              <input
                type='text'
                id='course_fees'
                name='sub_course_fees'
                value={formData.sub_course_fees}
                onChange={handleInputChange}
                className='border border-gray-300 p-2 rounded'
                placeholder='Enter Sub Course Fees'
                required
              />
            </div>

            {/* Sub Course Duration */}
            <div className='flex flex-col'>
              <label
                htmlFor='course_duration'
                className='mb-2 font-medium text-sm'
              >
                Sub Course Duration
              </label>
              <input
                type='text'
                id='course_duration'
                name='sub_course_duration'
                value={formData.sub_course_duration}
                onChange={handleInputChange}
                className='border border-gray-300 p-2 rounded'
                placeholder='Enter Sub Course Duration'
                required
              />
            </div>

            {/* Eligibility */}
            <div className='flex flex-col'>
              <label htmlFor='eligibility' className='mb-2 font-medium text-sm'>
                Eligibility
              </label>
              <input
                type='text'
                id='eligibility'
                name='sub_eligibility'
                value={formData.sub_eligibility}
                onChange={handleInputChange}
                className='border border-gray-300 p-2 rounded'
                placeholder='Enter Eligibility'
                required
              />
            </div>

            {/* Exams Accepted */}
            <div className='flex flex-col'>
              <label
                htmlFor='exams_accepted'
                className='mb-2 font-medium text-sm'
              >
                Exams Accepted
              </label>
              <textarea
                id='exams_accepted'
                name='sub_exams_accepted'
                value={formData.sub_exams_accepted}
                onChange={handleInputChange}
                className='border border-gray-300 p-2 rounded'
                rows='4'
                required
              />
            </div>

            {/* Available Seats */}
            <div className='flex flex-col'>
              <label
                htmlFor='available_seats'
                className='mb-2 font-medium text-sm'
              >
                Available Seats
              </label>
              <input
                type='text'
                id='available_seats'
                name='available_seats'
                value={formData.available_seats}
                onChange={handleInputChange}
                className='border border-gray-300 p-2 rounded'
                placeholder='Enter Available Seats'
                required
              />
            </div>

            {/* Study Mode */}
            <div className='flex flex-col'>
              <label htmlFor='study_mode' className='mb-2 font-medium text-sm'>
                Study Mode
              </label>
              <input
                type='text'
                id='study_mode'
                name='sub_study_mode'
                value={formData.sub_study_mode}
                onChange={handleInputChange}
                className='border border-gray-300 p-2 rounded'
                placeholder='Enter Study Mode'
                required
              />
            </div>

            {/* Sub Course Description */}
            <div className='flex flex-col md:col-span-2'>
              <label
                htmlFor='course_details'
                className='mb-2 font-medium text-sm'
              >
                Sub Course Description
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.sub_course_details}
                onChange={(event, editor) =>
                  setFormData((prev) => ({
                    ...prev,
                    sub_course_details: editor.getData(),
                  }))
                }
              />
            </div>
          </div>
          <button
            type='submit'
            className='mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
          >
            {loading ? (
              <div className='flex justify-center items-center'>
                <svg
                  className='animate-spin h-5 w-5 text-white mr-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddSubCourse;
