import React from "react";
import { Route, Routes } from "react-router-dom";
// import HomePage from "../landing/pages/HomePage";
import AddPlacementData from "../dashboard/sidebarpages/placements/AddPlacementData";
import AllPlacements from "../dashboard/sidebarpages/placements/AllPlacements";
import EditPlacement from "../dashboard/sidebarpages/placements/EditPlacement";
import AddProfessor from "../dashboard/sidebarpages/professor/AddProfessor";
import College from "../pages/College";
import Landing from "../pages/Landing";
// import EditProfessor from "../dashboard/sidebarpages/professor/EditProfessor";
import AddEvent from "../dashboard/sidebarpages/events/AddEvent";
import AllEvents from "../dashboard/sidebarpages/events/AllEvents";
import Dashboard from "./../dashboard/components/dashboardpage/DashboardPage";
// import EditEvent from './../dashboard/sidebarpages/events/EditEvent';
import AdminDashboard from "../Admin/dashboard/components/AdminDashboard/AdminDashoard";
import AddCourses from "../Admin/pages/courses/AddCourses";
import AllCourses from "../Admin/pages/courses/AllCourses";
import EditCourses from "../Admin/pages/courses/EditCourses";
import AddPrograms from "../Admin/pages/programs/AddPrograms";
import AllPrograms from "../Admin/pages/programs/AllPrograms";
import EditPrograms from "../Admin/pages/programs/EditPrograms";
import AddUniversity from "../Admin/pages/universities/AddUniversity";
import AllUniversity from "../Admin/pages/universities/AllUniversity";
import EditUniversity from "../Admin/pages/universities/EditUniversity";
import AddTestimonial from "../dashboard/sidebarpages/testimonial/AddTestimonial";
import AllTestimonial from "../dashboard/sidebarpages/testimonial/AllTestimonial";
import EditTestimonial from "../dashboard/sidebarpages/testimonial/EditTestimonial";

import AddAdmissions from "../Admin/pages/admissions/AddAdmissions";
import AllAdmissions from "../Admin/pages/admissions/AllAdmissions";
import EditAdmissions from "../Admin/pages/admissions/EditAdmissions";
import AddAdminFaq from "../Admin/pages/faqs/AddAdminFaq";
import AllAdminFaq from "../Admin/pages/faqs/AllAdminFaq";
import EditAdminFaq from "../Admin/pages/faqs/EditAdminFaq";
import AddScholarship from "../Admin/pages/scholarship/AddScholarship";
import AllScholarship from "../Admin/pages/scholarship/AllScholarship";
import EditScholarship from "../Admin/pages/scholarship/EditScholarship";
import LandingUniversity from "../components/Landing/LandingUniversity/LandingUniversity";
import AddAmbassador from "../dashboard/sidebarpages/ambassadors/AddAmbassador";
import AllAmbassadors from "../dashboard/sidebarpages/ambassadors/AllAmbassadors";
import EditEvent from "../dashboard/sidebarpages/events/EditEvent";
import AddFAQ from "../dashboard/sidebarpages/faq/AddFAQ";
import AllFaq from "../dashboard/sidebarpages/faq/AllFaq";
import EditFAQ from "../dashboard/sidebarpages/faq/EditFAQ";
import AddNews from "../dashboard/sidebarpages/news/AddNews";
import AllNews from "../dashboard/sidebarpages/news/AllNews";
import EditNews from "../dashboard/sidebarpages/news/EditNews";
import AllProfessor from "../dashboard/sidebarpages/professor/AllProfessor";
import EditProfessor from "../dashboard/sidebarpages/professor/EditProfessor";

import CareerForm from "../Admin/pages/careers/CareerForm";
import SubCareerForm from "../Admin/pages/careers/SubCareerForm";
import AddCredentials from "../Admin/pages/credentials/AddCredentials";
import AllCredentials from "../Admin/pages/credentials/AllCredentials";
import EditCredentials from "../Admin/pages/credentials/EditCredentials";
import AddExam from "../Admin/pages/exams/AddExam";
import AddExamSection from "../Admin/pages/exams/AddExamSection";
import AddExamStream from "../Admin/pages/exams/AddExamStream";
import AddSectionDetails from "../Admin/pages/exams/AddSectionDetails";
import AllExam from "../Admin/pages/exams/AllExam";

import AllSectionDetails from "../Admin/pages/exams/AllSectionDetails";
import EditExamSectionDetails from "../Admin/pages/exams/EditExamSectionDetails";
import AddAdminNews from "../Admin/pages/news/AddAdminNews";
import AllAdminNews from "../Admin/pages/news/AllAdminNews";
import EditAdminNews from "../Admin/pages/news/EditAdminNews";
import AddPlacements from "../Admin/pages/placements/AddPlacements";
import AllPlacement from "../Admin/pages/placements/AllPlacements";
import EditPlacements from "../Admin/pages/placements/EditPlacements";
import AddSubCourse from "../Admin/pages/subCourses/AddSubCourse";
import AllSubCourses from "../Admin/pages/subCourses/AllSubCourses";
import EditSubCourses from "../Admin/pages/subCourses/EditSubCourses";
import AddGallery from "../Admin/pages/universities/AddGallery";
import AddCity from "../Admin/pages/universityMaster/AddCity";
import AddCompany from "../Admin/pages/universityMaster/AddCompany";
import AddCountry from "../Admin/pages/universityMaster/AddCountry";
import AddState from "../Admin/pages/universityMaster/AddState";
import AddStreams from "../Admin/pages/universityMaster/AddStreams";
import AddSubCourses from "../Admin/pages/universityMaster/AddSubCourses";
import AddUniCourses from "../Admin/pages/universityMaster/AddUniCourses";
import AddUniPrograms from "../Admin/pages/universityMaster/AddUniPrograms";
import AddUniversityCity from "../Admin/pages/universityMaster/AddUniversityCity";
import Authentication from "../components/Authentication/dashboard/Authentication";
import CourseDetail from "../components/College/Courses/CourseDetail";
import Courses from "../components/College/Courses/Courses";
import SubCourse from "../components/College/Courses/SubCourse";
import LandingUniversityFilterPage from "../components/Landing/LandingUniversity/LandingUniversityFilterPage";
import EditAmbassador from "../dashboard/sidebarpages/ambassadors/EditAmbassadors";
import Analytics from "../dashboard/sidebarpages/analytics/Analytics";
import AddLeads from "../dashboard/sidebarpages/leads/AddLeads";
import AllLeads from "../dashboard/sidebarpages/leads/AllLeads";
import LeadDetails from "../dashboard/sidebarpages/leads/LeadDetails";
import AdmissionProspect from "../dashboard/sidebarpages/student/AdmissionProspect";
import InterestedProspect from "../dashboard/sidebarpages/student/InterestedProspect";
import CareerLayout from "../pages/CareerLayout";
import CareerMaster from "../pages/CareerMaster";
import CareerPage from "../pages/CareerPage";
import CareerTab from "../pages/CareerTab";
import CareersGrid from "../pages/CareersGrid";
import ProgramCourses from "../pages/ProgramCourses";
import SubCareerMaster from "../pages/SubCareerMaster";
import PrivateRoute from "./PrivateRoute";

import Exam from "../pages/Exam";

import StudentLeads from "../Admin/pages/Leads.jsx/StudentLeads";
import AdminStreamForm from "../Admin/pages/careers/AdminStreamForm";
import AllAdminStreamForm from "../Admin/pages/careers/AllAdminStreamForm";
import AllCareerForm from "../Admin/pages/careers/AllCareerForm";
import AllSubCareerForm from "../Admin/pages/careers/AllSubCareerForm";
import AllSubCareerMapping from "../Admin/pages/careers/AllSubCareerMapping";
import EditAdminStreamForm from "../Admin/pages/careers/EditAdminStreamForm";
import EditCareerForm from "../Admin/pages/careers/EditCareerForm";
import EditSubCareerForm from "../Admin/pages/careers/EditSubCareerForm";
import EditSubCareerMapping from "../Admin/pages/careers/EditSubCareerMapping";
import SubCareerMapping from "../Admin/pages/careers/SubCareerMapping";
import AddCourseMasterDetails from "../Admin/pages/coursesMaster/AddCourseMasterDetails";
import AllCourseMasterDetails from "../Admin/pages/coursesMaster/AllCourseMasterDetails";
import AllDuration from "../Admin/pages/coursesMaster/AllDuration";
import AllModes from "../Admin/pages/coursesMaster/AllModes";
import CourseDurationMaster from "../Admin/pages/coursesMaster/CourseDurationMaster";
import CourseModesMaster from "../Admin/pages/coursesMaster/CourseModesMaster";
import EditCourseMasterDetails from "../Admin/pages/coursesMaster/EditCourseMasterDetails";
import EditDuration from "../Admin/pages/coursesMaster/EditDuration";
import EditModes from "../Admin/pages/coursesMaster/EditModes";
import AllDropDownNews from "../Admin/pages/dropDownNews/AllDropDownNews";
import DropDownNews from "../Admin/pages/dropDownNews/DropDownNews";
import EditDropDownNews from "../Admin/pages/dropDownNews/EditDropDownNews";
import AddExamLevel from "../Admin/pages/exams/AddExamLevel";
import AllLevels from "../Admin/pages/exams/AllLevels";
import EditExam from "../Admin/pages/exams/EditExam";
import EditLevels from "../Admin/pages/exams/EditLevels";
import AddGeneralFaqs from "../Admin/pages/generalFaqs.jsx/AddGeneralFaqs";
import AllGeneralFaqs from "../Admin/pages/generalFaqs.jsx/AllGeneralFaqs";
import EditGeneralFaqs from "../Admin/pages/generalFaqs.jsx/EditGeneralFaqs";
import AllMaster from "../Admin/pages/universityMaster/AllMaster";
import AllUniversityCity from "../Admin/pages/universityMaster/AllUniversityCity";
import EditCity from "../Admin/pages/universityMaster/EditCity";
import EditCompany from "../Admin/pages/universityMaster/EditCompany";
import EditCountry from "../Admin/pages/universityMaster/EditCountry";
import EditState from "../Admin/pages/universityMaster/EditState";
import EditStreams from "../Admin/pages/universityMaster/EditStreams";
import EditSubCourse from "../Admin/pages/universityMaster/EditSubCourses";
import EditUniCourses from "../Admin/pages/universityMaster/EditUniCourses";
import EditUniPrograms from "../Admin/pages/universityMaster/EditUniPrograms";
import EditUniversityCity from "../Admin/pages/universityMaster/EditUniversityCity";
import ScrollToTop from "../ScrollToTop";
import AllExams from "../components/Exams/AllExams/AllExams";
import AboutUs from "../components/Footer/AboutUs";
import ContactUs from "../components/Footer/ContactUs";
import PrivacyPolicy from "../components/Footer/PrivacyPolicy";
import TermsConditions from "../components/Footer/TermsConditions";
import LatestUpdates from "../pages/LatestUpdates";
import NewsDetail from "../pages/NewsDetail";
import ParticularNews from "../pages/ParticularNews";
import AllCoursesList from "../pages/coursespages/AllCoursesList";
import CourseDetailPage from "../pages/coursespages/CourseDetailPage";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  return (
    <Routes>

      <Route path='/' element={<Landing />} />
      <Route path='/colleges' element={<LandingUniversity />} />
      <Route
        path='/landinguniversity/:type/:id/:name'
        element={<LandingUniversity />}
      />
      <Route path='/filter' element={<LandingUniversityFilterPage />} />
      <Route
        path='/college/:university_name'
        element={
          <ScrollToTop>
            <College />
          </ScrollToTop>
        }
      />
      <Route path='/college/:university_id/courses' element={<Courses />} />
      <Route
        path='/coursedetail/:university_id/:id'
        element={<CourseDetail />}
      />
      <Route path='/subcourse/:university_id/:id' element={<SubCourse />} />
      <Route
        path='/career'
        element={
          <ScrollToTop>
            <CareerPage />
          </ScrollToTop>
        }
      />
      <Route
        path='/career/grid'
        element={
          <ScrollToTop>
            <CareersGrid />
          </ScrollToTop>
        }
      />
      <Route
        path='/career/tab/:id/:stream_name'
        element={
          <ScrollToTop>
            <CareerTab />
          </ScrollToTop>
        }
      />
      <Route
        path='/program/:programId'
        element={
          <ScrollToTop>
            <ProgramCourses />
          </ScrollToTop>
        }
      />
      <Route
        path='/careerMaster/:courseId'
        element={
          <ScrollToTop>
            <CareerMaster />
          </ScrollToTop>
        }
      />
      <Route
        path='/subCareerMaster/:careerId'
        element={
          <ScrollToTop>
            <SubCareerMaster />
          </ScrollToTop>
        }
      />
      <Route path='/career/:career/:item' element={<ScrollToTop><CareerLayout /></ScrollToTop>} />
      {/* courses pages */}
      <Route path='/courses/details/:name' element={<CourseDetailPage />} />
      <Route path='/courses/all' element={<AllCoursesList />} />
      <Route path='/courses/all/filter' element={<AllCoursesList />} />

      {/* Exam page routes */}
      <Route path='/exams/:id' element={<Exam />} />
      <Route path='/exam-preparation' element={<AllExams />} />
      <Route path='/exams/filter/:filters-exam-in-india' element={<AllExams />} />

      <Route path='/latestUpdates' element={<LatestUpdates />} />
      <Route path='/newsDetail' element={<NewsDetail />} />
      <Route path='/particularNews/:id' element={<ParticularNews />} />
      <Route path='/college/particularNews/:id' element={<ParticularNews />} />
      <Route path='/authentication' element={<Authentication />} />

      {/* Footer Pages */}
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/terms-and-Conditions' element={<TermsConditions />} />

      <Route
        path='/dashboard'
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      >
        <Route index path='analytics' element={<Analytics />} />{" "}
        {/* Default content */}
        {/* Nested routes for events */}
        <Route path='leads'>
          <Route path='add' element={<AddLeads />} />
          <Route path='all' element={<AllLeads />} />
          <Route path='details/:id' element={<LeadDetails />} />
        </Route>
        <Route path='events'>
          <Route path='add' element={<AddEvent />} />
          {<Route path='edit/:id' element={<EditEvent />} />}
          <Route path='all' element={<AllEvents />} />
        </Route>
        <Route path='testimonials'>
          <Route path='add' element={<AddTestimonial />} />
          {<Route path='edit/:id' element={<EditTestimonial />} />}
          <Route path='all' element={<AllTestimonial />} />
        </Route>
        {/* Other routes */}
        <Route path='professors'>
          <Route path='add' element={<AddProfessor />} />
          <Route path='all' element={<AllProfessor />} />
          <Route path='edit/:id' element={<EditProfessor />} />
        </Route>
        <Route path='students'>
          <Route path='interested' element={<InterestedProspect />} />
          <Route path='admission' element={<AdmissionProspect />} />
          {/* <Route path="edit/:id" element={<EditProfessor/>} />  */}
        </Route>
        {/* <Route path="professors/edit" element={<EditProfessor />} /> */}
        <Route path='placements'>
          <Route path='add' element={<AddPlacementData />} />
          <Route path='edit/:id' element={<EditPlacement />} />
          <Route path='all' element={<AllPlacements />} />
        </Route>
        <Route path='festivals' element={<div>Festivals Content</div>} />
        <Route path='news'>
          <Route path='add' element={<AddNews />} />
          <Route path='edit/:id' element={<EditNews />} />
          <Route path='all' element={<AllNews />} />
        </Route>
        <Route path='gallery' element={<div>Gallery Content</div>} />
        <Route path='faqs'>
          <Route path='add' element={<AddFAQ />} />
          <Route path='all' element={<AllFaq />} />
          <Route path='edit/:id' element={<EditFAQ />} />
        </Route>
        <Route path='ambassadors'>
          <Route path='add' element={<AddAmbassador />} />
          <Route path='all' element={<AllAmbassadors />} />
          <Route path='edit/:id' element={<EditAmbassador />} />
        </Route>
        <Route
          path='testimonials'
          element={<div>Student Testimonials Content</div>}
        />
        {/* <Route path="ambassadors" element={<AddAmbassadors/>} /> */}
      </Route>

      <Route
        path='/admin'
        element={
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        }
      >
        <Route path='university'>
          <Route path='all' element={<AllUniversity />} />
          <Route path='add' element={<AddUniversity />} />
          <Route path='edit/:id' element={<EditUniversity />} />

          <Route path='addGallery' element={<AddGallery />} />

          <Route path='allCredentials' element={<AllCredentials />} />
          <Route path='addCredentials' element={<AddCredentials />} />
          <Route path='editCredentials/:id' element={<EditCredentials />} />
        </Route>

        <Route path='universityMaster'>
          <Route path='addStream' element={<AddStreams />} />
          <Route path='addProgram' element={<AddUniPrograms />} />
          <Route path='addCourse' element={<AddUniCourses />} />
          <Route path='subCourse' element={<AddSubCourses />} />
          <Route path='company' element={<AddCompany />} />
          <Route path='addCountry' element={<AddCountry />} />
          <Route path='addState' element={<AddState />} />
          <Route path='addCity' element={<AddCity />} />
          <Route path='addUniversityCity' element={<AddUniversityCity />} />
          <Route path='editCity/:cityId' element={<EditCity />} />
          <Route path='editCompany/:companyId' element={<EditCompany />} />
          <Route path='editCountry/:countryId' element={<EditCountry />} />
          <Route path='editStreams/:streamId' element={<EditStreams />} />
          <Route path='editState/:stateId' element={<EditState />} />
          <Route path='editSubCourse/:subCourseId' element={<EditSubCourse />} />
          <Route path='editUniversityCity/:universityCityId' element={<EditUniversityCity />} />
          <Route path='editUniPrograms/:programId' element={<EditUniPrograms />} />
          <Route path='editUniCourses/:courseId' element={<EditUniCourses />} />
          <Route path='allMaster' element={<AllMaster />} />
          <Route path='allUniversityCity' element={<AllUniversityCity />} />
        </Route>

        <Route path='programs'>
          <Route path='all' element={<AllPrograms />} />
          <Route path='add' element={<AddPrograms />} />
          <Route path='edit/:id' element={<EditPrograms />} />
        </Route>

        <Route path='courses'>
          <Route path='all' element={<AllCourses />} />
          <Route path='add' element={<AddCourses />} />
          <Route path='edit/:id' element={<EditCourses />} />
        </Route>
        <Route path='subCourses'>
          <Route path='all' element={<AllSubCourses />} />
          <Route path='add' element={<AddSubCourse />} />
          <Route path='edit/:id' element={<EditSubCourses />} />
        </Route>

        <Route path='admissions'>
          <Route path='all' element={<AllAdmissions />} />
          <Route path='add' element={<AddAdmissions />} />
          <Route path='edit/:id' element={<EditAdmissions />} />
        </Route>
        <Route path='scholarship'>
          <Route path='all' element={<AllScholarship />} />
          <Route path='add' element={<AddScholarship />} />
          <Route path='edit/:id' element={<EditScholarship />} />
        </Route>

        <Route path='placement'>
          <Route path='all' element={<AllPlacement />} />
          <Route path='add' element={<AddPlacements />} />
          <Route path='edit/:id' element={<EditPlacements />} />
        </Route>

        <Route path='exams'>
          <Route path='allExams' element={<AllExam />} />
          <Route path='addExams' element={<AddExam />} />
          <Route path='editExam/:id' element={<EditExam/>} />
          <Route path='addExamstream' element={<AddExamStream />} />
          <Route path='addSection' element={<AddExamSection />} />
   
          <Route path='addSectionDetails' element={<AddSectionDetails />} />
          <Route path='allSectionDetails' element={<AllSectionDetails />} />
          <Route
            path='EditSectionDetails/:id'
            element={<EditExamSectionDetails />}
          />
          <Route path='addExamLevel' element={<AddExamLevel />} />
          <Route path='allLevel' element={<AllLevels/>} />
          <Route path='editLevel/:id' element={<EditLevels/>} />
        </Route>

        <Route path='faqs'>
          <Route path='add' element={<AddAdminFaq />} />
          <Route path='all' element={<AllAdminFaq />} />
          <Route path='edit/:id' element={<EditAdminFaq />} />
        </Route>

        <Route path='news'>
          <Route path='add' element={<AddAdminNews />} />
          <Route path='all' element={<AllAdminNews />} />
          <Route path='edit/:id' element={<EditAdminNews />} />
          <Route path='addDropDownNews' element={<DropDownNews />} />
          <Route path='allDropDownNews' element={<AllDropDownNews />} />
          <Route path='editDropDownNews/:id' element={<EditDropDownNews/>} />
        </Route>

        <Route path='career'>
          <Route path='careerForm' element={<CareerForm />} />
          <Route path='AllCareerForm' element={<AllCareerForm/>} />
          <Route path='edit/:id' element={<EditCareerForm/>} />
          <Route path='subCareerForm' element={<SubCareerForm />} />
          <Route path='AllSubCareerForm' element={<AllSubCareerForm/>} />
          <Route path='EditSubCareerForm/:id' element={<EditSubCareerForm/>} />
          <Route path='subCareerMapping' element={<SubCareerMapping />} />
          <Route path='AllSubCareerMapping' element={<AllSubCareerMapping/>} />
          <Route path='EditSubCareerMapping/:id' element={<EditSubCareerMapping/>} />
          <Route path='EditAdminStreamForm/:id' element={<EditAdminStreamForm/>} />
          <Route path='adminStream' element={<AdminStreamForm />} />
          <Route path='AllAdminStream' element={<AllAdminStreamForm />} />
        </Route>

        <Route path='courseMasterDetails'>
        <Route path='all' element={<AllCourseMasterDetails />} />
          <Route path='add' element={<AddCourseMasterDetails />} />
          <Route path='edit/:id' element={<EditCourseMasterDetails />} />
          <Route path='courseDuration' element={<CourseDurationMaster />} />
          <Route path='allDuration' element={<AllDuration/>} />
          <Route path='editDuration/:id' element={<EditDuration />} />
          <Route path='courseMode' element={<CourseModesMaster />} />
          <Route path='allModes' element={<AllModes/>} />
          <Route path='editMode/:id' element={<EditModes />} />
        </Route>

        <Route path='studentLeads'>
          <Route path='all' element={<StudentLeads />} />
        </Route>
        <Route path='websiteFaqs'>
          <Route path='add' element={<AddGeneralFaqs />} />
          <Route path='all' element={<AllGeneralFaqs />} />
          <Route path='edit/:id' element={<EditGeneralFaqs />} />
        </Route>
      </Route>
      

      {/* College listing routes */}
      <Route path='/colleges' element={<LandingUniversity />} />

      {/* Single program colleges */}
      <Route
        path='/:degree-colleges-in-india'
        element={<LandingUniversity />}
      />

      {/* Colleges in city */}
      <Route path='/colleges-in-:city' element={<LandingUniversity />} />

      {/* Colleges in state */}
      <Route path='/colleges-in-:state' element={<LandingUniversity />} />

      {/* Multiple filters (program + specialization + location) */}
     

      {/* Legacy routes that should be kept for backward compatibility */}
      <Route
        path='/landinguniversity/:type/:id/:name'
        element={<LandingUniversity />}
      />
      <Route
        path='/filter/:filters-colleges-in-india'
        element={<LandingUniversity />}
      />

      {/* Single filter routes */}
     
      <Route path='/colleges-in-:location' element={<LandingUniversity />} />
    

      {/* Multiple filter routes */}
   
      <Route
        path='/filter/:filters-colleges-in-:location'
        element={<LandingUniversity />}
      />
         <Route
        path='/colleges/featured-colleges'
        element={<LandingUniversity />}
      />

<Route
        path='/colleges/popular-colleges'
        element={<LandingUniversity />}
      />
  
  <Route 
  
  />

    </Routes>
  );
};

export default AppRoutes;