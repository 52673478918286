import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditAdminStreamForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const [formData, setFormData] = useState({
    streams_id: '',
    about: '',
    eligibility: '',
    job_roles: '',
    work_description: '',
    salary: '',
    prep_books: '',
  });

  useEffect(() => {
    // Fetch streams for dropdown
    const fetchStreams = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/streams`);
        setDropdownOptions(response.data);
      } catch (error) {
        console.error('Error fetching streams:', error);
        toast.error('Failed to load stream options');
      }
    };

    // Set form data from location state
    const streamFormData = location.state;
    if (streamFormData) {
      setFormData({
        streams_id: streamFormData.streams_id,
        about: streamFormData.about,
        eligibility: streamFormData.eligibility,
        job_roles: streamFormData.job_roles,
        work_description: streamFormData.work_description,
        salary: streamFormData.salary,
        prep_books: streamFormData.prep_books,
      });
    }

    fetchStreams();
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.put(`${Base_URL}/streams_details/${id}`, formData);

      toast.success('Stream details updated successfully!');
      setTimeout(() => {
        navigate('/admin/stream/AllAdminStreamForm');
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error('Error updating stream details:', error);
      toast.error('Failed to update stream details');
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderSection title="Edit Stream Information" breadcrumb={['Stream', 'Edit Stream Information']} />
      <div className="bg-white p-4 rounded-md">
        <h2 className="text-xl font-semibold mb-4">Edit Stream Information</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Stream Dropdown */}
            <div className="flex flex-col">
              <label htmlFor="streams_id" className="mb-2 font-medium text-sm">Stream</label>
              <select
                id="streams_id"
                name="streams_id"
                value={formData.streams_id}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                required
              >
                <option value="">Select a Stream</option>
                {dropdownOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.stream_name}
                  </option>
                ))}
              </select>
            </div>

            {/* About */}
            <div className="flex flex-col">
              <label htmlFor="about" className="mb-2 font-medium text-sm">About</label>
              <textarea
                id="about"
                name="about"
                value={formData.about}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                placeholder="Enter About"
                required
              />
            </div>

            {/* Eligibility */}
            <div className="flex flex-col">
              <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Eligibility</label>
              <input
                type="text"
                id="eligibility"
                name="eligibility"
                value={formData.eligibility}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Eligibility"
                required
              />
            </div>

            {/* Job Roles */}
            <div className="flex flex-col">
              <label htmlFor="job_roles" className="mb-2 font-medium text-sm">Job Roles</label>
              <input
                type="text"
                id="job_roles"
                name="job_roles"
                value={formData.job_roles}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Job Roles"
                required
              />
            </div>

            {/* Work Description */}
            <div className="flex flex-col">
              <label htmlFor="work_description" className="mb-2 font-medium text-sm">Work Description</label>
              <textarea
                id="work_description"
                name="work_description"
                value={formData.work_description}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                placeholder="Enter Work Description"
                required
              />
            </div>

            {/* Salary */}
            <div className="flex flex-col">
              <label htmlFor="salary" className="mb-2 font-medium text-sm">Salary</label>
              <input
                type="text"
                id="salary"
                name="salary"
                value={formData.salary}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Salary"
                required
              />
            </div>

            {/* Prep Books */}
            <div className="flex flex-col">
              <label htmlFor="prep_books" className="mb-2 font-medium text-sm">Prep Books</label>
              <textarea
                id="prep_books"
                name="prep_books"
                value={formData.prep_books}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                rows="4"
                placeholder="Enter Prep Books"
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md flex items-center justify-center"
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Updating...
              </div>
            ) : (
              'Update'
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditAdminStreamForm; 