import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Base_URL } from "../apiConfig";


const ProgramCourses = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const programName = query.get('program_master_name');
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const apiOptions = JSON.stringify({
          table: "course_master",
          columns: ["course_master.*", "program_master.program_master_name"],
          joins: [
            { table: "program_master", on: "course_master.program_id = program_master.id", type: "INNER" },
          ],
          where: {
            "program_master.status": "1",
            "program_master.program_master_name": programName
          }
        });

        const response = await axios.get(`${Base_URL}/get-join-where/?options=${encodeURIComponent(apiOptions)}`);
        setCourses(response.data || []);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (programName) {
      fetchCourses();
    }
  }, [programName]);

  return (
    <div className='flex flex-col min-h-screen'>
     

      <div className='flex-grow p-6 mt-20 bg-[#F2FCEB]'>
        <div className='container mx-auto'>
          <h2 className='text-2xl font-bold mb-6 font-dm-sans mt-10 text-center'>
            Courses for {programName}
          </h2>
          <div className='grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
            {courses.length > 0 ? (
              courses.map((course) => (
                <div
                  key={course.id}
                  className='border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col'
                >
                  <div className='flex-grow' />
                  <div className='absolute bottom-0 w-full'>
                    <button
                      className='w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2'
                    >
                      {course.course_master_name}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No courses found for this program.</p>
            )}
          </div>
        </div>
      </div>


    </div>
  );
};

export default ProgramCourses;
