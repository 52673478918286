import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LuPlus } from "react-icons/lu";
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

const EditCourses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const course = location.state; // The course data passed from AllCourses

  const [formData, setFormData] = useState({
    course_master_name: course?.course_master_name || '',
    program_master_name: course?.program_master_name || '',
    university_name: course?.university_name || '',
    course_fees: course?.course_fees || '',
    course_duration: course?.course_duration || '',
    eligibility: course?.eligibility || '',
    course_details: course?.course_details || '',
    course_exams_accepted: course?.course_exams_accepted || [],
  });
  
  const [newExam, setNewExam] = useState('');
  const [loading, setLoading] = useState(false); // Track loading state
  const token = useSelector((state) => state.auth.token); // Retrieve token from Redux

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleExamAdd = (e) => {
    e.preventDefault();
    if (newExam && !formData.course_exams_accepted.includes(newExam)) {
      setFormData(prev => ({ ...prev, course_exams_accepted: [...prev.course_exams_accepted, newExam] }));
      setNewExam('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting
    try {
      const updatedCourse = { ...formData };
      await axios.put(`${Base_URL}/courses/${course.id}`, updatedCourse, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`  // Add token authorization
      },
      withCredentials: true  // Enable withCredentials for the request
      });
      toast.success('Course updated successfully!');
      setTimeout(() => {
        navigate('/admin/courses/all'); // Navigate back to the list after success
    }, 2000);
     
    } catch (error) {
      toast.error('Failed to update course.');
      console.error('Failed to update course:', error.response?.data || error.message);
      setLoading(false)
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-gray-100 p-6 min-h-screen">
        <HeaderTitle mainTitle="Edit Course" subTitle="Update Course Details" />

        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="course_master_name" className="mb-2 font-medium text-sm">Course Name</label>
                <input
                  type="text"
                  id="course_master_name"
                  name="course_master_name"
                  value={formData.course_master_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="course_fees" className="mb-2 font-medium text-sm">Course Fees</label>
                <input
                  type="text"
                  id="course_fees"
                  name="course_fees"
                  value={formData.course_fees}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="course_duration" className="mb-2 font-medium text-sm">Course Duration</label>
                <input
                  type="text"
                  id="course_duration"
                  name="course_duration"
                  value={formData.course_duration}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="eligibility" className="mb-2 font-medium text-sm">Eligibility</label>
                <input
                  type="text"
                  id="eligibility"
                  name="eligibility"
                  value={formData.eligibility}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="course_details" className="mb-2 font-medium text-sm">Course Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.course_details}
                  onChange={(event, editor) => setFormData({ ...formData, course_details: editor.getData() })}
                />
              </div>

              <div className="flex flex-col md:col-span-2">
                <label htmlFor="exams_accepted" className="mb-2 font-medium text-sm">Exams Accepted</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    id="new_exam"
                    value={newExam}
                    onChange={(e) => setNewExam(e.target.value)}
                    className="border w-full border-gray-300 p-2 rounded mr-2"
                    placeholder="Enter exam"
                  />
                  <button onClick={handleExamAdd} className="p-3 bg-blue-600 text-white rounded-md">
                    <LuPlus />
                  </button>
                </div>
                <div className="mt-2">
                  {formData.course_exams_accepted.map((exam, index) => (
                    <div key={index} className="inline-flex items-center mr-2 mb-2 bg-gray-200 p-2 rounded">
                      {exam}
                      <button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => setFormData(prev => ({
                          ...prev,
                          course_exams_accepted: prev.course_exams_accepted.filter(e => e !== exam),
                        }))}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
              {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Update Course'
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditCourses;
