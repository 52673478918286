import React from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as Exit } from "../../src/assets/header/Exit.svg";
import PopupContent from './PopupContent';

ReactModal.setAppElement('#root');

const PopupModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <>
     <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      },
      content: {
        margin: 'auto',
        width: 'fit-content',
        height: 'fit-content',
        // borderRadius: '8px',
        textAlign: 'center',
        padding: 0, // Remove padding from modal content,
        overflow:'hidden',
        zIndex: 10, // Ensure the modal appears on top of other content
    
      },
    }}
  >
     <button
          onClick={onClose}
          className="absolute top-1 right-1 md:top-4 md:right-4 "
        >
      <Exit className="h-[20px] w-[20px]"/>
    </button>
    <PopupContent  onSubmit={onSubmit}/>
  </ReactModal>
  </>
  )
}

export default PopupModal