import React, { useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import { useScrollToTop } from "./hooks/useScrollToTop";
import Layout from "./Layout";
import PopupModal from "./pages/PopupModal";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import SocialMediaIcons from "./components/SocialMediaIcons";

function App() {
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);
  const location = useLocation(); // Get the current route location

  useEffect(() => {
    // Check if current route is /dashboard or /admin, if so, skip showing the popup
    if (location.pathname === "/dashboard" || location.pathname === "/admin") {
      return; // Don't show the popup on these pages
    }
    const popupSubmitted = localStorage.getItem("popupSubmitted");
    const popupClosed = sessionStorage.getItem("popupClosed");

    // Show the popup if it has not been submitted or closed in this session
    if (!popupSubmitted && !popupClosed) {
      const timer = setTimeout(() => {
        setIsPopupModalOpen(true);
      }, 3000);

      // Cleanup timeout on component unmount
      return () => clearTimeout(timer);
    }
  }, []);

  const handlePopupClose = () => {
    sessionStorage.setItem("popupClosed", "true"); // Mark as closed for this session
    setIsPopupModalOpen(false); // Close the modal
  };
  const handleFormSubmit = () => {
    localStorage.setItem("popupSubmitted", "true"); // Mark as submitted (persists across sessions)
    setTimeout(() => {
      setIsPopupModalOpen(false); // Close the modal after a delay
    }, 2000); // 2-second delay
  };

  useScrollToTop();

  useEffect(() => {});

  return (
    <>
      <Layout>
        <ScrollToTop />
        <SocialMediaIcons />
        <AppRoutes />
      </Layout>
      <PopupModal
        isOpen={isPopupModalOpen}
        onClose={handlePopupClose}
        onSubmit={handleFormSubmit}
      />
    </>
  );
}

export default App;
