import React, { useState, useEffect } from "react";
import { HiPencil, HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../../../dashboard/sidebarpages/headerSection/HeaderSection";
import axios from "axios";
import { Base_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllAdminStreamForm = () => {
  const [streamForms, setStreamForms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStreamForms = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/streams_details`);

        setStreamForms(response.data);
      } catch (error) {
        console.error(
          "Error fetching stream forms:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchStreamForms();
  }, []);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = streamForms.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(streamForms.length / entriesPerPage);

  const filteredEntries = currentEntries.filter((form) =>
    form.stream_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditClick = (streamForm) => {
    navigate(`/admin/career/EditAdminStreamForm/${streamForm.id}`, {
      state: {
        id: streamForm.id,
        streams_id: streamForm.streams_id,
        about: streamForm.about,
        eligibility: streamForm.eligibility,
        job_roles: streamForm.job_roles,
        work_description: streamForm.work_description,
        salary: streamForm.salary,
        prep_books: streamForm.prep_books,
      },
    });
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${Base_URL}/streams_details/${id}`);
      setStreamForms((prevStreamForms) =>
        prevStreamForms.filter((form) => form.id !== id)
      );
      toast.success("Stream form deleted successfully.");
    } catch (error) {
      console.error(
        "Error deleting stream form:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to delete stream form.");
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className='p-6 bg-gray-100 min-h-screen'>
      <HeaderSection
        title='All Stream Forms'
        breadcrumb={["Stream", "All Stream Forms"]}
      />

      <div className='bg-white p-6 rounded-lg shadow'>
        <div className='flex items-center justify-between mb-4 pb-2 border-b border-gray-200'>
          <div
            className='font-poppins'
            style={{
              color: "rgb(49, 42, 42)",
              fontSize: "18.5px",
              fontWeight: 600,
            }}
          >
            All Stream Forms
          </div>
          <button
            className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
            onClick={() => navigate("/admin/career/adminStream")}
          >
            + Add New
          </button>
        </div>

        <div className='flex items-center justify-between mb-4 pb-2'>
          <div className='flex items-center space-x-4'>
            <span className='text-sm text-gray-600 mr-2'>Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className='border border-gray-300 rounded'
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>

          <div className='flex items-center space-x-4'>
            <span className='text-sm text-gray-600'>Search:</span>
            <input
              type='text'
              className='px-3 py-1 border border-gray-300 rounded'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: "30px",
                width: "200px",
                borderRadius: "5px",
                borderColor: "#d0d0d0",
                padding: "0 10px",
              }}
            />
          </div>
        </div>

        <div className='overflow-x-auto'>
          <table className='min-w-full bg-white'>
            <thead>
              <tr className='bg-white'>
                {[
                  "ID",
                  "Stream",
                  "About",
                  "Eligibility",
                  "Job Roles",
                  "Work Description",
                  "Salary",
                  "Prep Books",
                  "Actions",
                ].map((heading) => (
                  <th
                    key={heading}
                    className='text-left border-b'
                    style={{
                      color: "rgb(49, 42, 42)",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {streamForms.length > 0 ? (
                streamForms.map((form) => (
                  <tr key={form.id} className='hover:bg-white hover:shadow-md'>
                    <td>{form.id}</td>
                    <td>{form.stream_name}</td>
                    <td>{form.about}</td>
                    <td>{form.eligibility}</td>
                    <td>{form.job_roles}</td>
                    <td>{form.work_description}</td>
                    <td>{form.salary}</td>
                    <td>{form.prep_books}</td>
                    <td>
                      <div className='flex space-x-2'>
                        <button
                          onClick={() => handleEditClick(form)}
                          className='p-2 rounded bg-cmsgreen text-white hover:bg-hovergreen'
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(form.id)}
                          className='p-1 rounded bg-red-500 text-white hover:bg-red-700'
                        >
                          <HiTrash size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='9' className='text-center py-4'>
                    No stream forms found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='flex justify-between items-center mt-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={handlePrevious}
              className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='text-sm text-gray-600'>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]'
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AllAdminStreamForm;
