import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { FaSort } from "react-icons/fa";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import HeaderTitle from "../../dashboard/HeaderTitle";


const AllAdmissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [admissions, setAdmissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // Sorting state
  const token = useSelector((state) => state.auth.token);


  useEffect(() => {
    const fetchAdmissions = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${Base_URL}/get-with-joins/admissions`,
          {
            joins: [
              {
                joinTable: "universities",
                joinCondition:
                  "universities.university_id=admissions.university_id",
              },
            ],
          }
        );
        setAdmissions(response.data);
      } catch (err) {
        toast.error("Failed to fetch admissions");
      } finally {
        setLoading(false);
      }
    };
    fetchAdmissions();
  }, []);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color="lightgrey" />;
  };

  const sortedAdmissions = useMemo(() => {
    let sortableAdmissions = [...admissions];
    if (sortConfig.key) {
      sortableAdmissions.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAdmissions;
  }, [admissions, sortConfig]);

  const filteredAdmissions = sortedAdmissions.filter((admission) =>
    admission.university_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalEntries = filteredAdmissions.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const displayedAdmissions = filteredAdmissions.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () =>
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleNext = () =>
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this admission?"
    );
    if (!confirmDelete) return;

    try {
      await axios.put(`${Base_URL}/update/delete/admissions/${id}`,{},{
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`  // Add token authorization
      },
      // withCredentials: true  // Enable withCredentials for the request
      });
      toast.success("Admission deleted successfully");
      // Update the state to remove the deleted admission
      setAdmissions((prev) => prev.filter((admission) => admission.id !== id));
    } catch (err) {
      toast.error("Failed to delete admission");
    }
  };

  return (
    <div className='bg-gray-100 p-6 min-h-screen overflow-auto'>
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className='bg-white p-4 rounded-md shadow-md'>
        <div className='flex justify-between items-center mb-4 pb-2 border-b border-gray-200'>
          <h2 className='text-xl font-semibold mb-4'>{subTitle}</h2>
          <button
            className='px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            onClick={() =>
              navigate("/admin/admissions/add", {
                state: { mainTitle, subTitle: "Add Admissions" },
              })
            }
          >
            + Add New
          </button>
        </div>

        <div className='flex justify-end mb-4'>
          <input
            type='text'
            className='px-3 py-1 border border-gray-300 rounded'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Search by University Name'
          />
        </div>

        <div className='grid grid-cols-6 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md'>
          <div onClick={() => handleSort("id")}>
            <div className='flex items-center space-x-1'>
              <span>Admission Id</span>
              {getSortIcon("id")}
            </div>
          </div>
          <div onClick={() => handleSort("university_name")}>
            <div className='flex items-center space-x-1'>
              <span>University Name</span>
              {getSortIcon("university_name")}
            </div>
          </div>
          <div onClick={() => handleSort('created_on')}>
          <div className='flex items-center space-x-1'>
              <span> Created On</span>
              {getSortIcon('created_on')}
            </div>
              
            </div>
            <div onClick={() => handleSort('created_by')} >
            <div className='flex items-center space-x-1'>
              <span> Created By</span>
              {getSortIcon('created_by')}
            </div>
            </div>
          <div onClick={() => handleSort("admission_desc")}>
            <div className='flex items-center space-x-1'>
              <span>Admission Details</span>
              {getSortIcon("admission_desc")}
            </div>
          </div>
          <div>Action</div>
        </div>

        {displayedAdmissions.length > 0 ? (
          displayedAdmissions.map((admission) => (
            <div
              key={admission.id}
              className='grid grid-cols-6 gap-2 text-tableTextColor text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100'
            >
              <div>{admission.id}</div>
              <div className=''>{admission.university_name}</div>
              <div>{admission.created_on ? admission.created_on.split('T')[0] : 'N/A'}</div>
              <div>{admission.created_by}</div>
              <div className='truncate '>
                {admission.admission_desc}
              </div>
              <div className='flex space-x-2'>
                <button
                  onClick={() =>
                    navigate(`/admin/admissions/edit/${admission.id}`, {
                      state: admission,
                    })
                  }
                  className='bg-cmsgreen hover:bg-hovergreen text-white p-2 rounded'
                >
                  <HiPencilAlt />
                </button>
                <button className='bg-red-500 hover:bg-red-700 text-white p-2 rounded'
                  onClick={() => handleDelete(admission.id)}>
                  <HiTrash />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className='text-center py-4'>No admissions found</div>
        )}

        {/* Pagination */}
        <div className='flex justify-between items-center mt-4'>
          <div className='flex items-center'>
            <span className='text-sm text-gray-600 mr-2'>Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => {
                setEntriesPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              className='border border-gray-300 rounded'
            >
              {[5, 10, 20].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className='flex items-center space-x-2'>
            <button
              onClick={handlePrevious}
              className={`px-4 py-2 rounded text-sm ${
                currentPage === 1
                  ? "bg-hovergreen text-white "
                  : "bg-cmsgreen text-white"
              }`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className='text-sm text-gray-600'>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className={`px-4 py-2 rounded text-sm ${
                currentPage === totalPages
                  ? "bg-hovergreen text-white"
                  : "text-white bg-cmsgreen"
              }`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAdmissions;
