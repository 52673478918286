import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from 'react-redux';

const EditDuration = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const duration = location.state; 
    const token = useSelector((state) => state.auth.token); // Get token from Redux state

    const [formData, setFormData] = useState({
      duration_title: duration?.duration_title || ''
    });
    const [loading, setLoading] = useState(false); // Track loading state
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Set loading to true when submitting
  
      try {
        await axios.put(`${Base_URL}/update/duration_master/${duration.id}`, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Duration updated successfully!');
        setTimeout(() => {
          navigate('/admin/courseMasterDetails/allDuration');
        }, 2000);
      } catch (error) {
        toast.error('Failed to update duration.');
        console.error('Failed to update duration:', error.response?.data || error.message);
        setLoading(false);
      } finally {
        setLoading(false); // Reset loading state after submission
      }
    };
  
    return (
      <div>
        <ToastContainer />
        <div className="bg-gray-100 p-6 h-screen">
          <HeaderTitle mainTitle="Duration" subTitle="Edit Duration" />
          <div className="bg-white p-4 rounded-md">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label htmlFor="duration" className="mb-2 font-medium text-sm">Duration(In years)</label>
                  <input
                    type="text"
                    id="duration"
                    name="duration_title"
                    value={formData.duration_title}
                    onChange={handleInputChange}
                    className="border border-gray-300 p-2 rounded"
                    required
                  />
                </div>
              </div>

              <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
                {loading ? ( // Show spinner when loading
                  <div className="flex justify-center items-center">
                    <svg
                      className="animate-spin h-5 w-5 text-white mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                    Submitting...
                  </div>
                ) : (
                  'Update Duration'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
}

export default EditDuration;
