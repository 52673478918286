import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import HeaderTitle from "../../dashboard/HeaderTitle";

const EditGeneralFaqs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const faq = location.state; // Get FAQ data passed from AllAdminFaq

  const [formData, setFormData] = useState({
    qna: faq?.faq.qna || [], // Initialize with the qna array or an empty array
  });
  const [loading, setLoading] = useState(false); // Track loading state
  const { registration_id, token, name } = useSelector((state) => state.auth);

  const handleAddQna = () => {
    setFormData((prevData) => ({
      ...prevData,
      qna: [...prevData.qna, { question: "", answer: "" }],
    }));
  };

  const handleRemoveQna = (index) => {
    const updatedQna = formData.qna.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, qna: updatedQna }));
  };

  const handleQnaChange = (index, field, value) => {
    const updatedQna = [...formData.qna];
    updatedQna[index][field] = value;
    setFormData((prevData) => ({ ...prevData, qna: updatedQna }));
  };

  const handleRemoveFaq = (index) => {
    const updatedFaqs = formData.faqs.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, faqs: updatedFaqs }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting
    try {
      const postData = { ...formData, qna: JSON.stringify(formData.qna) }; // Serialize qna field};
      await axios.put(`${Base_URL}/update/admin_faq/${faq.faq.id}`, postData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        withCredentials: true, // This allows cookies to be sent with the request
      });
      toast.success("FAQ updated successfully!");
      setTimeout(() => {
        navigate("/admin/websiteFaqs/all"); // Navigate back to the list after success
      }, 2000);
    } catch (error) {
      toast.error("Failed to update FAQ.");
      console.error(
        "Failed to update FAQ:",
        error.response?.data || error.message
      );
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 h-screen'>
        <HeaderTitle mainTitle='FAQs' subTitle='Edit FAQ' />
        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col md:col-span-2'>
                <label htmlFor='keywords' className='mb-2 font-medium text-sm'>
                  Question and Answers
                </label>
                {formData.qna.map((qna, index) => (
                  <div key={index} className='flex items-center mb-2'>
                    <input
                      type='text'
                      placeholder='Enter Question'
                      value={qna.question}
                      onChange={(e) =>
                        handleQnaChange(index, "question", e.target.value)
                      }
                      className='border border-gray-300 p-2 rounded w-full mr-2'
                      required
                    />
                    <input
                      type='text'
                      placeholder='Enter Answer'
                      value={qna.answer}
                      onChange={(e) =>
                        handleQnaChange(index, "answer", e.target.value)
                      }
                      className='border border-gray-300 p-2 rounded w-full mr-2'
                      required
                    />
                    <button
                      type='button'
                      onClick={() => handleRemoveQna(index)}
                      className='text-red-600 hover:text-red-800 ml-2'
                    >
                      ×
                    </button>
                  </div>
                ))}
                <button
                  type='button'
                  onClick={handleAddQna}
                  className='p-2 mt-3 bg-cmsgreen text-white rounded text-sm flex items-center'
                >
                  {/* <LuPlus className="mr-2" /> */}
                  Add Question
                </button>
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-cmsgreen text-white rounded-md'
            >
              {loading ? ( // Show spinner when loading
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                "Update FAQ"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditGeneralFaqs;
