import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import HeaderTitle from "../../dashboard/HeaderTitle";

const AddExamSection = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    exam_master_id: null,
    // created_by: 1,
    exam_section_name: "",
  });

  const [exams, setExams] = useState([]);
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    // Fetch stream data from the API

    const fetchExams = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/exam_master`);

        const examOptions = response.data.map((exam) => ({
          value: exam.id,
          label: exam.exam_master_name,
        }));
        setExams(examOptions);
      } catch (error) {
        console.error("Failed to fetch states:", error);
        toast.error("Failed to load state options.");
      }
    };

    fetchExams();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleExamChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      exam_master_id: selectedOption.value, // Set stream_id (the first value of the array)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newSection = { ...formData };
      await axios.post(`${Base_URL}/create/exam_section_master`, newSection, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Exam Section added successfully!");
    } catch (error) {
      toast.error("Failed to add exam section");
      console.error(
        "Failed to add exam section",
        error.response?.data || error.message
      );
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 h-screen'>
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label htmlFor='name' className='mb-2 font-medium text-sm'>
                  Select Exam
                </label>
                <Select
                  options={exams}
                  onChange={handleExamChange}
                  placeholder={"Select Exam"} // Display placeholder text
                  className='basic-single'
                />
              </div>
              <div className='flex flex-col'>
                <label
                  htmlFor='exam_master_name'
                  className='mb-2 font-medium text-sm'
                >
                  Create Exam Section
                </label>
                <input
                  type='text'
                  id='exam_section_name'
                  name='exam_section_name'
                  value={formData.exam_section_name}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-blue-600 text-white rounded-md'
            >
              Add Exam Section
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddExamSection;
