import { API_ENDPOINT } from "../../utils/constant";
import apiClient from "../apiClient";

export const fetchNewsAnnouncements = async () => {
  try {
    const response = await apiClient.get(API_ENDPOINT.NEWS_ANNOUNCEMENTS);

    if (Array.isArray(response.data)) {
      const sortedNews = response.data.sort((a, b) => {
        const dateA = new Date(a.created_at || a.announcement_date);
        const dateB = new Date(b.created_at || b.announcement_date);
        return dateB - dateA;
      });

      return sortedNews;
    }
  } catch (error) {
    console.error("Error fetching news announcements:", error);
    throw error;
  }
};

export const fetchNewsByTitle = async (title) => {
  try {
    const options = {
      table: "news_announcements",
      columns: ["news_announcements.*", "registration.name as user_name"],
      joins: [
        {
          table: "registration",
          on: "registration.registration_id = news_announcements.created_by",
          type: "INNER",
        },
      ],
      where: {
        "news_announcements.title": title,
        "news_announcements.status": "1",
        "registration.status": "1",
      },
    };

    const url = `${API_ENDPOINT.JOIN_WHERE}?options=${encodeURIComponent(
      JSON.stringify(options)
    )}`;

    const response = await apiClient.get(url);

    if (Array.isArray(response.data)) {
      const sortedNews = response.data.sort((a, b) => {
        const dateA = new Date(a.created_at || a.announcement_date);
        const dateB = new Date(b.created_at || b.announcement_date);
        return dateB - dateA; // Sort by date descending
      });

      return sortedNews;
    }

    return [];
  } catch (error) {
    console.error("Error fetching news by title:", error);
    throw error;
  }
};

export const fetchNewsAdminByTitle = async (title) => {
  try {
    const options = {
      table: "news_announcements_admin",
      columns: ["news_announcements_admin.*", "registration.name as user_name"],
      joins: [
        {
          table: "universities",
          on: "news_announcements_admin.university_id = universities.university_id",
          type: "INNER",
        },
        {
          table: "registration",
          on: "registration.registration_id = news_announcements_admin.created_by",
          type: "INNER",
        },
      ],
      where: {
        "news_announcements_admin.title": title,
        "news_announcements_admin.status": "1",
        "registration.status": "1",
        "universities.status": "1",
      },
    };

    const url = `${API_ENDPOINT.JOIN_WHERE}?options=${encodeURIComponent(
      JSON.stringify(options)
    )}`;

    const response = await apiClient.get(url);

    if (Array.isArray(response.data)) {
      const sortedNews = response.data.sort((a, b) => {
        const dateA = new Date(a.created_at || a.announcement_date);
        const dateB = new Date(b.created_at || b.announcement_date);
        return dateB - dateA; // Sort by date descending
      });

      return sortedNews;
    }

    return [];
  } catch (error) {
    console.error("Error fetching admin news by title:", error);
    throw error;
  }
};
export const fetchNewsByCollege = async (collegeName) => {
  try {
    const options = {
      table: "news_announcements_admin",
      columns: ["news_announcements_admin.*", "registration.name as user_name"],
      joins: [
        {
          table: "universities",
          on: "news_announcements_admin.university_id = universities.university_id",
          type: "INNER",
        },
        {
          table: "registration",
          on: "registration.registration_id = news_announcements_admin.created_by",
          type: "INNER",
        },
      ],
      where: {
        "universities.university_name": collegeName,
        "news_announcements_admin.status": "1",
        "registration.status": "1",
        "universities.status": "1",
      },
    };

    const url = `${API_ENDPOINT.JOIN_WHERE}?options=${encodeURIComponent(
      JSON.stringify(options)
    )}`;

    const response = await apiClient.get(url);

    if (Array.isArray(response.data)) {
      const sortedNews = response.data.sort((a, b) => {
        const dateA = new Date(a.created_at || a.announcement_date);
        const dateB = new Date(b.created_at || b.announcement_date);
        return dateB - dateA; // Sort by date descending
      });

      return sortedNews;
    }

    return [];
  } catch (error) {
    console.error(`Error fetching news for college '${collegeName}':`, error);
    throw error;
  }
};
