
import React, { useEffect, useState } from "react";
import { Img_URL } from "../../../apiConfig";
import college_logo from "../../../assets/college_logo.png";
import Arrow from "../../../assets/landing/Arrow.png";
import Graphic from "../../../assets/landing/Graphic.png";
import { getNewsAnnouncements } from "../../../redux/slice/NewsEventSlice";
import EventCard from "../../College/NewsEvents/EventCard/EventCard";
import NewsCard from "../../College/NewsEvents/NewsCard/NewsCard";
import { useDispatch, useSelector } from "react-redux";
import "./landing-news-events.css";

const LandingNewsEvents = () => {
  const dispatch = useDispatch();
  const [upcomingEvents] = useState([
    {
      event_name: "Webinar",
      event_details:
        "Join us for a live webinar on Google Meet to explore exciting opportunities and insights!",
      event_location: "Online",
      event_date: "2024-12-20",
    },
    {
      event_name: "Live Session",
      event_details:
        "Meet potential employers and explore career opportunities.",
      event_location: "Jammu",
      event_date: "2024-12-25",
    },
    {
      event_name: "Live Session",
      event_details:
        "Learn about the latest advancements in Artificial Intelligence.",
      event_location: "KC Emporium Jammu",
      event_date: "2025-01-10",
    },
  ]);
  const { news, loading, error } = useSelector((state) => state.newsEvents);

  const [recentNews, setRecentNews] = useState([]);

  useEffect(() => {
    dispatch(getNewsAnnouncements());
  }, [dispatch]);

  const dummyFeaturedNews = {
    title: "Innovations for Sustainable Future",
    content:
      "First International Conference on Current Environmental Technologies for Sustainable Development.We're hosting the 1st International Conference on Current Environmental Technologies for Sustainable Development (ICCETSD 2024) on December 9th & 10th, 2024.",
    picture: Graphic,
    announcement_date: "2024-04-12",
  };

  return (
    <div className='py-1 px-4 md:px-6 lg:px-8 bg-white'>
      <div className='text-black text-base font-medium flex flex-col gap-5'>
        <p className='text-center md:text-lg text-[16px] text-[#808080] font-[500] mb-2'>
          NEWS AND EVENTS
        </p>
        <p className='md:text-xl text-[18px] font-[500] text-center mb-8'>
          Hot Off the Press: Dive into the Latest News and Exciting Campus
          Events!
        </p>
      </div>

      {/* Featured News */}
      <div className='py-10'>
        <div className='flex flex-col md:flex-row items-center relative'>
          <div className='bg-gray-500 flex-shrink-0 h-72 w-full max-w-xs rounded-xl overflow-hidden'>
            <img
              src={dummyFeaturedNews.picture || college_logo}
              alt='news-image'
              className='w-full h-full object-cover'
            />
          </div>
          <div
            className='text-card-container bg-white rounded-xl p-5 border-2 relative -translate-y-10 md:-translate-x-10 mt-auto'
            style={{ zIndex: 1 }}
          >
            <img
              src={Arrow}
              alt='Arrow Icon'
              className='news-arrow-icon absolute right-0 top-0 mt-2 mr-2'
            />
            <div className='badge-container flex mb-2 justify-center md:justify-start'>
              <p className='text-xs text-white bg-[#3ACA2E] py-1 px-3 rounded-xl'>
                FEATURED NEWS
              </p>
            </div>
            <div>
              <p className='text-black text-lg font-semibold mt-5'>
                {dummyFeaturedNews.title}
              </p>
              <p className='text-xs font-medium mt-2 mb-3'>
                {new Date(
                  dummyFeaturedNews.announcement_date
                ).toLocaleDateString()}
              </p>
              <p className='text-base mt-2 news_display_content'>
                {dummyFeaturedNews.content}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='py-10 flex flex-col lg:flex-row gap-8 max-w-screen-xl mx-auto'>
        {/* Recent News Section */}
        <div className='flex-item mb-8 lg:mb-0'>
          <p className='text-black text-base font-bold mb-6 text-center lg:text-left leading-[26px] lg:leading-normal'>
            Recent News
          </p>
          <div className='flex flex-col gap-12'>
            {loading ? (
              <p>Loading...</p>
            ) : news?.length > 0 ? (
              news
                .slice(0, 2)
                ?.map((item, index) => (
                  <NewsCard
                    key={index}
                    id={item.id}
                    title={item.title}
                    desc={item.content}
                    image={`${Img_URL}/${item.picture}` || college_logo}
                    date={new Date(item.announcement_date).toLocaleDateString()}
                    className='w-full h-auto'
                  />
                ))
            ) : error ? (
              <p>{error}</p>
            ) : (
              <p>No recent news available.</p>
            )}
          </div>
        </div>

        {/* Upcoming Events Section */}
        <div className='flex-item mb-8 lg:mb-0'>
          <p className='text-black text-base font-bold mb-6 text-center lg:text-left leading-[26px] lg:leading-normal'>
            Upcoming Events
          </p>
          <div className='bg-white flex flex-col w-full py-5 rounded-[20px] border-2 border-gray-200'>
            {upcomingEvents.map((event, index) => (
              <div key={index}>
                <EventCard
                  title={event.event_name}
                  subtitle={event.event_details}
                  location={event.event_location}
                  date={new Date(event.event_date).getDate()}
                  month={new Date(event.event_date)
                    .toLocaleString("default", { month: "short" })
                    .toUpperCase()}
                />
                {index < upcomingEvents.length - 1 && (
                  <hr className='border-gray-300 my-2' />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingNewsEvents;
