import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Base_URL } from '../apiConfig';
import CareerSearch from './CareerSearch';

const CareersGrid = () => {
  const [streams, setStreams] = useState([]); // Holds all career streams
  const [filteredStreams, setFilteredStreams] = useState([]); // Holds filtered streams
  const navigate = useNavigate();

  // Fetch all streams from backend
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await fetch(`${Base_URL}/get/streams`);
        const data = await response.json();
        
        const validData = Array.isArray(data) ? data : [];
        setStreams(validData);
        setFilteredStreams(validData); // Set initial streams
      } catch (error) {
        console.error("Error fetching streams:", error);
      }
    };

    fetchStreams();
  }, []);

  // Navigate to specific career tab
  const handleStreamSelect = (stream) => {
    navigate(`/careerProgram/${encodeURIComponent(stream.stream_name)}`);
  };
  

  // Handle search and dynamically filter the streams
  const handleSearch = (query) => {
    const filteredResults = streams.filter((stream) =>
      stream.stream_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredStreams(filteredResults);
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-[150px] bg-[#F2FCEB]">
      {/* Pass search query changes to parent */}
      <CareerSearch onSearchChange={handleSearch} />

      <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-8">
        {Array.isArray(filteredStreams) && filteredStreams.length > 0 ? (
          filteredStreams.map((stream) => (
            <div
              key={stream.id}
              className="border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col"
            >
              <div className="h-[190px] w-full overflow-hidden rounded-t-[10px] bg-gray-200">
                {stream.picture ? (
                  <img
                    src={`${Base_URL}/file${stream.picture}`}
                    alt={stream.stream_name}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'path/to/fallback/image.jpg';
                    }}
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center text-gray-400">
                    No Image Available
                  </div>
                )}
              </div>
              
              <div className="absolute bottom-0 w-full">
                <button
                  onClick={() => handleStreamSelect(stream)}
                  className="w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2"
                >
                  {stream.stream_name}
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-600">
            No Streams Found
          </div>
        )}
      </div>
    </div>
  );
};

export default CareersGrid;
