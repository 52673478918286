import React, { useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "../../assets/header/Back.svg";
import Digital from "../../assets/header/Digital.gif";
import Gift from "../../assets/header/Gift.gif";
import { ReactComponent as Intelligence } from "../../assets/header/Intelligence.svg";
import { ReactComponent as Partner } from "../../assets/header/Partner.svg";
import MobileReferEarn from "./MobileReferEarn";

const MobileMenu = ({ dummyPrograms, setMobileMenuOpen,openReferEarnModal,setReferEarnModalOpen }) => {
    const [isMobileReferEarn,setMobileReferEarn]=useState(false)
    const openReferEarn=()=>{
      setMobileReferEarn(true)
        
    }
    const closeReferEarn=()=>{
        setMobileReferEarn(false)
    }

  const navigate = useNavigate();

  const handleNavigation = (program) => {
    const programSlug = program.toLowerCase().replace(/\s+/g, "-"); // Convert program name to slug
    navigate(`/filter/${programSlug}-colleges-in-india`); // Pass program name as state
  };

  return (
    <div className='fixed inset-0  bg-white overflow-y-auto lg:hidden ' style={{zIndex:60}}>
      <div className='p-4 flex justify-between items-center border-b'>
        <button
          className='text-lg font-medium flex items-center space-x-2'
          onClick={() => setMobileMenuOpen(false)}
        >
          <Back className='text-2xl' />
          <span>Menu</span>
        </button>
        <div className='flex items-center' style={{ marginLeft: "2px" }}   onClick={openReferEarn}>
          <div   className='text-xs flex items-center justify-center text-black'>
            <img
              src={Gift}
              alt='Gift'
              className='h-[50px] relative left-2 ml-[0px] w-[50px]'
            />
          </div>

          <div className='cursor-pointer'>
            <div className='text-black cursor-pointer'>Refer & Earn</div>
            <div className='bg-primary text-xs text-white'>Get Upto ₹5000*</div>
          </div>
        </div>
      </div>

      <div className='p-4 bg-[#DFF6E1] flex items-center justify-between'>
        <div className='bg-primary p-1 rounded-md relative  shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white'>
          <Intelligence className='h-[16px] w-[16px]' />
          <div className='text-xs'>AI Powered</div>
        </div>

        <div className='border-primary bg-white flex items-center justify-center border-[1px] rounded-md text-primaryText shadow-md text-xs px-6 py-1'>
          Search Goal & City
          <div>
            <FaCaretDown className='h-[14px] w-[14px] ml-1' />
          </div>
        </div>
      </div>

      <div className='p-4'>
        <p className='text-lg font-semibold mb-4'>Browse Programs</p>
        <ul className='space-y-4'>
          {dummyPrograms.map((program) => (
            <li
              key={program.id}
              onClick={() => handleNavigation(program.program_master_name)}
              className='flex justify-between items-center border-b pb-2 cursor-pointer'
            >
              <span className='text-sm'>{program.program_master_name}</span>
              <span className='text-primaryText font-medium text-lg'>+</span>
            </li>
          ))}
        </ul>
      </div>

      <div className='flex flex-col space-y-4 p-4 mt-2 text-center bg-white'>
        <div className='flex justify-center '>
          <img
            src={Digital} // Path to the digital GIF
            alt='Digital Marketing GIF'
            className='w-[45px] h-[45px]'
          />
        </div>
        <div>
          <p className='text-sm text-primary font-medium'>
            Become our Marketing Partner
          </p>
        </div>
        <div>
          <p className='text-xs text-gray-500'>
            Lorem ipsum odor amet, consectetuer adipiscing elit.
          </p>
        </div>

        <div className='w-full flex items-center justify-center'>
          <button className=' flex justify-center space-x-2 items-center mt-4 px-12 py-2 bg-primary text-white rounded-3xl'>
            <Partner />
            <div className='text-md'>Join Us</div>
          </button>
        </div>
      </div>
      {
        isMobileReferEarn &&(
          <MobileReferEarn 
             onRequestClose={closeReferEarn}
          />
        )
      }

    </div>
  );
};

export default MobileMenu;
