import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';

const EditPlacement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id || 1; // Use login_id from Redux or default to 1
  
  const [formData, setFormData] = useState({
    university_id: login_id,
    student_name: '',
    course: '',
    company_name: '',
    recruitment_date: '',
    average_salary_package: '',
  });
  
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('No file chosen');
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlacementData = async () => {
      try {
        const response = await axios.get(`${Base_URL}/placement/${id}`);
        const placement = response.data;
        const formattedDate = new Date(placement.recruitment_date).toISOString().split('T')[0];
        
        setFormData({
          university_id: login_id,
          student_name: placement.student_name,
          course: placement.course,
          company_name: placement.company_name,
          recruitment_date: formattedDate,
          average_salary_package: placement.average_salary_package,
        });
      } catch (error) {
        console.error('Error fetching placement data:', error);
        setError('Failed to fetch placement data');
      }
    };
    fetchPlacementData();
  }, [id, login_id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    } else {
      setImageName('No file chosen');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const data = new FormData();
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      if (image) {
        data.append('picture', image);
      }

      await axios.put(`${Base_URL}/placement/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Placement data updated successfully!');
      setTimeout(() => {
        setLoading(false); // Stop loading
        
        navigate('/dashboard/placements/all'); // Redirect after success
      }, 2000); // Add delay for better UX
    } catch (error) {
      console.error('Failed to update placement data:', error.response?.data || error.message);
      toast.error('Failed to update placement data. Please try again.');
      setLoading(false); // Stop loading on error
    }
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md h-screen">
      <HeaderSection
        title="Edit Placement"
        breadcrumb={['Placement', 'Edit Placements']}
      />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[#312a2a] text-[17.25px] font-[500] font-poppins">Basic Info</h2>
        </div>

        {error && <div className="text-red-600 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Student Name */}
            <div className="flex flex-col">
              <label htmlFor="student_name" className="text-[#312a2a] font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Student Name
              </label>
              <input
                type="text"
                id="student_name"
                name="student_name"
                value={formData.student_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            {/* Course */}
            <div className="flex flex-col">
              <label htmlFor="course" className="text-[#312a2a] font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Course
              </label>
              <input
                type="text"
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            {/* Company Name */}
            <div className="flex flex-col">
              <label htmlFor="company_name" className="text-[#312a2a] font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Company Name
              </label>
              <input
                type="text"
                id="company_name"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            {/* Recruitment Date */}
            <div className="flex flex-col">
              <label htmlFor="recruitment_date" className="text-[#312a2a] font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Recruitment Date
              </label>
              <input
                type="date"
                id="recruitment_date"
                name="recruitment_date"
                value={formData.recruitment_date}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            {/* Average Salary Package */}
            <div className="flex flex-col">
              <label htmlFor="average_salary_package" className="text-[#312a2a] font-poppins text-[0.875rem] font-[500] mb-[7px]">
                Average Salary Package
              </label>
              <input
                type="number"
                id="average_salary_package"
                name="average_salary_package"
                value={formData.average_salary_package}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>

            {/* Upload Profile Picture */}
            <div className="flex flex-col">
              <label htmlFor="profile_picture" className="text-[#312a2a] font-poppins text-[0.875rem] font-[500] mb-[6px]">
                Upload Profile Picture
              </label>
              <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                <label className="flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer font-poppins">
                  <span className="text-[12.25px]">Choose File</span>
                  <input
                    type="file"
                    id="profile_picture"
                    name="profile_picture"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </label>
                <div className="flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center font-poppins text-[12.25px]">
                  {imageName || 'No file chosen'}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md font-poppins flex items-center justify-center"
            disabled={loading} // Disable while loading
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
               Updating...
              </div>
            ) : (
              'Update'
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditPlacement;