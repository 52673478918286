import React, { useState } from "react";
import axios from "axios";
import { Base_URL } from "../../../apiConfig";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const EditStreams = () => {
  const navigate = useNavigate();
  const { streamId } = useParams();
  const location = useLocation();
  const { item, mainTitle, subTitle } = location.state || {};
  const [formData, setFormData] = useState({
    stream_name: item?.stream_name || "",
  });
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.auth); // Get token from Redux store

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const updatedStream = { ...formData };
      await axios.put(`${Base_URL}/update/streams/${streamId}`, updatedStream, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include Bearer token for authorization
        },
      });
      toast.success("Stream updated successfully!");
      setTimeout(() => {
        navigate('/admin/universityMaster/allMaster', { state: { selectedType: "streams" } });
      }, 2000); // 2-second delay
    } catch (error) {
      toast.error("Failed to update stream.");
      console.error("Failed to update stream:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 h-screen'>
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label htmlFor='stream_name' className='mb-2 font-medium text-sm'>
                  Stream Name
                </label>
                <input
                  type='text'
                  id='stream_name'
                  name='stream_name'
                  value={formData.stream_name}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            >
              {loading ? (
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Updating...
                </div>
              ) : (
                "Update Stream"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditStreams;
