import React, { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ExamListService from "../../../api/services/ExamListService";
import { fetchFilteredExams } from "../../../api/services/FilterService";
import MasterDataService from "../../../api/services/MasterDataService";
import filter from "../../../assets/landing/filter.png";
import nodata from "../../../assets/landing/no_data.png";
import HatCircleLoader from "../../Loader/HatCircleLOader";

const AllExams = () => {
  const [selectedFilters, setSelectedFilters] = useState([]); // Single array for selected filters
  const [examsData, setExamsData] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [filterData, setFilterData] = useState({
    modes: [],
    levels: [],
    streams: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [searchQueries, setSearchQueries] = useState({
    Modes: "",
    Levels: "",
    Streams: "",
  });
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [activeMobileFilter, setActiveMobileFilter] = useState("Modes");

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 10); // Set sticky if scrolled past 85px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Fetch initial filter data and exams
  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        setIsLoading(true);
        const [modes, levels, streams] = await Promise.all([
          MasterDataService.fetchModes(),
          MasterDataService.fetchLevels(),
          MasterDataService.fetchStreams(),
        ]);

        setFilterData({
          modes: modes.map((item) => ({
            id: item.id,
            name: item.mode_title,
          })),
          levels: levels.map((item) => ({
            id: item.id,
            name: item.level_name,
          })),
          streams: streams.map((item) => ({
            id: item.id,
            name: item.stream_name,
          })),
        });

        await fetchAllExams(); // Fetch all exams initially
      } catch (error) {
        console.error("Error fetching filter data:", error);
      } finally {
        setIsLoading(false); // Stop global loader
      }
    };

    fetchFilterData();
  }, []);

  // Fetch all exams
  const fetchAllExams = async () => {
    try {
      const exams = await ExamListService.fetchExams();
      setExamsData(exams);
    } catch (error) {
      console.error("Error fetching all exams:", error);
    }
  };

  // Fetch filtered exams based on selected filters
  const fetchFilteredExamsData = async () => {
    try {
      const exams = await fetchFilteredExams(selectedFilters); // Pass the selected filters array
      setExamsData(exams);
    } catch (error) {
      console.error("Error fetching filtered exams:", error);
    }
  };

  // Handle checkbox changes for filters
  const handleCheckboxChange = (filterName) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filterName)) {
        // Remove the filter if it exists
        return prevFilters.filter((item) => item !== filterName);
      } else {
        // Add the filter if it doesn't exist
        return [...prevFilters, filterName];
      }
    });
  };

  // Trigger filter API call whenever selectedFilters changes
  useEffect(() => {
    if (selectedFilters.length > 0) {
      fetchFilteredExamsData();
    } else {
      fetchAllExams(); // Fetch all exams if no filters are selected
    }
  }, [selectedFilters]);

  // Clear all filters
  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  const handleMobileFilterClick = () => {
    setIsMobileFilterOpen(true);
  };

  const handleCloseMobileFilter = () => {
    setIsMobileFilterOpen(false);
  };

  const getMobileFilterData = (filterType) => {
    switch (filterType) {
      case "Modes":
        return filterData.modes.filter((item) =>
          item.name.toLowerCase().includes(searchQueries.Modes.toLowerCase())
        );
      case "Levels":
        return filterData.levels.filter((item) =>
          item.name.toLowerCase().includes(searchQueries.Levels.toLowerCase())
        );
      case "Streams":
        return filterData.streams.filter((item) =>
          item.name.toLowerCase().includes(searchQueries.Streams.toLowerCase())
        );
      default:
        return [];
    }
  };

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <HatCircleLoader />
      </div>
    );
  }

  return (
    <div className='container mx-auto px-4 py-8 mt-[7rem] md:mt-20 flex flex-col lg:flex-row gap-6'>
      {/* Left Filter Section */}
      <div className='w-full hidden md:block lg:w-1/4 space-y-6'>
        {Object.keys(filterData).map((filterType) => (
          <div
            key={filterType}
            className='p-3 border rounded-lg shadow-lg bg-[#FCFCFC]'
            style={{ height: "300px", overflow: "hidden" }}
          >
            <div className='flex justify-between items-center mb-4'>
              <h3 className='font-dm-sans text-sm font-bold'>{filterType}</h3>
              <span
                className='font-dm-sans text-[12px] text-[#808080] font-[700] cursor-pointer'
                onClick={handleClearFilters}
              >
                Clear All
              </span>
            </div>
            <input
              type='text'
              className='w-full h-10 p-2 border font-dm-sans text-[14px] rounded mb-2'
              placeholder={`Search ${filterType}`}
              value={searchQueries[filterType]}
              onChange={(e) =>
                setSearchQueries({
                  ...searchQueries,
                  [filterType]: e.target.value,
                })
              }
            />
            <div className='overflow-y-auto' style={{ maxHeight: "200px" }}>
              <ul className='space-y-2'>
                {filterData[filterType]
                  ?.filter((item) =>
                    item.name
                      .toLowerCase()
                      .includes(searchQueries[filterType]?.toLowerCase() || "")
                  )
                  .map((item) => (
                    <li
                      key={item.id}
                      className='flex items-center p-[2px] text-[14px] font-dm-sans font-[700]'
                    >
                      <input
                        type='checkbox'
                        className='mr-2 ml-1'
                        checked={selectedFilters.includes(item.name)}
                        onChange={() => handleCheckboxChange(item.name)}
                      />
                      <span>{item.name}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Mobile Filter Button */}
      <div
        className={`fixed flex justify-between items-center left-0 w-full bg-[#DCFFC4] z-[20] border-b border-[#DADADA] lg:hidden p-4 transition-all duration-300 ${
          isSticky ? "top-0" : "top-[85px]"
        }`}
      >
        <div
          className='flex-2 bg-white rounded-[5rem] border-1 p-2 items-center justify-center'
          onClick={handleMobileFilterClick}
        >
          <img src={filter} alt='Filter' className='w-6 h-6' />
        </div>
      </div>

      {/* Mobile Filter Modal */}
      {isMobileFilterOpen && (
        <div className='fixed inset-0 z-[60] bg-white flex flex-col lg:hidden'>
          <div className='flex items-center justify-between p-4 border-b border-[#DADADA]'>
            <button
              onClick={handleCloseMobileFilter}
              className='text-[#929292]'
            >
              &#10005;
            </button>
          </div>

          <div className='flex flex-grow overflow-y-auto'>
            <div className='w-1/3 bg-white border-r border-b border-t border-[#929292] overflow-hidden'>
              <ul className='space-y-2 p-0 mt-5 text-[16px] font-[700] font-dm-sans text-[#000000]'>
                {["Modes", "Levels", "Streams"].map((filter, index) => (
                  <li
                    key={index}
                    onClick={() => setActiveMobileFilter(filter)}
                    className={`p-2 cursor-pointer w-full ${
                      activeMobileFilter === filter
                        ? "bg-cmsgreen text-white"
                        : "bg-white"
                    }`}
                  >
                    {filter}
                  </li>
                ))}
              </ul>
            </div>

            <div className='w-2/3 bg-white overflow-y-auto p-4 ml-4'>
              <ul className='space-y-2 mt-5'>
                {getMobileFilterData(activeMobileFilter).map((item, idx) => (
                  <li
                    key={idx}
                    className='flex items-center p-2 text-[14px] font-dm-sans font-[700]'
                  >
                    <input
                      type='checkbox'
                      className='mr-2 text-cmsgreen'
                      checked={selectedFilters.includes(item.name)}
                      onChange={() => handleCheckboxChange(item.name)}
                    />
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className='fixed bottom-0 left-0 w-full bg-cmsgreen border border-[#929292]'>
            <div className='flex items-center h-16'>
              <div
                className='flex items-center justify-center w-1/2'
                onClick={handleCloseMobileFilter}
              >
                <p className='text-[14px] font-dm-sans font-[500] text-white'>
                  Apply Filters
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content Section */}
      <div className='w-full lg:w-3/4 flex flex-col gap-8 lg:p-2'>
        <div className='flex justify-center mb-0 lg:mb-2 '>
          <h2 className='font-dm-sans text-2xl lg:text-3xl font-bold text-center'>
            {selectedFilters.length > 0
              ? "Filtered Exams"
              : "All Entrance Exams"}
          </h2>
        </div>

        {isLoading ? (
          <div className='flex justify-center items-center h-[calc(100vh-200px)]'>
            <p>Loading...</p>
          </div>
        ) : examsData.length === 0 ? (
          <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)] w-full'>
            <img src={nodata} alt='No data' className='w-40 h-40 mb-4' />
            <p className='text-lg font-semibold text-gray-600'>
              No exams found.
            </p>
            <button onClick={handleClearFilters} className='text-blue-500'>
              View All Exams
            </button>
          </div>
        ) : (
          <div>
            {examsData.map((exam) => (
              <ExamCard key={exam.id} exam={exam} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ExamCard = ({ exam }) => {
  const navigate = useNavigate();

  const handleButtonClick = (tabName) => {
    const tabMapping = {
      Overview: 1,
      Eligibility: 2,
      "Application Form": 3,
      "Exam Pattern": 4,
      Syllabus: 5,
    };

    // Replace spaces with underscores for the exam name and trim any trailing underscores
    const formattedExamName = exam.exam_master_name.trim().replace(/\s+/g, "-");

    navigate(`/exams/${formattedExamName?.toLowerCase()}`, {
      state: {
        selectedTab: tabMapping[tabName],
      },
    });
  };

  return (
    <div className='relative border w-full rounded-lg shadow-lg p-5  mt-6 flex flex-col lg:flex-row md:items-center items-start'>
      <div className='w-full flex flex-col justify-between p-4 relative'>
        <div className='flex justify-between items-center mb-4'>
          <div>
            <h3 className='font-dm-sans text-lg font-bold cursor-pointer'>
              {exam.exam_master_name}
            </h3>
            <p className='text-gray-600 text-sm mt-1'>
              Last Updated: {new Date(exam.updated_on).toLocaleDateString()}
            </p>
          </div>
          <button className='bg-[#3ACA2E] text-white py-2 px-4 rounded-[20px] flex items-center gap-2 text-sm font-bold whitespace-nowrap'>
            <FaBell />
            Set Exam Alert
          </button>
        </div>
        <div className='mt-6 flex flex-wrap gap-4'>
          {[
            "Overview",
            "Eligibility",
            "Application Form",
            "Exam Pattern",
            "Syllabus",
          ].map((tab) => (
            <ExamButton
              key={tab}
              title={tab}
              onClick={() => handleButtonClick(tab)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ExamButton = ({ title, onClick }) => (
  <button
    className='bg-white border border-[#3ACA2E] text-black text-sm px-4 py-2 rounded-[20px] font-dm-sans font-bold hover:bg-[#3ACA2E] hover:text-white transition-colors duration-300'
    onClick={onClick}
  >
    {title}
  </button>
);

export default AllExams;
