import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { Base_URL } from '../../../apiConfig';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddGeneralFaqs = () => {
    const [formData, setFormData] = useState({
        qna: [{ question: '', answer: '' }], // Initialize with one empty QnA
      });
      const [error, setError] = useState(null);
      const [universities, setUniversities] = useState([]);  // State to store universities
    
      const location = useLocation();
      const { mainTitle, subTitle } = location.state || {};
      const { registration_id, token,name } = useSelector((state) => state.auth);
    
    
    
      const handleQnaChange = (index, field, value) => {
        const updatedQna = [...formData.qna];
        updatedQna[index][field] = value;
        setFormData((prev) => ({ ...prev, qna: updatedQna }));
      };
    
      const handleAddQna = () => {
        setFormData((prev) => ({
          ...prev,
          qna: [...prev.qna, { question: '', answer: '' }],
        }));
      };
    
      const handleRemoveQna = (index) => {
        const updatedQna = formData.qna.filter((_, i) => i !== index);
        setFormData((prev) => ({ ...prev, qna: updatedQna }));
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // Ensure registration_typeid is always 3
          const postData = { ...formData,  qna: JSON.stringify(formData.qna)} // Serialize qna field};
    
          const response = await axios.post(`${Base_URL}/create/admin_faq`, postData, {
            headers: {
              'Content-Type': 'application/json',
               'authorization': `Bearer ${token}`
            },
            withCredentials: true // This allows cookies to be sent with the request
          });
    
          toast.success('FAQ added successfully!');
          // setFormData({
          //   question: '',
          //   answer: '',
          //   university_id: formData.university_id,
          //   user_id: formData.user_id,
          //   registration_typeid: 3, // Reset to default value
          // });
          // setError(null);
        } catch (error) {
          console.error('Failed to add FAQ:', error.response?.data || error.message);
          toast.error('Failed to add FAQ. Please try again.');
        }
      };
    
  return (
  <>
<ToastContainer />
    <div className="bg-gray-100 h-screen p-6 rounded-lg ">
      <HeaderTitle subTitle={subTitle} mainTitle={mainTitle} />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        {/* Basic Info Header */}
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[rgb(136,136,136)] text-[17.25px] font-[400] font-poppins">FAQ Details</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            {/* Form Fields */}
             {/* QnA Section */}
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-medium text-sm">Questions and Answers</label>
            {formData.qna.map((qna, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  placeholder="Question"
                  value={qna.question}
                  onChange={(e) => handleQnaChange(index, 'question', e.target.value)}
                  className="border border-gray-300 p-2 rounded w-full mr-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Answer"
                  value={qna.answer}
                  onChange={(e) => handleQnaChange(index, 'answer', e.target.value)}
                  className="border border-gray-300 p-2 rounded w-full mr-2"
                  required
                />
                <button
                  type="button"
                  onClick={() => handleRemoveQna(index)}
                  className="text-red-600 hover:text-red-800 ml-2"
                >
                  ×
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddQna}
              className="p-2 bg-cmsgreen text-white rounded text-sm"
            >
              Add Question
            </button>
          </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded-md font-poppins">
            Submit
          </button>
        </form>
      </div>
    </div>
    </>
  )
}

export default AddGeneralFaqs