import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import filter from '../../../assets/landing/filter.png'; // Replace with actual filter icon path
import './LandingUniversity.css';

function LandingUniversityFilterPage() {
  const [activeFilter, setActiveFilter] = useState(null);
  const navigate = useNavigate();

  // Dummy data for filters
  const filters = ['Filter 1', 'Filter 2', 'Filter 3', 'Filter 4', 'Filter 5', 'Filter 6'];

  const handleApplyFilters = () => {
    // Navigate back to the previous page
    navigate(-1);
  };

  return (
    <>
     

      <div className="flex flex-col h-screen">
        {/* Fixed Header with "Filters" */}
        <div className="sticky top-0 z-10 h-16 border-b border-[#DADADA] bg-white shadow-lg flex items-center justify-center">
          <img src={filter} alt="Filter Icon" className="w-6 h-6 mr-2" />
          <h1 className="text-[14px] text-[#929292] font-dm-sans font-[500]">Filters</h1>
        </div>

        {/* Main Content */}
        <div className="flex flex-grow ">
          {/* Left Column - Filter List */}
          <div className="w-1/3 bg-white border-r border-b border-t border-[#929292]  overflow-hidden">
            <ul className="space-y-2 p-0 mt-5 text-center text-[14px] font-[700] font-dm-sans text-[#000000]">
              {filters.map((filterItem, index) => (
                <li
                  key={index}
                  onClick={() => setActiveFilter(index)}
                  className={`p-2 cursor-pointer w-full ${activeFilter === index ? 'bg-[#F2F2F2] border-t border-[#00000040]' : 'bg-white'}`}
                  style={{ height: '38px' }}
                >
                  {filterItem}
                </li>
              ))}
            </ul>
          </div>

          {/* Right Column - Filter Options */}
          <div className="w-2/3 bg-white overflow-hidden p-4 ml-4">
            <ul className="space-y-2 mt-5">
              {filters.map((filterItem, index) => (
                <li key={index} className="flex items-center space-x-2 ">
                  <input type="checkbox" className="mr-2 " />
                  <span className='text-[14px] font-[700] font-dm-sans text-[#000000]'>{filterItem}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Sticky Footer */}
        <div className="fixed bottom-0 left-0 w-full bg-[#ffffff] border border-[#929292] lg:hidden">
  <div className="flex items-center h-16">
    {/* Column 1 */}
    <div className="flex items-center justify-center w-1/2">
     
      <p className="text-[14px] font-dm-sans font-[500] text-[#929292]">Clear All</p>
    </div>

    {/* Vertical Line Separator */}
    <div className="h-full border-l border-[#929292]"></div>

    {/* Column 2 */}
    <div className="flex items-center justify-center w-1/2"  onClick={handleApplyFilters}>
   
      <p className="text-[14px] font-dm-sans font-[500] text-[#929292]">Apply Filters</p>
    </div>
  </div>
</div>
</div>
    </>
  );
}

export default LandingUniversityFilterPage;
