import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { Base_URL } from "../../../apiConfig";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const AddCredentials = () => {
  const navigate = useNavigate();
  const [universities, setUniversities] = useState([]);
  const [formData, setFormData] = useState({
    university_id: null,
    registration_typeid: 2,
    email: "",
    password: "",
    status: "", // Default value for status
    // createdBy: 1,
    phone_number: null,
  });
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [loading, setLoading] = useState(false); // Track loading state
  const { registration_id, token, name } = useSelector((state) => state.auth);

  useEffect(() => {
    // Fetch list of universities
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`);
        const universityOptions = response.data.map((university) => ({
          value: university.university_id,
          label: university.university_name,
        }));
        setUniversities(universityOptions);
      } catch (error) {
        console.error("Failed to fetch universities:", error);
        toast.error("Failed to load universities");
      }
    };

    fetchUniversities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      university_id: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleStatusChange = (selectedOption) => {
    // Convert to '1' for active and '0' for inactive
    setFormData({ ...formData, status: selectedOption });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const newProgram = {
        ...formData,
        phone_number: formData.phone_number
          ? parseInt(formData.phone_number, 10)
          : null, // Convert phone number to int
      };

      await axios.post(`${Base_URL}/universities_credentials`, newProgram, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        withCredentials: true, // This allows cookies to be sent with the request
      });
      toast.success("Credentials added successfully!");
    } catch (error) {
      toast.error(
        `Failed to add credential!! ${error.response.data.error.code}`
      );

      setLoading(false);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 h-screen'>
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label
                  htmlFor='university_id'
                  className='mb-2 font-medium text-sm'
                >
                  Select University
                </label>
                <Select
                  options={universities}
                  value={universities.find(
                    (option) => option.value === formData.university_id
                  )}
                  onChange={handleSelectChange}
                  placeholder='Select a university'
                  className='basic-single'
                  classNamePrefix='select'
                  isClearable
                />
              </div>

              <div className='flex flex-col'>
                <label htmlFor='email' className='mb-2 font-medium text-sm'>
                  Email
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label htmlFor='password' className='mb-2 font-medium text-sm'>
                  Password
                </label>
                <input
                  type='password'
                  id='password'
                  name='password'
                  value={formData.password}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='phone_number'
                  className='mb-2 font-medium text-sm'
                >
                  Phone Number
                </label>
                <input
                  type='text'
                  id='phone_number'
                  name='phone_number'
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label htmlFor='status' className='mb-2 font-medium text-sm'>
                  Status
                </label>
                <Select
                  options={[
                    { value: 1, label: "Active" },
                    { value: 0, label: "Inactive" },
                  ]}
                  value={
                    formData.status === 1
                      ? { value: 1, label: "Active" }
                      : { value: 0, label: "Inactive" }
                  }
                  onChange={(selectedOption) =>
                    handleStatusChange(selectedOption.value)
                  }
                  placeholder='Select Status'
                  className='basic-single'
                />
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            >
              {loading ? ( // Show spinner when loading
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                "Add Credentials"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCredentials;
