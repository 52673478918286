import React, { useState } from "react";
import SimpleEnquiryForm from "../../../Forms/SimpleEnquiryForm";
import ExpertHelp from "../../../Forms/ExpertHelp";

const CourseCard = ({
  program_master_name,
  program_fees,
  program_duration,
  study_mode,
  eligibility,
  course_offered,
  id,
  onViewMore,
  university_id,
  program_master_id,
  course_master_id,
  title,
  course_details


}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState("");
  const [modal,setModal]=useState(false)

  const openModal = () => {
    setSelectedCollege(program_master_name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModal(false)
  };

  return (
    <>
      <div className='bg-white p-6 mx-auto rounded-lg border shadow-md w-full lg:w-[320px] xl:w-[400px] relative flex flex-col justify-between h-full'>
        {/* Title with White Heart Icon and Gray Outline */}
        <div className='flex items-center justify-between'>
          <p className='text-lg font-bold text-black'>{program_master_name}</p>
          <button className='flex items-center justify-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='white'
              viewBox='0 0 24 24'
              className='w-5 h-5 stroke-gray-500'
              strokeWidth='1.5'
            >
              <path d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z' />
            </svg>
          </button>
        </div>

        {/* Fees, Duration, Study Mode */}
        <div className='mt-4 grid grid-cols-2 lg:grid-cols-3 gap-4 border-b pb-4'>
          <div className='text-center'>
            <p className='text-sm font-semibold text-black'>{program_fees}</p>
            <p className='text-xs text-gray-500'>Fees</p>
          </div>
          <div className='text-center'>
            <p className='text-sm font-semibold text-black'>{program_duration}</p>
            <p className='text-xs text-gray-500'>Duration</p>
          </div>
          <div className='text-center'>
            <p className='text-sm font-semibold text-black'>{study_mode}</p>
            <p className='text-xs text-gray-500'>Study Mode</p>
          </div>
        </div>

        {/* Eligibility */}
        <div className='mt-4 border-b pb-4'>
          <p className='text-xs text-gray-500'>Eligibility</p>
          <p className='text-sm text-gray-600'>{eligibility}</p>
        </div>

      

        {/* Action Buttons */}
       
        <div className='mt-6 flex flex-col lg:flex-row justify-between'>
  <button
    className='w-full lg:w-[120px] mb-2 sm:mb-0 px-6 py-2 text-sm font-semibold text-white bg-[#3ACA2E] rounded-[10px]'
    onClick={openModal}
  >
    Apply Now
  </button>
  <button
    className='w-full sm:w-[150px] px-6 py-2 text-sm font-semibold text-[#3ACA2E] border border-[#3ACA2E] rounded-[10px]'
    onClick={() => title.includes('Expert')?setModal(true):onViewMore(id)}
  >
   {title}
  </button>
</div>

      </div>

      {
        modal && (
            <ExpertHelp
            isOpen={modal}
            onClose={closeModal}
            />
        )
      }

      {isModalOpen && (
        <SimpleEnquiryForm
          isOpen={isModalOpen}
          onClose={closeModal}
          selectedCollege={selectedCollege}
          university_id={university_id}
          program_master_id={program_master_id}
          course_master_id={course_master_id}
        />
      )}
    </>
  );
};

export default CourseCard;
