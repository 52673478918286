import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux"; // Import useSelector

const EditScholarship = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const scholarship = location.state;

  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);

  // State for the form data
  const [formData, setFormData] = useState({
    university_name: scholarship?.university_name || "",
    scholarship_desc: scholarship?.scholarship_desc || "",
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updatedScholarship = {
        scholarship_desc: formData.scholarship_desc,
      };

      // Make PUT request to update scholarship with authorization and credentials
      await axios.put(`${Base_URL}/update/scholarship/${scholarship.id}`, updatedScholarship, {
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${token}`, // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });

      toast.success("Scholarship updated successfully!");
      setTimeout(() => {
        navigate("/admin/scholarship/all"); // Navigate back to the list after success
    }, 2000);
    } catch (error) {
      console.error("Failed to update scholarship:", error.response?.data || error.message);
      toast.error("Failed to update scholarship. Please try again.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderTitle mainTitle="Scholarship" subTitle="Edit Scholarship" />

      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* University Name */}
            <div className="flex flex-col">
              <label htmlFor="university_name" className="mb-2 font-medium text-sm">
                University Name
              </label>
              <input
                type="text"
                id="university_name"
                name="university_name"
                value={formData.university_name}
                className="border border-gray-300 p-2 rounded bg-gray-200"
                disabled
              />
            </div>

            {/* Scholarship Description (CKEditor) */}
            <div className="flex flex-col md:col-span-2">
              <label htmlFor="scholarship_desc" className="mb-2 font-medium text-sm">
                Scholarship Description
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={formData.scholarship_desc}
                onChange={(event, editor) => {
                  setFormData({ ...formData, scholarship_desc: editor.getData() });
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </form>
      </div>

      {/* Toast for notifications */}
      <ToastContainer />
    </div>
  );
};

export default EditScholarship;
