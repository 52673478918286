import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import HeaderSection from "../../../dashboard/sidebarpages/headerSection/HeaderSection";
import { useSelector } from "react-redux";
import { Base_URL } from "../../../apiConfig";
const SubCareerForm = () => {
  const [careerMasterData, setCareerMasterData] = useState([]);
  const [selectedCareer, setSelectedCareer] = useState("");
  const [subCareerName, setSubCareerName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("No file chosen");
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchCareerMaster = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/career_master`);
        setCareerMasterData(response.data);
      } catch (error) {
        console.error("Error fetching career master data:", error);
        toast.error("Failed to load career data");
      }
    };

    fetchCareerMaster();
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedCareer || !subCareerName) {
      toast.error("Please fill all required fields!");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("career_master_id", selectedCareer);
      formData.append("name", subCareerName);
      formData.append("created_by", 1);
      if (image) {
        formData.append("picture", image);
      }

      const response = await axios.post(
        `${Base_URL}/create-upload/SubCareerMaster`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Sub-career successfully created!");
      setSelectedCareer("");
      setSubCareerName("");
      setImage(null);
      setImageName("No file chosen");
    } catch (error) {
      console.error("Error submitting sub-career data:", error);
      toast.error("Failed to create sub-career");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='bg-gray-100 p-6'>
      <HeaderSection
        title='Create Sub Career'
        breadcrumb={["Career", "Create Sub Career"]}
      />
      <div className='bg-white p-6 rounded-lg shadow'>
        <div className='flex items-center justify-between mb-4 pb-2 border-b border-gray-200'>
          <div
            className='font-poppins'
            style={{
              color: "rgb(49, 42, 42)",
              fontSize: "18.5px",
              fontWeight: 600,
              lineHeight: "26.25px",
              height: "45px",
            }}
          >
            Create Sub Career
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <label
                htmlFor='career'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Select Career
              </label>
              <select
                id='career'
                name='career'
                value={selectedCareer}
                onChange={(e) => setSelectedCareer(e.target.value)}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              >
                <option value=''>-- Select Career --</option>
                {careerMasterData.map((career) => (
                  <option key={career.id} value={career.id}>
                    {career.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='subCareerName'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Sub Career Name
              </label>
              <input
                id='subCareerName'
                name='subCareerName'
                type='text'
                value={subCareerName}
                onChange={(e) => setSubCareerName(e.target.value)}
                placeholder='Enter sub career name'
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>

            <div className='flex flex-col'>
              <label
                htmlFor='picture'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Upload Picture
              </label>
              <div className='flex border border-gray-300 rounded-md mt-2 overflow-hidden'>
                <label className='flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]'>
                  <span>Choose File</span>
                  <input
                    type='file'
                    id='picture'
                    name='picture'
                    onChange={handleImageChange}
                    className='hidden'
                  />
                </label>
                <div className='flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]'>
                  {imageName}
                </div>
              </div>
            </div>
          </div>

          <button
            type='submit'
            className='mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md flex items-center justify-center'
            disabled={isLoading}
          >
            {isLoading ? (
              <div className='flex justify-center items-center'>
                <svg
                  className='animate-spin h-5 w-5 text-white mr-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                  ></path>
                </svg>
                Creating...
              </div>
            ) : (
              "Create Sub Career"
            )}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SubCareerForm;
