import React, { useEffect } from "react";

const TermsConditions = () => {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='min-h-screen'>
      <section
        className='flex flex-col items-center justify-center py-8 mt-[40px] bg-[rgba(0,0,0,0.5)] border-b-2 border-gray-200 text-2xl font-bold'
       
      >
        <div className='text-center text-white'>
          <div>Terms of Service</div>
          <div className='text-sm  mt-2'>
            Effective Date: October 1, 2024
          </div>
        </div>
      </section>
      <div className='flex-grow py-8 '>
        <div className=' bg-white p-8 '>
          {/* Section - Introduction */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>Introduction</h2>
            <p className='text-gray-700'>
              Welcome to College Dwarka. By accessing and using our platform,
              you agree to comply with and be bound by the following Terms of
              Service. Please read these terms carefully before using our
              services.
            </p>
          </section>

          {/* Section - Acceptance of Terms */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>
              1. Acceptance of Terms
            </h2>
            <p className='text-gray-700'>
              By accessing or using any part of College Dwarka, you agree to be
              bound by these Terms of Service. If you do not agree to all the
              terms, you may not access or use our services.
            </p>
          </section>

          {/* Section - Changes to Terms */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>2. Changes to Terms</h2>
            <p className='text-gray-700'>
              We reserve the right to modify or replace these terms at any time.
              We will notify you of any changes by updating the terms on this
              page. Your continued use of our platform after any changes
              constitutes acceptance of the new terms.
            </p>
          </section>

          {/* Section - User Accounts */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>3. User Accounts</h2>
            <p className='text-gray-700'>
              To use certain features of our platform, you may be required to
              create an account. You are responsible for maintaining the
              confidentiality of your account information and for all activities
              that occur under your account.
            </p>
          </section>

          {/* Section - Use of the Platform */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>
              4. Use of the Platform
            </h2>
            <p className='text-gray-700 mb-4'>
              You agree to use College Dwarka only for lawful purposes. You may
              not use our platform to engage in any illegal or unauthorized
              activities, including but not limited to:
            </p>
            <ul className='list-disc list-inside pl-4 text-gray-700 space-y-2'>
              <li>Violating any local, state, or federal laws</li>
              <li>Interfering with the operation of the platform</li>
              <li>Impersonating another person or entity</li>
              <li>Distributing harmful or malicious content</li>
            </ul>
          </section>

          {/* Section - Intellectual Property */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>
              5. Intellectual Property
            </h2>
            <p className='text-gray-700'>
              All content on College Dwarka, including text, graphics, logos,
              and software, is the property of College Dwarka or its content
              suppliers. You agree not to reproduce, duplicate, or exploit any
              portion of the platform without express permission.
            </p>
          </section>

          {/* Section - Termination */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>6. Termination</h2>
            <p className='text-gray-700'>
              We reserve the right to terminate or suspend your account and
              access to our platform at our sole discretion, without notice or
              liability, if you violate these Terms of Service or engage in
              prohibited activities.
            </p>
          </section>

          {/* Section - Limitation of Liability */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>
              7. Limitation of Liability
            </h2>
            <p className='text-gray-700'>
              College Dwarka and its affiliates are not liable for any direct,
              indirect, incidental, or consequential damages arising out of your
              use or inability to use the platform. We provide the platform "as
              is" and make no warranties regarding the platform’s functionality
              or availability.
            </p>
          </section>

          {/* Section - Governing Law */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>8. Governing Law</h2>
            <p className='text-gray-700'>
              These Terms of Service are governed by and construed in accordance
              with the laws of India, without regard to its conflict of law
              provisions.
            </p>
          </section>

          {/* Section - Contact Us */}
          <section className='mb-8'>
            <h2 className='text-xl font-semibold mb-4'>9. Contact Us</h2>
            <p className='text-gray-700'>
              If you have any questions about these Terms of Service, please
              contact us at:
            </p>
            <p className='text-gray-700 mt-2'>Email: info@collegedwarka.com</p>
          </section>

         
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
