import { API_ENDPOINT } from "../../utils/constant"; // Import the API endpoint constants
import apiClient from "../apiClient"; // Import the Axios instance with interceptors

const CourseListService = {
  /**
   * Fetch all courses.
   * @returns {Promise<Array>} List of courses.
   */
  fetchCourses: async () => {
    try {
      const response = await apiClient.get(API_ENDPOINT.COURSE_DETAILS);
      return response.data;
    } catch (error) {
      console.error("Error fetching courses:", error.message);
      throw new Error(
        error.response?.data?.message || "Failed to fetch courses"
      );
    }
  },


  /**
   * Fetch courses with joins and filter by ID.
   * @param {number} id The ID to filter courses.
   * @returns {Promise<Object>} The filtered course details.
   */
  fetchCourseDetailsWithJoin: async (options) => {
    try {
      const url = `${API_ENDPOINT.JOIN_WHERE}?options=${encodeURIComponent(
        JSON.stringify(options)
      )}`;
      const response = await apiClient.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching exam details:", error.message);
      throw new Error(
        error.response?.data?.message || "Unable to fetch exam details"
      );
    }
  },
};




export default CourseListService;
