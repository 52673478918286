import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../styles/college.css";
//import Navbar from '../components/Header/Navbar';
import { throttle } from "lodash";

import college_grad from "../assets/college_grad.png";
import Admission from "../components/College/Admission/Admission";
import Chat from "../components/College/Chat/Chat";
import Courses from "../components/College/Courses/Courses";
import Gallery from "../components/College/Gallery/Gallery";
import NewsEvents from "../components/College/NewsEvents/NewsEvents";
import Overview from "../components/College/Overview/Overview";
import Placements from "../components/College/Placements/Placements";
import QA from "../components/College/QA/QA";
import Scholarship from "../components/College/Scholarship/Scholarship";
import Reviews from "../components/Landing/Reviews/Reviews";

import { useSelector } from "react-redux";
import CollegeListService from "../api/services/CollegeListService";
import MasterDataService from "../api/services/MasterDataService";
import { Base_URL, Img_URL } from "../apiConfig";
import EnquiryForm from "../components/Forms/EnquiryForm";
import HatCircleLoader from "../components/Loader/HatCircleLOader";

const College = () => {
  const [collegeDetails, setCollegeDetails] = useState(null);
  const [preview, setPreview] = useState(0);
  const [logoPath, setLogoPath] = useState("");
  const [pictures, setPictures] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [qualification, setQualification] = useState("");
  const [program, setProgram] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact_no: "",
    program: "",
  });
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState("");
  const [error, setError] = useState(null);
  const mainSectionRef = useRef(null);
  const location = useLocation();

  const [originalTop, setOriginalTop] = useState(null); // Initially null until loaded
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0); // Track last scroll position
  // Track original top position
  const subheaderRef = useRef(null);
  const { token } = useSelector((state) => state.auth);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [authMode, setAuthMode] = useState("signup");
  const [headerHeight, setHeaderHeight] = useState(0); // Add state to track header height

  const { university_name } = useParams(); // Extract university name from URL

  useEffect(() => {
    const fetchUniversityData = async () => {
      try {
        // Replace hyphens with spaces

        // Fetch data from the API
        const response = await CollegeListService.FetchCollegeByName(
          university_name
        );

        if (response[0]) {
          if (response[0].logo) {
            const logoUrl = `${Img_URL}/${response[0].logo}`;
            setLogoPath(logoUrl);
          }

          // Fetch video if available
          if (response[0].video) {
            const videoUrl = `${Img_URL}/${response[0].video}`;
            setVideoPath(videoUrl);
          }

          // Fetch pictures if available
          if (response[0].pictures) {
            try {
              let picturesArray;
              if (Array.isArray(response[0].pictures)) {
                picturesArray = response[0].pictures;
              } else if (typeof response[0].pictures === "string") {
                picturesArray = response[0].pictures.split(",");
              } else {
                throw new Error("Invalid format for pictures.");
              }

              const pictureUrls = picturesArray?.map(
                (picture) => `${Img_URL}/${picture.trim()}`
              );

              setPictures(pictureUrls);
            } catch (error) {
              console.error("Error parsing pictures:", error);
            }
          }
        }

        setCollegeDetails(response[0]);
      } catch (error) {
        console.error("Error fetching university data:", error);
        setMessage("Failed to load university data");
      }
    };

    fetchUniversityData();
  }, [university_name]);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await MasterDataService.fetchDegrees();

        setProgramOptions(response);
      } catch (error) {
        console.error("Error fetching programs:", error);
        setMessage("Failed to load programs");
      }
    };
    fetchPrograms();
  }, []);

  useEffect(() => {
    const updateOriginalTop = () => {
      if (subheaderRef.current) {
        // Calculate original top position relative to viewport
        setOriginalTop(
          subheaderRef.current.getBoundingClientRect().top + window.scrollY
        );
      }
    };

    // Update `originalTop` after a short delay to ensure DOM is fully rendered
    const delayedUpdate = setTimeout(updateOriginalTop, 200);

    const handleScroll = () => {
      // Make subheader sticky based on scroll position
      if (originalTop !== null && window.scrollY >= originalTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Throttle scroll handler for better performance
    const throttledScrollHandler = throttle(handleScroll, 100);

    // Attach scroll and resize event listeners
    window.addEventListener("scroll", throttledScrollHandler);
    window.addEventListener("resize", updateOriginalTop);

    // Cleanup on component unmount
    return () => {
      clearTimeout(delayedUpdate);
      window.removeEventListener("scroll", throttledScrollHandler);
      window.removeEventListener("resize", updateOriginalTop);
    };
  }, [originalTop]);

  const formSectionRef = useRef(null);
  const [videoPath, setVideoPath] = useState("");

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // Fetch the logo, video and pictures if available
  }, [collegeDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if all fields are filled
    if (!name || !email || !contactNo || !program) {
      toast.error("Please fill all fields.", {});
      // Clear fields on validation error
      setName("");
      setEmail("");
      setContactNo("");
      setProgram("");
      return;
    }

    const formData = {
      name,
      email,
      contact_no: contactNo,
      program: program,
    };

    try {
      const response = await axios.post(
        `${Base_URL}/create/enquiry_form`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        toast.success("Enquiry submitted successfully!");
        // Clear all fields on success
        setName("");
        setEmail("");
        setContactNo("");
        setProgram("");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Failed to submit enquiry. Please try again.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginTop: "160px",
            zIndex: 10000,
          },
        }
      );
      // Clear all fields on error
      setName("");
      setEmail("");
      setContactNo("");
      setProgram("");
      console.error(error);
    }
  };

  const openModal = (collegeName) => {
    setSelectedCollege(collegeName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const validateForm = () => {
    const nameRegex = /^[a-zA-Z\s]+$/; // Name: Only alphabets and spaces
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email validation
    const contactRegex = /^[0-9]{10}$/; // Mobile number validation
    if (!nameRegex.test(name)) {
      toast.error("Name should only contain alphabets and spaces.");
      return false;
    }

    if (!emailRegex.test(email)) {
      toast.error("Invalid email address.");
      return false;
    }

    if (!contactRegex.test(contactNo)) {
      toast.error("Contact number must be a valid 10-digit number.");
      return false;
    }

    if (!program) {
      toast.error("Please select a program.");
      return false;
    }

    return true;
  };
  const handleEnquirySubmit = async (formData) => {
    if (!validateForm()) return;
    try {
      const response = await axios.post(
        `${Base_URL}/create/enquiry_form`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        toast.success("Enquiry submitted successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginTop: "160px",
            zIndex: 10000,
          },
        });
        closeModal(); // Close modal after successful submission
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Failed to submit enquiry. Please try again.",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            marginTop: "160px",
            zIndex: 10000,
          },
        }
      );
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handlePreview = (view) => {
    setPreview(view);

    // Only scroll if the subheader is sticky
    if (isSticky) {
      const headerHeight = 100; // Height of the main header
      const subheaderHeight = 60; // Height of the subheader

      // Calculate the position to scroll to
      // This will be the original position of the subheader
      const scrollToPosition = originalTop - headerHeight - subheaderHeight;

      // Scroll to show header, sticky subheader, and content
      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const scrollToForm = () => {
    if (formSectionRef.current) {
      const yOffset = -100; // Offset to account for fixed header
      const element = formSectionRef.current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleDropdownInteraction = () => {
      const subheader = document.querySelector(".subheader");
      const dropdowns = document.querySelectorAll(
        ".navbar-dropdown, .dropdown-menu"
      );

      if (!subheader || !dropdowns.length) return;

      const handleDropdownEnter = () => {
        if (subheader.classList.contains("fixed")) {
          subheader.classList.add("dropdown-active");
        }
      };

      const handleDropdownLeave = () => {
        if (subheader.classList.contains("fixed")) {
          subheader.classList.remove("dropdown-active");
        }
      };

      dropdowns.forEach((dropdown) => {
        dropdown.addEventListener("mouseenter", handleDropdownEnter);
        dropdown.addEventListener("mouseleave", handleDropdownLeave);
      });

      return () => {
        dropdowns.forEach((dropdown) => {
          dropdown.removeEventListener("mouseenter", handleDropdownEnter);
          dropdown.removeEventListener("mouseleave", handleDropdownLeave);
        });
      };
    };

    handleDropdownInteraction();
  }, []);

  useEffect(() => {
    const handleAuthModal = (event) => {
      if (event.detail.mode === "signup") {
        setModalIsOpen(true);
        setAuthMode("signup");
      }
    };

    window.addEventListener("openAuthModal", handleAuthModal);

    return () => {
      window.removeEventListener("openAuthModal", handleAuthModal);
    };
  }, []);

  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.querySelector("header"); // Adjust selector to match your header
      if (header) {
        setHeaderHeight(header.offsetHeight); // Set header height
      }
    };

    updateHeaderHeight(); // Initial call to set header height
    window.addEventListener("resize", updateHeaderHeight); // Update on resize

    return () => {
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, []);

  if (!collegeDetails) {
    return <HatCircleLoader />;
  }

  return (
    <div className='college_main'>
      {/* Top Section with consistent margins */}
      <div className='w-full max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 py-5 mt-10'>
        {/* College Title Section */}
        <div className='flex flex-col md:flex-row items-center justify-center gap-3 md:gap-5'>
          {/* College Logo */}
          <div className='h-[50px] w-[50px] md:h-[80px] md:w-[80px] flex-shrink-0'>
            <img
              src={logoPath}
              alt='college-logo'
              className='h-full w-full object-cover rounded-full'
            />
          </div>

          {/* College Name */}
          <h1 className='text-center md:text-left text-xl md:text-3xl font-[500] text-black'>
            {collegeDetails.university_name}
          </h1>
        </div>

        {/* Slider Section */}
        <div className='w-full max-w-[75%] mx-auto mt-8'>
          <Slider {...settings}>
            {videoPath && (
              <div className='w-full aspect-video'>
                <video
                  src={videoPath}
                  controls
                  className='w-full h-full object-cover rounded-2xl'
                />
              </div>
              //   <div className="w-full aspect-video">
              //   <iframe
              //     src={videoPath}
              //     title="Embedded Video"
              //     frameBorder="0"
              //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              //     allowFullScreen
              //     className="w-full h-full object-cover rounded-2xl"
              //   ></iframe>
              // </div>
            )}

            {pictures &&
              pictures?.map((picture, index) => (
                <div key={index} className='w-full aspect-video'>
                  <img
                    src={picture}
                    alt={`college-image-${index + 1}`}
                    className='w-full h-full object-cover rounded-2xl'
                  />
                </div>
              ))}
          </Slider>
        </div>

        {/* College tags */}
        <div className='flex flex-wrap justify-center gap-2 mt-8'>
          {collegeDetails.keywords &&
            collegeDetails.keywords?.slice(0, 6).map((item, index) => (
              <div
                key={index}
                className='text-white bg-[#003D83] px-3 py-2 rounded-xl text-xs md:text-sm text-center'
              >
                <p>
                  {item.title}: {item.details}
                </p>
              </div>
            ))}
        </div>

        {/* Action Buttons */}
        <div className='flex justify-center items-center gap-4 mt-8'>
          <button
            onClick={() => openModal(collegeDetails?.university_name)}
            className='w-[170px] px-4 py-2.5 bg-[#3ACA2E] text-white font-semibold rounded-xl hover:bg-[#2fb926] transition-colors text-sm md:text-base'
          >
            Apply Now
          </button>

          <button
            onClick={scrollToForm}
            className=' px-4 py-2.5 border-2 border-[#3ACA2E] text-black font-semibold rounded-xl hover:bg-gray-50 transition-colors text-sm md:text-base'
          >
            Get Free Counselling
          </button>
        </div>
      </div>

      {/* Options - sub header */}
      <div
        id='subheader'
        ref={subheaderRef}
        className={` subheader    top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header   bg-white shadow-md md:justify-center`}
        style={{
          position: isSticky ? "fixed" : "static",
          top: isSticky ? "0px" : "auto",
          width: "100%",
        }}
      >
        <p
          className={`college_sub_header_option ${
            preview === 0 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(0)}
        >
          Overview
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 1 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(1)}
        >
          Courses
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 2 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(2)}
        >
          Admission
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 3 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(3)}
        >
          Scholarship
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 4 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(4)}
        >
          Placements
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 5 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(5)}
        >
          Gallery
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 6 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(6)}
        >
          News & Events
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 7 ? "college_sub_header_active" : ""
          }`}
          onClick={() => handlePreview(7)}
        >
          Q/A
        </p>
        <p
          className={`college_sub_header_option ${
            preview === 8 ? "college_sub_header_active" : ""
          }`}
          onClick={() => (window.location.href = "https://wa.me/6005130753")}
        >
          Chat
        </p>
      </div>

      {/* Main Section */}
      {/* <div className="" ref={mainSectionRef}>
    {preview === 0 && (<Overview collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 1 && (<Courses collegeDetails={collegeDetails} />)}
    {preview === 2 && (<Admission collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 3 && (<Scholarship collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 4 && (<Placements collegeDetails={collegeDetails}  />)}
    {preview === 5 && (<Gallery collegeDetails={collegeDetails} logoPath={logoPath}  />)}
    {preview === 6 && (<NewsEvents collegeDetails={collegeDetails} logoPath={logoPath} />)}
    {preview === 7 && (<QA collegeDetails={collegeDetails} logoPath={logoPath} />)}
    {preview === 8 && (<Chat collegeDetails={collegeDetails}  />)}
</div> */}
      <div className='' ref={mainSectionRef}>
        {preview === 0 && (
          <Overview collegeDetails={collegeDetails} logoPath={logoPath} />
        )}
        {preview === 1 && <Courses collegeDetails={collegeDetails} />}
        {preview === 2 && (
          <Admission collegeDetails={collegeDetails} logoPath={logoPath} />
        )}
        {preview === 3 && (
          <Scholarship collegeDetails={collegeDetails} logoPath={logoPath} />
        )}
        {preview === 4 && <Placements collegeDetails={collegeDetails} />}
        {preview === 5 && (
          <Gallery collegeDetails={collegeDetails} logoPath={logoPath} />
        )}
        {preview === 6 && (
          <NewsEvents collegeDetails={collegeDetails} logoPath={logoPath} />
        )}
        {preview === 7 && (
          <QA collegeDetails={collegeDetails} logoPath={logoPath} />
        )}
        {/* Redirect to WhatsApp for preview 7 */}
        {preview === 8 && <Chat />}
      </div>

      {/* Details Form Section*/}
      <div
        className='flex flex-wrap  justify-center gap-20 items-center px-5 py-20 mobile-form-shadow '
        ref={formSectionRef}
      >
        <div className='flex flex-col items-center  gap-5 max-w-[700px] mx-auto px-4 md:px-0 md:text-center'>
          <p className='text-2xl text-black font-medium text-center md:text-center'>
            Unlock Your Future with Quality Education - Join Us for a
            Transformative College Experience!
          </p>
          <div className='w-full max-w-[400px]'>
            <img
              src={college_grad}
              className='w-full rounded-xl'
              alt='College Graduation'
            />
          </div>
        </div>

        <div className='flex flex-col gap-3 p-8 mx-auto max-w-full md:max-w-[400px] border border-gray-300 rounded-lg shadow-md '>
          <p className='text-black text-base font-medium md:text-center text-left'>
            Get Started
          </p>
          <p className='text-black text-base font-medium md:text-center text-left'>
            Fill your details and get your Information
          </p>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-3 w-full'>
              <div className='flex flex-col gap-1 w-full'>
                <label
                  className='text-black text-sm font-medium'
                  htmlFor='name'
                >
                  Name
                </label>
                <input
                  type='text'
                  placeholder='Enter your Full Name'
                  className='focus:outline-none rounded border border-gray-300 p-2'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className='flex flex-col gap-1 w-full'>
                <label
                  className='text-black text-sm font-medium'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  type='email'
                  placeholder='Enter your Email'
                  className='focus:outline-none rounded border border-gray-300 p-2'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className='flex flex-col gap-1 w-full'>
                <label
                  className='text-black text-sm font-medium'
                  htmlFor='contact'
                >
                  Contact Number
                </label>
                <input
                  type='tel'
                  placeholder='Enter your Phone Number'
                  className='rounded border border-gray-300 p-2 focus:outline-none'
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />
              </div>

              <div className='flex flex-col gap-1 w-full'>
                <label
                  className='text-black text-sm font-medium'
                  htmlFor='program'
                >
                  Program
                </label>
                <select
                  id='program'
                  name='program'
                  value={program}
                  onChange={(e) => setProgram(e.target.value)}
                  className='rounded border border-gray-300 p-2 focus:outline-none'
                  required
                >
                  <option value='' disabled>
                    Select Program
                  </option>
                  {programOptions?.map((prog) => (
                    <option key={prog.id} value={prog.id}>
                      {prog.program_master_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex justify-center py-3'>
                <button
                  type='submit'
                  className='bg-[#3ACA2E] rounded-3xl px-10 py-2 text-medium text-white text-lg'
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Student Reviews */}

      <Reviews />

      {/* Enquiry Form Modal */}
      {isModalOpen && (
        <EnquiryForm
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleEnquirySubmit}
          selectedCollege={collegeDetails?.university_name}
        />
      )}

      {/* Toast Container */}
      <ToastContainer
        position='top-right'
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        style={{
          zIndex: 10000,
          marginTop: "90px",
        }}
      />
    </div>
  );
};

export default College;
