import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { Base_URL } from '../../../apiConfig';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

const AddAdminFaq = () => {
  const [formData, setFormData] = useState({
    // question: '',
    // answer: '',
    university_id: '',  // This will be set from the dropdown
    // user_id: '',        // This will be set after fetching user data
    // registration_typeid: 3,  // Fixed value as specified
    qna: [{ question: '', answer: '' }], // Initialize with one empty QnA
  });
  const [error, setError] = useState(null);
  const [universities, setUniversities] = useState([]);  // State to store universities

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const { registration_id, token,name } = useSelector((state) => state.auth);

  useEffect(() => {
    // Fetching user_id and universities
    const fetchData = async () => {
      try {
        // Fetch user and university data
        const response = await axios.get(`${Base_URL}/universities`);
        const universityData = response.data;

        // Ensure the data is correctly structured
        setUniversities(universityData);  // Assuming data is a list of universities
      } catch (error) {
        console.error('Failed to fetch data:', error.response?.data || error.message);
        setError('Failed to fetch data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleQnaChange = (index, field, value) => {
    const updatedQna = [...formData.qna];
    updatedQna[index][field] = value;
    setFormData((prev) => ({ ...prev, qna: updatedQna }));
  };

  const handleAddQna = () => {
    setFormData((prev) => ({
      ...prev,
      qna: [...prev.qna, { question: '', answer: '' }],
    }));
  };

  const handleRemoveQna = (index) => {
    const updatedQna = formData.qna.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, qna: updatedQna }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ensure registration_typeid is always 3
      const postData = { ...formData,  qna: JSON.stringify(formData.qna)} // Serialize qna field};

      const response = await axios.post(`${Base_URL}/create/faq`, postData, {
        headers: {
          'Content-Type': 'application/json',
           'authorization': `Bearer ${token}`
        },
        withCredentials: true // This allows cookies to be sent with the request
      });

      alert('FAQ added successfully!');
      // setFormData({
      //   question: '',
      //   answer: '',
      //   university_id: formData.university_id,
      //   user_id: formData.user_id,
      //   registration_typeid: 3, // Reset to default value
      // });
      // setError(null);
    } catch (error) {
      console.error('Failed to add FAQ:', error.response?.data || error.message);
      setError('Failed to add FAQ. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 h-screen p-6 rounded-lg ">
      <HeaderTitle subTitle={subTitle} mainTitle={mainTitle} />
      <div className="border border-gray-300 bg-white p-4 rounded-md">
        {/* Basic Info Header */}
        <div className="flex justify-between items-center border-b border-gray-300 pb-2 mb-4">
          <h2 className="text-[rgb(136,136,136)] text-[17.25px] font-[400] font-poppins">FAQ Details</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            {/* Form Fields */}
            {/* <div className="flex flex-col">
              <label
                htmlFor="question"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Question
              </label>
              <input
                type="text"
                id="question"
                name="question"
                value={formData.question}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="answer"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                Answer
              </label>
              <input
                type="text"
                id="answer"
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              />
            </div> */}
            <div className="flex flex-col">
              <label
                htmlFor="university_id"
                className="text-[rgb(49,42,42)] cursor-default inline-block font-poppins text-[12.25px] font-[500] mb-[7px]">
                University
              </label>
              <select
                id="university_id"
                name="university_id"
                value={formData.university_id}
                onChange={handleChange}
                className="appearance-none bg-white border border-[rgba(228,229,230,0.99)] rounded-[5.25px] p-[4.375px] w-full text-[rgb(49,42,42)] font-poppins text-[13.118px] focus:outline-none"
                required
              >
                <option value="" disabled>Select University</option>
                {universities.map((university) => (
                  <option key={university.university_id} value={university.university_id}>
                    {university.university_name}
                  </option>
                ))}
              </select>
            </div>

             {/* QnA Section */}
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-medium text-sm">Questions and Answers</label>
            {formData.qna.map((qna, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  placeholder="Question"
                  value={qna.question}
                  onChange={(e) => handleQnaChange(index, 'question', e.target.value)}
                  className="border border-gray-300 p-2 rounded w-full mr-2"
                  required
                />
                <input
                  type="text"
                  placeholder="Answer"
                  value={qna.answer}
                  onChange={(e) => handleQnaChange(index, 'answer', e.target.value)}
                  className="border border-gray-300 p-2 rounded w-full mr-2"
                  required
                />
                <button
                  type="button"
                  onClick={() => handleRemoveQna(index)}
                  className="text-red-600 hover:text-red-800 ml-2"
                >
                  ×
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddQna}
              className="p-2 bg-cmsgreen text-white rounded text-sm"
            >
              Add Question
            </button>
          </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen text-white rounded-md font-poppins">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddAdminFaq;
