import React, { useEffect, useRef, useState } from 'react';

const ReviewCard = ({ rating, name, username, profile_img, review }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentRef = useRef(null);

    // For rating preview
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    // Check if the content exceeds the max height
    useEffect(() => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            setIsOverflowing(contentHeight > 100); // Change 100px to whatever height limit you desire
        }
    }, []);

    return (
        <div className="p-3 bg-white max-w-[400px]">
            <div className="flex gap-2 mb-5">
                <div className="h-[40px] w-[40px]">
                    <img src={profile_img} alt="user-image" className="w-full h-full" />
                </div>
                <div className="flex flex-col">
                    <p className="text-base text-black font-medium">{name}</p>
                    <p>@{username}</p>
                    <div className="flex gap-1 mt-1">
                        {Array.from({ length: fullStars }, (_, index) => (
                            <svg key={index} xmlns="http://www.w3.org/2000/svg" fill="#FCD34D" viewBox="0 0 24 24" strokeWidth="2" stroke="#FCD34D" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                            </svg>
                        ))}
                        {hasHalfStar && (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#FCD34D" viewBox="0 0 24 24" strokeWidth="2" stroke="#FCD34D" className="size-4">
                                <defs>
                                    <linearGradient id="half_grad">
                                        <stop offset="50%" stopColor="#FCD34D" />
                                        <stop offset="50%" stopColor="white" stopOpacity="1" />
                                    </linearGradient>
                                </defs>
                                <path strokeLinecap="round" strokeLinejoin="round" fill="url(#half_grad)" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                            </svg>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-full' : 'max-h-[100px]'}`} ref={contentRef}>
                    <p className="text-black text-base">
                        {review}
                    </p>
                </div>

                {/* Show More/Show Less button */}
                {isOverflowing && (
                    <div className="flex items-center gap-1 text-black cursor-pointer mt-2" onClick={() => setIsExpanded(!isExpanded)}>
                        <p className="text-sm font-semibold">{isExpanded ? 'Show Less' : 'Show More'}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d={isExpanded ? 'M19.5 8.25l-7.5 7.5-7.5-7.5' : 'M19.5 15.75l-7.5-7.5-7.5 7.5'} />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReviewCard;
