import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchFilteredUniversities } from "../../../api/services/FilterService";
import MasterDataService from "../../../api/services/MasterDataService";
import { Base_URL, Img_URL } from "../../../apiConfig";
import filter from "../../../assets/landing/filter.png";
import Hat from "../../../assets/landing/Hat.png";
import locationIcon from "../../../assets/landing/locationIcon.png";
import nodata from "../../../assets/landing/no_data.png";
import RankIcon from "../../../assets/landing/RankIcon.png";
import ratingIcon from "../../../assets/landing/ratingIcon.png";
import univIcon from "../../../assets/landing/univIcon.png";
import {
  getAllColleges,
  getFeaturedColleges,
  getPopularColleges,
} from "../../../redux/slice/collegesSlice";
import {
  fetchCities,
  fetchDegrees,
  fetchSpecializations,
  fetchStates,
} from "../../../redux/slice/masterDataSlice";
import EnquiryForm from "../../Forms/EnquiryForm";
import ExpertHelp from "../../Forms/ExpertHelp"; // Import the ExpertHelp component
import HatCircleLoader from "../../Loader/HatCircleLOader";
import "./LandingUniversity.css";

function LandingUniversity() {
  const { type, id, name, degree, filters, locationParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);

  const currentUrl = `${window.location.origin}${location.pathname}`;

  // State declarations
  const [wishlist, setWishlist] = useState(new Set());
  const [allColleges, setAllColleges] = useState([]);

  const [collegesByProgram, setCollegesByProgram] = useState([]);
  const [collegesByLocation, setCollegesByLocation] = useState([]);

  const [filteredData, setFilteredData] = useState(null);
  const [selectedDegree, setSelectedDegree] = useState(degree || "");
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [activeMobileFilter, setActiveMobileFilter] = useState("Degree");
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [activeFilterName, setActiveFilterName] = useState("");
  const [degreeSearchQuery, setDegreeSearchQuery] = useState("");
  const [specializationSearchQuery, setSpecializationSearchQuery] =
    useState("");
  const [stateSearchQuery, setStateSearchQuery] = useState("");
  const [citySearchQuery, setCitySearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState("");
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const username = useSelector((state) => state.auth.name);
  const email = useSelector((state) => state.auth.email);
  const [colleges, setColleges] = useState([]);
  const [formData, setFormData] = useState({
    name: username,
    email: email,
    contact_no: "",
    program: "",
  });
  const [isSticky, setIsSticky] = useState(false);
  const featuredColleges = useSelector(
    (state) => state.colleges.featuredColleges
  );
  const AllColleges = useSelector((state) => state.colleges.allColleges);
  const popularColleges = useSelector(
    (state) => state.colleges.popularColleges
  );

  const { degrees, cities, states, specializations, loading } = useSelector(
    (state) => state.masterData
  );

  const [programList, setProgramList] = useState(degrees);
  const [cityList, setCityList] = useState(cities);
  const [stateList, setStateList] = useState(states);
  const [subCoursesList, setSubCoursesList] = useState(specializations);

  const dispatch = useDispatch();

  const [isExpertHelpOpen, setIsExpertHelpOpen] = useState(false); // State to manage ExpertHelp modal

  const extractFiltersFromURL = (
    pathname,
    programList = [],
    subCoursesList = [],
    cityList = [],
    stateList = []
  ) => {
    const filters = {
      programs: [],
      specializations: [],
      cities: [],
      states: [],
    };

    // Regex to handle "/filter/..." and "/colleges-in-..."
    const filterMatch = pathname.match(
      /(?:\/filter\/)?([a-zA-Z0-9._()-]+(?:\([a-zA-Z0-9._\s-]+\))?)-colleges-in(?:-([a-zA-Z0-9._()-]+))?/i
    );

    const cityOnlyMatch = pathname.match(/\/colleges-in-([a-zA-Z0-9._()-]+)/i);

    if (filterMatch) {
      const combinedFilters = filterMatch[1]?.split("-") || [];
      const locationFilters = filterMatch[2]?.split("-") || []; // Location part of the URL

      const allPrograms = programList.map((program) =>
        program?.program_master_name?.toLowerCase().replace(/\s+/g, "_")
      );
      const allSpecializations = subCoursesList?.map((specialization) =>
        specialization?.sub_course_name?.toLowerCase().replace(/\s+/g, "_")
      );
      const allCities = cityList?.map((city) =>
        city?.name?.toLowerCase().replace(/\s+/g, "_")
      );
      const allStates = stateList?.map((state) =>
        state?.name?.toLowerCase().replace(/\s+/g, "_")
      );

      // Process combined filters (programs and specializations)
      combinedFilters.forEach((filter) => {
        if (allPrograms.includes(filter.toLowerCase())) {
          filters.programs.push(filter.replace(/_/g, " ").toLowerCase());
        } else if (allSpecializations.includes(filter.toLowerCase())) {
          filters.specializations.push(filter.replace(/_/g, " ").toLowerCase());
        }
      });

      // Process location filters (cities and states)
      locationFilters.forEach((filter) => {
        if (allCities.includes(filter.toLowerCase())) {
          filters.cities.push(filter.replace(/_/g, " ").toLowerCase());
        }
        if (allStates.includes(filter.toLowerCase())) {
          filters.states.push(filter.replace(/_/g, " ").toLowerCase());
        }
      });
    } else if (cityOnlyMatch) {
      // Handle the case for "/colleges-in-<city>"
      const locationFilter = cityOnlyMatch[1]?.split("-") || [];
      const allCities = cityList?.map((city) =>
        city?.name?.toLowerCase().replace(/\s+/g, "_")
      );
      const allStates = stateList.map((state) =>
        state?.name?.toLowerCase().replace(/\s+/g, "_")
      );

      locationFilter.forEach((filter) => {
        if (allCities.includes(filter.toLowerCase())) {
          filters.cities.push(filter.replace(/_/g, " ").toLowerCase());
        }
        if (allStates.includes(filter.toLowerCase())) {
          filters.states.push(filter.replace(/_/g, " ").toLowerCase());
        }
      });
    }

    const flattenedFilters = [
      [...filters.programs],
      [...filters.specializations],
      [...filters.cities],
      [...filters.states],
    ];

    return { filters, flattenedFilters };
  };

  function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 10); // Set sticky if scrolled past 85px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading to true at the start

        // Fetch all required data simultaneously
        await Promise.all([
          dispatch(fetchDegrees()),
          dispatch(fetchCities()),
          dispatch(fetchStates()),
          dispatch(fetchSpecializations()),
        ]);

        // Additional fetch logic (featured colleges, popular colleges, etc.)
        if (location.pathname.includes("featured")) {
          await dispatch(getFeaturedColleges());
          setColleges(featuredColleges);
        } else if (location.pathname.includes("popular")) {
          await dispatch(getPopularColleges());
          setColleges(popularColleges);
        } else if (
          location.pathname.includes("filter") ||
          location.pathname.includes("colleges-in-")
        ) {
          const { flattenedFilters } = extractFiltersFromURL(
            location.pathname,
            programList,
            subCoursesList,
            cityList,
            stateList
          );
          const filteredData = await fetchFilteredUniversities(
            flattenedFilters
          );
          setColleges(filteredData);
        } else {
          await dispatch(getAllColleges());
          setColleges(AllColleges);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load data.");
      } finally {
        setIsLoading(false); // Set loading to false when all data is fetched
      }
    };

    fetchData();
  }, [
    dispatch,
    location.pathname,
    programList,
    subCoursesList,
    cityList,
    stateList,
  ]);

  const handleCheckboxChange = (filterType, name) => {
    let updatedState;
    switch (filterType) {
      case "Degree":
        updatedState = selectedDegree.includes(name)
          ? selectedDegree.filter((item) => item !== name)
          : [...selectedDegree, name];
        setSelectedDegree(updatedState);
        break;
      case "Specialization":
        updatedState = selectedSpecialization.includes(name)
          ? selectedSpecialization.filter((item) => item !== name)
          : [...selectedSpecialization, name];
        setSelectedSpecialization(updatedState);
        break;
      case "City":
        updatedState = selectedCity.includes(name)
          ? selectedCity.filter((item) => item !== name)
          : [...selectedCity, name];
        setSelectedCity(updatedState);
        break;
      case "State":
        updatedState = selectedState.includes(name)
          ? selectedState.filter((item) => item !== name)
          : [...selectedState, name];
        setSelectedState(updatedState);
        break;
      default:
        break;
    }

    // Trigger the route update
    updateRoute(updatedState, filterType);
  };

  // Update route logic
  const updateRoute = (updatedFilters, filterType) => {
    const processFilters = (filters) =>
      filters.map((name) => name.toLowerCase().replace(/\s+/g, "_")).join("-");

    // Prepare filters for the URL
    const degreeFilters =
      filterType === "Degree" ? updatedFilters : selectedDegree;
    const specializationFilters =
      filterType === "Specialization" ? updatedFilters : selectedSpecialization;
    const cityFilters = filterType === "City" ? updatedFilters : selectedCity;
    const stateFilters =
      filterType === "State" ? updatedFilters : selectedState;

    const filters = [...degreeFilters, ...specializationFilters];
    const locations = [...cityFilters, ...stateFilters];

    let newPath = "";

    if (filters.length > 0) {
      if (locations.length > 0) {
        newPath = `/filter/${processFilters(
          filters
        )}-colleges-in-${processFilters(locations)}`;
      } else {
        newPath = `/filter/${processFilters(filters)}-colleges-in-india`;
      }
    } else if (locations.length > 0) {
      newPath = `/colleges-in-${processFilters(locations)}`;
    } else {
      newPath = "/colleges";
    }

    // Navigate to the new URL
    if (location.pathname !== newPath) {
      navigate(newPath);
    }
  };

  const handleClearAllFilters = (filter) => {
    switch (filter) {
      case "Degree":
        setSelectedDegree([]);
        setDegreeSearchQuery("");
        break;
      case "Specialization":
        setSelectedSpecialization([]);
        setSpecializationSearchQuery("");
        break;
      case "State":
        setSelectedState([]);
        setStateSearchQuery("");
        break;
      case "City":
        setSelectedCity([]);
        setCitySearchQuery("");
        break;
      default:
        // Clear all filters
        setSelectedDegree([]);
        setSelectedSpecialization([]);
        setSelectedState([]);
        setSelectedCity([]);
        setDegreeSearchQuery("");
        setSpecializationSearchQuery("");
        setStateSearchQuery("");
        setCitySearchQuery("");
        break;
    }

    // Trigger route update after clearing filters
    updateRoute([], filter);
  };

  // Dummy data for university info
  const infoRows = [
    { id: 1, img: RankIcon, text: "NIRF Rank #46" },
    { id: 2, img: univIcon, text: "Private University" },
    { id: 3, img: ratingIcon, text: "View Reviews" },
    { id: 4, img: locationIcon, text: "Punjab, India." },
  ];

  const handleWishlistClick = (id) => {
    const newWishlist = new Set(wishlist);
    if (newWishlist.has(id)) {
      newWishlist.delete(id);
    } else {
      newWishlist.add(id);
    }
    setWishlist(newWishlist);
  };

  const handleFilterClick = () => {
    navigate("/filter");
  };

  const getFilterData = (filterType) => {
    let data = [];
    switch (filterType) {
      case "Degree":
        data = programList.filter((item) =>
          item.program_master_name
            .toLowerCase()
            .includes(degreeSearchQuery.toLowerCase())
        );
        break;
      case "Specialization":
        data = subCoursesList.filter((item) =>
          item.sub_course_name
            .toLowerCase()
            .includes(specializationSearchQuery.toLowerCase())
        );
        break;
      case "State":
        data = stateList.filter((item) =>
          item.name.toLowerCase().includes(stateSearchQuery.toLowerCase())
        );
        break;
      case "City":
        data = cityList.filter((item) =>
          item.name.toLowerCase().includes(citySearchQuery.toLowerCase())
        );
        break;
      default:
        data = [];
        break;
    }

    return data.length > 0 ? data : null; // Return null if no data found
  };

  const mobileFilters = [
    "Filter 1",
    "Filter 2",
    "Filter 3",
    "Filter 4",
    "Filter 5",
    "Filter 6",
  ];

  const handleMobileFilterClick = () => {
    setIsMobileFilterOpen(true);
    setActiveMobileFilter("Degree");
  };

  const handleCloseMobileFilter = () => {
    setIsMobileFilterOpen(false);
  };

  const handleExploreClick = (college) => {
    const universityNameSlug = encodeURIComponent(
      college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    navigate(`/college/${universityNameSlug}`, {
      state: { college },
    });
  };

  // Add this function to clear all filters
  const clearAllFilters = () => {
    setSelectedDegree([]);
    setSelectedSpecialization([]);
    setSelectedState([]);
    setSelectedCity([]);
    setFilteredData(null);
    setActiveFilterName("");
    navigate("/colleges");
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await axios.post(`${Base_URL}/create/enquiry_form`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`, // Add token to authorization header
        },
        withCredentials: true, // Enable withCredentials
      });
      setFormData({
        name: username,
        email: email,
        contact_no: "",

        program: "",
      });
      setIsModalOpen(false);
      toast.success("Form submitted successfully!");
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const openModal = (collegeName) => {
    setSelectedCollege(collegeName);
    setFormData((prevData) => ({
      ...prevData,
      name: username,
      email: email,
    }));
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchCitiesByState = async (stateId) => {
    try {
      // Ensure stateId is passed as a string
      const response = await fetch(
        `${Base_URL}/get-join-where/?options={"table":"city","columns":["city.*","state.name as state_name"],"joins":[{"table":"state","on":"state.id = city.state_id","type":"INNER"}],"where":{"state.name":"${stateId}"}}`
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setCityList(data); // Update city list based on state selection
      } else {
        console.error("Expected an array of cities, but got:", data);
        setCityList([]); // Reset to an empty array if the response is not as expected
      }
    } catch (error) {
      console.error("Error fetching cities by state:", error);
    }
  };

  const fetchStatesByCity = async (cityName) => {
    try {
      // Fetch states related to the selected city
      const response = await fetch(
        `${Base_URL}/get-join-where/?options={"table":"state","columns":["state.*"],"joins":[{"table":"city","on":"city.state_id = state.id","type":"INNER"}],"where":{"city.name":"${cityName}"}}`
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setStateList(data); // Update the state list based on city selection
      } else {
        console.error("Expected an array of states, but got:", data);
        setStateList([]); // Reset to an empty array if the response is not as expected
      }
    } catch (error) {
      console.error("Error fetching states by city:", error);
    }
  };

  useEffect(() => {
    if (selectedCity.length > 0) {
      // If a city is selected, fetch states related to that city
      fetchStatesByCity(selectedCity[0]); // Assuming selectedCity is an array
    } else {
      // If no city is selected, fetch all states
      const fetchAllStates = async () => {
        try {
          const states = await MasterDataService.fetchStates();
          if (Array.isArray(states)) {
            setStateList(states);
          } else {
            console.error("Expected an array of states, but got:", states);
            setStateList([]); // Reset to an empty array if the response is not as expected
          }
        } catch (error) {
          console.error("Error fetching all states:", error);
        }
      };

      fetchAllStates();
    }
  }, [selectedCity[0]]);

  useEffect(() => {
    if (selectedState.length > 0) {
      // If a state is selected, fetch cities for that state
      fetchCitiesByState(selectedState[0]); // Assuming selectedState is an array
    } else {
      // If no state is selected, fetch all cities
      const fetchAllCities = async () => {
        try {
          const cities = await MasterDataService.fetchCities();
          if (Array.isArray(cities)) {
            setCityList(cities);
          } else {
            console.error("Expected an array of cities, but got:", cities);
            setCityList([]); // Reset to an empty array if the response is not as expected
          }
        } catch (error) {
          console.error("Error fetching all cities:", error);
        }
      };

      fetchAllCities();
    }
  }, [selectedState[0]]);

  const programName = location.state?.programName; // Get the program name from state

  // Logic to mark the checkbox based on programName
  const isChecked = (name) => {
    return programName && programName.toLowerCase() === name.toLowerCase();
  };

  const getMobileFilterData = (filterType) => {
    switch (filterType) {
      case "Degree":
        return programList.filter((item) =>
          item.program_master_name
            .toLowerCase()
            .includes(degreeSearchQuery.toLowerCase())
        );
      case "Specialization":
        return subCoursesList.filter((item) =>
          item.sub_course_name
            .toLowerCase()
            .includes(specializationSearchQuery.toLowerCase())
        );
      case "State":
        return stateList.filter((item) =>
          item.name.toLowerCase().includes(stateSearchQuery.toLowerCase())
        );
      case "City":
        return cityList.filter((item) =>
          item.name.toLowerCase().includes(citySearchQuery.toLowerCase())
        );
      default:
        return [];
    }
  };

  const handleDegreeClick = (degree) => {
    // Navigate to LandingUniversity with degree as a query param
    navigate(
      `/landinguniversity/${type}/${id}/${name}?degree=${encodeURIComponent(
        degree
      )}`
    );
  };

  // Function to open ExpertHelp modal
  const openExpertHelp = () => {
    setIsExpertHelpOpen(true);
  };

  // Function to close ExpertHelp modal
  const closeExpertHelp = () => {
    setIsExpertHelpOpen(false);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      navigate("/colleges");
    } else if (value === "featured") {
      navigate("/colleges/featured-colleges");
    }
  };

  if (isLoading) {
    return <HatCircleLoader />;
  }

  return (
    <>
      <div className='container mx-auto px-4 py-8 mt-20 flex flex-col lg:flex-row gap-6'>
        {/* Left Section - Filter Boxes */}
        <div className='w-full hidden md:block lg:w-1/4 font-dm-sans space-y-6'>
          {["Degree", "Specialization", "State", "City"].map((filter, idx) => (
            <div
              key={idx}
              className='p-3 border rounded-lg shadow-lg bg-[#FCFCFC]'
              style={{ height: "300px", overflow: "hidden" }}
            >
              <div className='flex justify-between items-center mb-4'>
                <h3 className='font-dm-sans text-sm font-bold'>{filter}</h3>
                <span
                  className='font-dm-sans text-[12px] text-[#808080] font-[700] cursor-pointer'
                  onClick={() => handleClearAllFilters(filter)}
                >
                  Clear All
                </span>
              </div>
              <input
                type='text'
                className='w-full h-10 p-2 border font-dm-sans text-[14px] rounded mb-2'
                placeholder={`Search ${filter}`}
                value={
                  filter === "Degree"
                    ? degreeSearchQuery
                    : filter === "Specialization"
                    ? specializationSearchQuery
                    : filter === "State"
                    ? stateSearchQuery
                    : citySearchQuery
                }
                onChange={(e) => {
                  filter === "Degree"
                    ? setDegreeSearchQuery(e.target.value)
                    : filter === "Specialization"
                    ? setSpecializationSearchQuery(e.target.value)
                    : filter === "State"
                    ? setStateSearchQuery(e.target.value)
                    : setCitySearchQuery(e.target.value);
                }}
              />
              <div
                className='overflow-y-auto'
                style={{ maxHeight: "200px", paddingBottom: "16px" }}
              >
                {getFilterData(filter) ? (
                  <ul className='space-y-2'>
                    {getFilterData(filter)?.map((item) => (
                      <li
                        key={item.id}
                        className='flex items-center p-[2px] text-[14px] font-dm-sans font-[700]'
                      >
                        <input
                          type='checkbox'
                          className='mr-2 ml-1'
                          checked={
                            filter === "Degree"
                              ? selectedDegree?.includes(
                                  item.program_master_name
                                )
                              : filter === "Specialization"
                              ? selectedSpecialization?.includes(
                                  item.sub_course_name
                                )
                              : filter === "State"
                              ? selectedState?.includes(item.name)
                              : selectedCity?.includes(item.name)
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              filter,
                              filter === "Degree"
                                ? item.program_master_name
                                : filter === "Specialization"
                                ? item.sub_course_name
                                : item.name
                            )
                          }
                        />
                        <span>
                          {filter === "Degree" && item.program_master_name}
                          {filter === "Specialization" && item.sub_course_name}
                          {filter === "State" && item.name}
                          {filter === "City" && item.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className='text-center text-gray-500'>No data found</p>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Right Section - University Cards */}
        <div className='w-full lg:w-3/4 flex flex-col gap-8 lg:p-2'>
          {/* Heading Section */}
          <div className='flex justify-center mb-0 lg:mb-2'>
            <h2 className='font-dm-sans text-2xl lg:text-3xl text-center mt-5 lg:mt-0'>
              {window.location.pathname === "/colleges/featured-colleges"
                ? "Featured Colleges in India"
                : window.location.pathname.includes("popular")
                ? "Popular Colleges in India"
                : selectedDegree?.length > 0
                ? `${
                    programList.find((p) => p.id === selectedDegree[0])
                      ?.program_master_name || "Colleges"
                  } in India`
                : selectedCity?.length > 0
                ? `Colleges in ${
                    cityList.find((city) => city.id === selectedCity[0])
                      ?.name || ""
                  }`
                : selectedState?.length > 0
                ? `Colleges in ${
                    stateList.find((state) => state.id === selectedState[0])
                      ?.name || ""
                  }`
                : "All Colleges in India"}
            </h2>
          </div>

          {/* Cards Section */}
          <div className='flex flex-col items-center gap-8'>
            <div className='flex flex-col gap-8 w-full lg:max-w-6xl'>
              {colleges?.filter((university) => university.is_active === 1)
                .length === 0 ? (
                <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)] w-full'>
                  <img src={nodata} alt='No data' className='w-40 h-40 mb-4' />
                  <p className='text-lg font-semibold text-gray-600'>
                    No colleges found.
                  </p>
                  <div
                    className='pt-2 text-[#468EFF] cursor-pointer'
                    onClick={clearAllFilters}
                  >
                    View all
                  </div>
                </div>
              ) : (
                colleges
                  ?.filter((university) => university.is_active === 1)
                  .map((university) => (
                    <div
                      key={university.id}
                      className='relative border w-full rounded-lg shadow-lg p-5 flex flex-col lg:flex-row md:items-center items-start'
                    >
                      {/* University Image Section */}
                      <div className='w-full lg:w-2/5 mb-4 lg:mb-0 flex justify-center'>
                        <img
                          src={`${Img_URL}/${university.pictures[0]}`}
                          alt={university.university_name}
                          className='w-full max-w-[300px] md:h-[200px] cursor-pointer object-cover rounded-lg'
                          onClick={() => handleExploreClick(university)}
                        />
                      </div>

                      {/* University Info Section */}
                      <div className='w-full lg:w-3/5 flex flex-col justify-between p-4 relative'>
                        <h3
                          className='font-dm-sans text-lg font-bold mb-2 cursor-pointer'
                          onClick={() => handleExploreClick(university)}
                        >
                          {university.university_name}
                        </h3>
                        <div className='grid grid-cols-2 gap-4 w-full'>
                          {/* University Info Section */}
                          <div className='space-y-2'>
                            {infoRows.map(
                              (row, index) =>
                                university.keywords[index]?.title &&
                                university.keywords[index]?.details && (
                                  <div
                                    key={row.id}
                                    className='flex items-center'
                                  >
                                    <img
                                      src={row.img}
                                      alt='icon'
                                      className='mr-2'
                                      style={{ width: "15px", height: "15px" }}
                                    />
                                    <span className='text-[14px] font-dm-sans font-[500] text-gray-600'>
                                      {university.keywords[index]?.title}:{" "}
                                      {university.keywords[index]?.details}
                                    </span>
                                  </div>
                                )
                            )}
                          </div>

                          {/* Additional Info Section */}
                          <div className='  border border-[#57CC0266] h-[130px]  md:h-auto mt-3 md:mt-0 rounded-[20px] flex flex-col items-center justify-center'>
                            <div className='w-[90px]  md:w-[90px] h-[60px] md:h-[60px] mb-2'>
                              <img
                                src={Hat}
                                alt='Hat'
                                className='w-full h-full object-cover'
                              />
                            </div>
                            <div className='text-center mb-2'>
                              <span className='font-dm-sans text-[19px] font-bold'>
                                {`${university.course_count || 35}+ Courses`}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* Additional Info Section */}

                        {/* Buttons Section */}
                        <div className='mt-6 flex flex-row lg:flex-row gap-12'>
                          <button
                            className='w-[130px] lg:w-[180px] h-[45px] bg-[#3ACA2E] text-white font-dm-sans md:text-[14px] text-[10px] font-bold rounded-[20px] flex items-center justify-center'
                            onClick={() =>
                              openModal(university.university_name)
                            }
                          >
                            Apply Now
                          </button>
                          <button
                            className='w-[130px] lg:w-[180px] h-[45px] bg-white border border-[#3ACA2E] text-black md:text-[14px] text-[10px] font-dm-sans font-bold rounded-[20px] flex items-center justify-center'
                            onClick={openExpertHelp} // Open ExpertHelp modal on click
                          >
                            Get Free Counselling
                          </button>
                        </div>
                      </div>

                      {/* Wishlist Heart Icon */}
                      <div className='absolute top-2 right-2'>
                        <button
                          onClick={() => handleWishlistClick(university.id)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className={`h-6 w-6 ${
                              wishlist.has(university.id)
                                ? "text-red-500"
                                : "text-gray-400"
                            }`}
                            fill={wishlist.has(university.id) ? "red" : "none"}
                            viewBox='0 0 24 24'
                            stroke={
                              wishlist.has(university.id)
                                ? "red"
                                : "currentColor"
                            }
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth={2}
                              d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
          {isModalOpen && <EnquiryForm onClose={closeModal} />}
          <ToastContainer />
        </div>
      </div>

      {/* Sticky Footer Division for Mobile */}

      <div
        className={`fixed flex justify-between items-center left-0 w-full bg-[#DCFFC4] border-b border-[#DADADA] lg:hidden p-4 transition-all duration-300 ${
          isSticky ? "top-0" : "top-[85px]"
        }`}
      >
        {/* Column with Filter Icon */}
        <div
          className='flex-2 bg-white rounded-[5rem] border-1 p-2 items-center justify-center '
          onClick={handleMobileFilterClick}
        >
          <img src={filter} alt='Filter' className='w-6 h-6 ' />
        </div>

        {/* Radio Buttons for Filter Options */}
        <div className='flex items-center  space-x-4 ml-4'>
          <div className='flex items-center'>
            <input
              type='radio'
              id='allColleges'
              name='filterOption'
              value='all'
              checked={location.pathname === "/colleges"} // Check based on URL
              onChange={handleRadioChange}
              className='mr-2 text-cmsgreen'
            />
            <label
              htmlFor='allColleges'
              className='text-sm font-dm-sans text-[#929292]'
            >
              All Colleges
            </label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              id='featuredColleges'
              name='filterOption'
              value='featured'
              checked={location.pathname === "/colleges/featured-colleges"} // Check based on URL
              onChange={handleRadioChange}
              className='mr-2 text-cmsgreen'
            />
            <label
              htmlFor='featuredColleges'
              className='text-sm font-dm-sans text-[#929292]'
            >
              Featured Colleges
            </label>
          </div>
        </div>
      </div>

      {/* Mobile Filter Modal */}
      {isMobileFilterOpen && (
        <div
          className={`fixed inset-0 z-[60] bg-white flex flex-col lg:hidden transform transition-transform duration-500 ease-in-out ${
            isMobileFilterOpen ? "translate-y-0" : "-translate-y-full"
          }`}
        >
          <div className='flex items-center justify-between p-4 border-b border-[#DADADA]'>
            <button
              onClick={handleCloseMobileFilter}
              className='text-[#929292]'
            >
              &#10005;
            </button>
          </div>

          <div className='flex flex-grow overflow-y-auto '>
            <div className='w-1/3 bg-white border-r border-b border-t border-[#929292] overflow-hidden'>
              <ul className='space-y-2 p-0 mt-5  text-[16px] font-[700] font-dm-sans text-[#000000]'>
                {["Degree", "Specialization", "State", "City"].map(
                  (filter, index) => (
                    <li
                      key={index}
                      onClick={() => setActiveMobileFilter(filter)}
                      className={`p-2 cursor-pointer w-full ${
                        activeMobileFilter === filter
                          ? "bg-cmsgreen text-white "
                          : "bg-white"
                      }`}
                      style={{ height: "38px" }}
                    >
                      {filter}
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className='w-2/3 bg-white overflow-y-auto p-4 ml-4'>
              <ul className='space-y-2 mt-5'>
                {getMobileFilterData(activeMobileFilter)?.map((item, idx) => (
                  <li
                    key={idx}
                    className='flex items-center p-2 text-[14px] font-dm-sans font-[700]'
                  >
                    <input
                      type='checkbox'
                      className='mr-2 text-cmsgreen'
                      checked={
                        activeMobileFilter === "Degree"
                          ? selectedDegree.includes(item.program_master_name)
                          : activeMobileFilter === "Specialization"
                          ? selectedSpecialization.includes(
                              item.sub_course_name
                            )
                          : activeMobileFilter === "State"
                          ? selectedState.includes(item.name)
                          : selectedCity.includes(item.name)
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          activeMobileFilter,
                          activeMobileFilter === "Degree"
                            ? item.program_master_name
                            : activeMobileFilter === "Specialization"
                            ? item.sub_course_name
                            : item.name
                        )
                      }
                    />
                    <span>
                      {activeMobileFilter === "Degree" &&
                        item.program_master_name}
                      {activeMobileFilter === "Specialization" &&
                        item.sub_course_name}
                      {activeMobileFilter === "State" && item.name}
                      {activeMobileFilter === "City" && item.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className='fixed bottom-0 left-0 w-full bg-cmsgreen border border-[#929292] '>
            <div className='flex items-center h-16'>
              <div
                className='flex items-center justify-center w-1/2'
                onClick={clearAllFilters}
              >
                <p className='text-[14px]   font-dm-sans font-[500] text-white'>
                  Clear All
                </p>
              </div>

              <div className='h-full border-l border-white'></div>

              <div
                className='flex items-center justify-center w-1/2'
                onClick={handleCloseMobileFilter}
              >
                <p className='text-[14px]  font-dm-sans font-[500] text-white'>
                  Apply Filters
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Render ExpertHelp modal */}
      {isExpertHelpOpen && (
        <ExpertHelp isOpen={isExpertHelpOpen} onClose={closeExpertHelp} />
      )}
      <ToastContainer />
    </>
  );
}

export default LandingUniversity;
