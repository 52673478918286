import React from 'react';
import { FaEnvelope, FaGlobe, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const facilitiesData = [
    { icon: '🏫', label: 'Library' },
    { icon: '🏋️‍♂️', label: 'Gym' },
    { icon: '🍽️', label: 'Cafeteria' },
    { icon: '💻', label: 'Computer Lab' },
    { icon: '🎨', label: 'Art Studio' },
    { icon: '⚽', label: 'Sports Field' },
    { icon: '🚲', label: 'Bicycle Parking' },
    { icon: '🛌', label: 'Dormitory' },
    { icon: '📖', label: 'Study Room' },
    { icon: '🖥️', label: 'IT Support' },
    { icon: '📞', label: 'Student Services' },
    { icon: '🚌', label: 'Shuttle Service' },
    { icon: '🧑‍🏫', label: 'Counseling Center' },
    { icon: '🕊️', label: 'Quiet Room' },
    { icon: '🎓', label: 'Career Center' },
    { icon: '🌱', label: 'Gardens' },
    { icon: '🏞️', label: 'Recreation Area' },
    { icon: '⚾', label: 'Baseball Field' },
    { icon: '🏊‍♂️', label: 'Swimming Pool' },
    { icon: '🏛️', label: 'Lecture Hall' },
    { icon: '🍏', label: 'Health Center' },
    { icon: '🧘‍♀️', label: 'Meditation Room' },
    { icon: '📡', label: 'Wifi Zones' },
    { icon: '🎤', label: 'Auditorium' },
    { icon: '🎾', label: 'Tennis Court' },
    { icon: '🚻', label: 'Restrooms' },
    { icon: '🛍️', label: 'Bookstore' },
    { icon: '🌐', label: 'International Office' },
    { icon: '🖼️', label: 'Gallery' },
    { icon: '🍵', label: 'Tea Room' },
    { icon: '💼', label: 'Job Board' },
    { icon: '📅', label: 'Event Hall' },
    { icon: '🔧', label: 'Maintenance' },
    { icon: '📰', label: 'Newspaper' },
    { icon: '🗣️', label: 'Language Lab' },
    { icon: '🎉', label: 'Clubhouse' },
    { icon: '🎧', label: 'Music Room' },
    { icon: '🏠', label: 'Visitor Center' },
    { icon: '🚴‍♂️', label: 'Track' },
    { icon: '🚑', label: 'First Aid' },
];



const CampusFacilities = ({collegeDetails}) => {
    const contactDetails = [
        { icon:<FaMapMarkerAlt /> ,  value: collegeDetails?.university_address || 'University Address not available'},
        { icon: <FaEnvelope />,  value: collegeDetails?.university_email || 'Email not available' },
        { icon:<FaPhoneAlt /> ,  value: collegeDetails?.university_ph_no || 'Phone Number not available' },
        { icon: <FaGlobe />,  value: collegeDetails?.website || 'Website not available' },
        { icon: <FaGlobe />,  value: collegeDetails?.pincode || 'Pincode not available' },
    ];
    
    return (
        <>
            {/* Campus Facilities Section */}
            <div className="max-w-6xl mx-auto p-4 border border-gray-300 rounded-lg shadow-lg mt-7">
                <h1 className="text-2xl font-bold text-center mb-4">Campus Facilities</h1>
                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 gap-2">
                    {facilitiesData.map((facility, index) => (
                        <div key={index} className="bg-white rounded border border-gray-200 p-1 flex items-center justify-center text-center h-16">
                            <span className="text-xl mr-1">{facility.icon}</span>
                            <span className="text-sm">{facility.label}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* College Contact Details Section with same margins */}
            <div className="max-w-6xl mx-auto mt-10 p-6 border border-gray-300 rounded-lg shadow-md">
    <h2 className="text-xl font-semibold mb-4">College Contact Details</h2>
    <div className="space-y-4">
        {contactDetails.map((contact, index) => (
            <div key={index} className="flex items-center">
                {/* Adjusted the icon alignment */}
                <span className="text-xl mx-7 text-[#3ACA2E] mr-3">{contact.icon}</span>
                
                {/* Flex removed from this div to align text with the icon */}
                <p className="text-gray-600 mx-7">{contact.value}</p>
            </div>
        ))}
    </div>
</div>

        </>
    );
};

export default CampusFacilities;
