import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Base_URL, Img_URL } from "../apiConfig";

const CareerTab = () => {
  const { stream_name } = useParams();
  const [programs, setPrograms] = useState([]);
  const [streamName, setStreamName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        
        const apiOptions = JSON.stringify({
          table: "program_master",
          columns: ["program_master.*", "streams.stream_name"],
          joins: [
            { table: "streams", on: "program_master.stream_id = streams.id", type: "INNER" },
          ],
          where: { "streams.stream_name": stream_name, "streams.status": "1" },
        });
  
        const response = await axios.get(`${Base_URL}/get-join-where/?options=${encodeURIComponent(apiOptions)}`);
        setPrograms(response.data || []);
        setStreamName(stream_name); // Update the stream name from the URL
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };
  
    fetchPrograms();
  }, [stream_name]);
  

  const handleButtonClick = (program) => {
    navigate(`/careerCourse/${encodeURIComponent(program.program_master_name)}`);
  };

  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex-grow p-6 mt-20 bg-[#F2FCEB]'>
        <div className='container mx-auto'>
          <h2 className='text-2xl font-bold mb-6 font-dm-sans mt-10 text-center'>
            {streamName}
          </h2>

          <div className='grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
            {programs.length > 0 ? (
              programs.map((program) => (
                <div
                  key={program.id}
                  className='border border-gray-300 bg-[#D9D9D9] shadow-md rounded-[10px] h-[250px] relative flex flex-col'
                >
                  <div className='h-[190px] w-full overflow-hidden rounded-t-[10px] bg-gray-200'>
                    {program.picture ? (
                      <img
                        src={`${Img_URL}/file${program.picture}`}
                        alt={program.program_master_name}
                        className='w-full h-full object-cover'
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "path/to/fallback/image.jpg";
                        }}
                      />
                    ) : (
                      <div className='w-full h-full flex items-center justify-center text-gray-400'>
                        No Image Available
                      </div>
                    )}
                  </div>
                  <div className='absolute bottom-0 w-full'>
                    <button
                      onClick={() => handleButtonClick(program)}
                      className='w-full h-[60px] bg-[#75E36B] text-white font-bold rounded-b-[10px] border border-gray-300 hover:bg-green-500 transition-all ease-out duration-300 text-sm text-center break-words px-2'
                    >
                      {program.program_master_name}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className='col-span-4 text-center'>
                No programs found for this stream.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerTab;
