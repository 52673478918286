import React, { useState, useEffect } from "react";
import "./qa.css";
import QACard from "./QACard/QACard";
import axios from "axios";
import { Base_URL } from "../../../apiConfig";

const QA = ({ collegeDetails, logoPath }) => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Fetch all FAQs
    axios
      .get(`${Base_URL}/get/faq/university/${collegeDetails.university_id}`)
      .then((response) => {
        // Filter FAQs based on the current university_id
        const faqData = response.data[0];

        setQuestions(faqData?.qna || []);
      })
      .catch((error) => {
        console.error("Error fetching FAQ data:", error);
      });
  }, [collegeDetails.university_id]);

  return (
    <div className='px-5 py-8 bg-[#DCFFC4]'>
      <div className='text-black text-base font-medium flex flex-col gap-5 mb-10'>
        <div className='flex items-center justify-center gap-3 mb-5'>
          <div className='md:h-[80px] md:w-[80px] h-[40px] w-[40px]'>
            <img
              src={logoPath}
              alt='college-logo'
              className='h-full w-full object-cover'
            />
          </div>
          <p className='md:text-3xl text-black md:font-semibold text-[20px] font-[700]'>
            {collegeDetails.university_name}
          </p>
        </div>
        <div className='flex items-center justify-center gap-3 mb-5'>
          <p className='font-bold text-xl'>
            Questions Asked On {collegeDetails.university_name}.
          </p>
        </div>
      </div>

      <div className='flex justify-center py-5'>
        <div className='px-10 bg-white w-[700px] rounded-xl pt-10 pb-5'>
          {questions.length > 0 ? (
            questions.map((qnaItem, index) => (
              <QACard
                key={index}
                number={index + 1}
                question={qnaItem.question}
                answer={qnaItem.answer}
              />
            ))
          ) : (
            <p className='text-center text-gray-600'>
              No questions available for this university.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default QA;
