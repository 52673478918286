//Production

// export const Base_URL= 'https://api.collegedwarka.com/api/'
// export const Img_URL = 'https://api.collegedwarka.com/api/uploads' 

//uat

export const Base_URL= process.env.REACT_APP_API_BASE_URL
export const Img_URL = process.env.REACT_APP_IMG_URL 


