import React from 'react';

const HeaderSection = ({ title, breadcrumb }) => {
  return (
    <div
      className="bg-gray-100 flex justify-between items-center px-6 py-4 rounded-t-lg"
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12.25px',
        fontWeight: 400,
        height: '50.625px',
        lineHeight: '18.375px',
        marginBottom: '30px',
        textAlign: 'start',
        width: '100%',
        borderRadius: '5px',
      }}
    >
      <div style={{ color: '#41B923', fontWeight: 'bold', fontSize: '17.5px', fontFamily: 'Poppins, sans-serif' }}>
        {title}
      </div>
      <div style={{ fontSize: '12.25px' }}>
        {breadcrumb.map((item, index) => (
          <span key={index} style={{ color: index === breadcrumb.length - 1 ? '#41B923' : 'rgb(105, 105, 105)' }}>
            {item}
            {index < breadcrumb.length - 1 && ' > '}
          </span>
        ))}
      </div>
    </div>
  );
};

export default HeaderSection;
