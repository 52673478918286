import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PortalImage from "../../assets/Authentication/portal.png";
import { loginUser } from "../../redux/slice/authSlice";

const SignUp = ({ toggleMode }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    userName: "",
    phoneNo: "",
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.auth);

  // Password validation rules
  const isPasswordValid =
    formData.password.length >= 8 &&
    /\d/.test(formData.password) &&
    /[!@#$%^&*(),.?":{}|<>]/.test(formData.password);

  const isPasswordMatch =
    formData.password &&
    formData.confirmPassword &&
    formData.password === formData.confirmPassword;

  // Phone number validation
  const isPhoneValid = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate all fields
  const validateForm = () => {
    if (!formData.userName.trim()) {
      setErrorMessage("Please enter a username");
      return false;
    }
    if (!isPhoneValid(formData.phoneNo)) {
      setErrorMessage("Please enter a valid 10-digit phone number");
      return false;
    }
    if (!formData.email.trim()) {
      setErrorMessage("Please enter an email address");
      return false;
    }
    if (!isPasswordValid) {
      setErrorMessage("Password must be at least 8 characters long, include a number and a special character");
      return false;
    }
    if (!isPasswordMatch) {
      setErrorMessage("Passwords do not match");
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!validateForm()) {
      // If validation fails, don't proceed with API call
      return;
    }

    setLoading(true);

    const data = {
      email: formData.email,
      password: formData.password,
      name: formData.userName,
      mobilenumber: formData.phoneNo,
      reg_type: 1,
    };

    dispatch(loginUser({ formData: data, actionType: 1 }));
  };

  // Effect to handle API error
  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      setLoading(false);
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className='md:flex contents h-full rounded-2xl overflow-hidden' style={{minHeight:'inherit'}}>
      {/* Left Section - Image */}
      <div className='hidden md:flex md:w-[50%] bg-loginbg bg-cover flex-col justify-center items-center p-10'>
        <div className='flex flex-col mb-10 self-start'>
          <h1 className='font-bold text-[40px] text-black'>Welcome to</h1>
          <p className='text-[40px] font-light leading-[20px] tracking-[0.5px] m-0 text-black z-15'>
            Student Portal
          </p>
          <p className='text-xs font-light mt-4 ml-1 text-black z-15'>
            Sign up to explore your interests
          </p>
        </div>
        <div className='z-10'>
          <img
            src={PortalImage}
            alt='Portal'
            className='w-[95%] h-[95%] object-contain'
          />
        </div>
      </div>

      {/* Right Section - Sign Up Form */}
      <div className='w-full md:w-[50%] bg-white flex flex-col justify-center px-6 py-4 overflow-y-auto'>
        <form onSubmit={handleSubmit} className='w-full max-w-sm mx-auto'>
          <h2 className='text-3xl font-bold mb-4'>Sign Up</h2>
          <div className='space-y-3'>
            <div>
              <label
                htmlFor='userName'
                className='block text-xs font-normal mb-1'
              >
                User Name
              </label>
              <input
                id='userName'
                type='text'
                name='userName'
                placeholder='User Name'
                value={formData.userName}
                onChange={handleInputChange}
                className='w-full p-2 text-sm border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-green-400'
              />
            </div>
            <div>
              <label
                htmlFor='phoneNo'
                className='block text-xs font-normal mb-1'
              >
                Mobile Number
              </label>
              <input
                id='phoneNo'
                type='tel'
                name='phoneNo'
                placeholder='Mobile Number'
                value={formData.phoneNo}
                onChange={handleInputChange}
                className='w-full p-2 text-sm border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-green-400'
              />
            </div>
            <div>
              <label htmlFor='email' className='block text-xs font-normal mb-1'>
                Email
              </label>
              <input
                id='email'
                type='email'
                name='email'
                placeholder='Email'
                value={formData.email}
                onChange={handleInputChange}
                className='w-full p-2 text-sm border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-green-400'
              />
            </div>
            <div>
              <label
                htmlFor='password'
                className='block text-xs font-normal mb-1'
              >
                Password
              </label>
              <input
                id='password'
                type='password'
                name='password'
                placeholder='Password'
                value={formData.password}
                onChange={handleInputChange}
                className='w-full p-2 text-sm border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-green-400'
              />
            </div>
            <div>
              <label
                htmlFor='confirmPassword'
                className='block text-xs font-normal mb-1'
              >
                Confirm Password
              </label>
              <input
                id='confirmPassword'
                type='password'
                name='confirmPassword'
                placeholder='Confirm Password'
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className='w-full p-2 text-sm border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-green-400'
              />
            </div>
          </div>

        

          <div className='mt-4'>
            {/* Submit Button with Spinner */}
            <button
              type='submit'
              className='w-full px-4 py-2 bg-buttonbg text-[#000] rounded-md font-poppins flex items-center justify-center'
              disabled={loading}
            >
              {loading ? (
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Signing Up...
                </div>
              ) : (
                "Sign Up"
              )}
            </button>

            {/* Error Message */}
            {errorMessage && (
              <div className='mt-2 text-red-500 text-sm text-center'>
                {errorMessage}
              </div>
            )}
          </div>

          <p className='mt-4 text-center text-xs text-gray-600'>
            Already have an account?{" "}
            <button
              type='button'
              onClick={toggleMode}
              className='text-hovergreen hover:underline'
            >
              Sign In
            </button>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
