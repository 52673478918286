import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'; // To access Redux state for token

const AddUniversityCity = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [cities, setCities] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth); // Get token from Redux

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/city`);
        const cityOptions = response.data.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCities(cityOptions);
      } catch (error) {
        console.error('Failed to fetch cities:', error);
        toast.error('Failed to load city options.');
      }
    };

    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`);
        const universityOptions = response.data.map((university) => ({
          value: university.university_id,
          label: university.university_name,
        }));
        setUniversities(universityOptions);
      } catch (error) {
        console.error('Failed to fetch universities:', error);
        toast.error('Failed to load university options.');
      }
    };

    fetchCity();
    fetchUniversities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUniversityChange = (selectedOption) => {
    setSelectedUniversity(selectedOption);
  };

  const handleCityChange = (selectedOption) => {
    setFormData({ ...formData, city_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const newUniversityCity = { ...formData, university_id: selectedUniversity.value };
      await axios.post(`${Base_URL}/create/city_mapping`, newUniversityCity, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`, // Include authorization token
        },
      });
      toast.success('University City added successfully!');
      // Navigate or clear form as needed
    } catch (error) {
      toast.error('Failed to add university city.');
      console.error('Failed to add university city:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="university_id" className="mb-2 font-medium text-sm">Select University</label>
                <Select
                  options={universities}
                  value={selectedUniversity}
                  onChange={handleUniversityChange}
                  placeholder="Select a university"
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="city_id" className="mb-2 font-medium text-sm">Select City</label>
                <Select
                  options={cities}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="basic-single"
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
              {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Add University City'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUniversityCity;
