import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // Optional: Add an event listener for when the window is fully loaded
    window.onload = () => window.scrollTo(0, 0);
    return () => window.removeEventListener('load', window.scrollTo(0, 0));
  }, [pathname]);
  

  return children;
};

export default ScrollToTop;
