import { Parser } from "htmlparser2";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourseListService from "../../api/services/CourseListService";
import "../../styles/college.css";

const CourseDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { courseName } = location.state || {};
  const { name } = useParams();

  const [activeTab, setActiveTab] = useState("overview");
  const [courseDetails, setCourseDetails] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const subheaderRef = useRef(null);

  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.querySelector("header");
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    updateHeaderHeight();

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateHeaderHeight);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, [headerHeight]);

  const fetchCourseDetails = async () => {
    const options = {
      table: "course_master_details",
      columns: [
        "course_master_details.*",
        "course_master.course_master_name",
        "registration.name as user_name",
      ],
      joins: [
        {
          table: "course_master",
          on: "course_master.id = course_master_details.course_master_id ",
          type: "INNER",
        },
        {
          table: "registration",
          on: "registration.registration_id = course_master_details.created_by ",
          type: "INNER",
        },
      ],
      where: {
        "course_master_details.status": "1",
        "course_master.course_master_name": name,
      },
    };
    try {
      const details = await CourseListService.fetchCourseDetailsWithJoin(
        options
      );
      setCourseDetails(details[0]); // Assuming the API returns an array
    } catch (error) {
      toast.error("Failed to fetch course details");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCourseDetails();
  }, [name]);

  const parseHtml = (html) => {
    const result = [];
    let currentElement = null;
    const elementStack = [];

    const parser = new Parser(
      {
        onopentag(name, attributes) {
          const newElement = { name, attributes, children: [] };
          if (currentElement) {
            currentElement.children.push(newElement);
            elementStack.push(currentElement);
          } else {
            result.push(newElement);
          }
          currentElement = newElement;
        },
        ontext(text) {
          if (currentElement) {
            currentElement.children.push(text);
          } else {
            result.push(text);
          }
        },
        onclosetag(tagname) {
          if (elementStack.length > 0) {
            currentElement = elementStack.pop();
          } else {
            currentElement = null;
          }
        },
      },
      { decodeEntities: true }
    );

    parser.write(html);
    parser.end();

    return result;
  };

  const renderContent = (content) => {
    return content.map((item, index) => {
      if (typeof item === "string") {
        return item;
      }

      const Tag = item.name;
      const isVoidElement = ["br", "img", "input", "hr"].includes(Tag);
      const props = {
        ...item.attributes,
        key: index,
        className:
          Tag === "table"
            ? "min-w-full border-collapse border border-gray-300 my-4"
            : "",
      };

      if (Tag === "tr") {
        props.className = index % 2 === 0 ? "bg-gray-100" : "bg-white";
      }

      if (Tag === "td" || Tag === "th") {
        props.className = "border border-gray-300 px-4 py-2";
      }

      return isVoidElement
        ? React.createElement(Tag, props)
        : React.createElement(
            Tag,
            props,
            item.children && renderContent(item.children)
          );
    });
  };

  const renderTabContent = () => {
    if (!courseDetails) {
      return <p>Loading...</p>;
    }

    const content =
      activeTab === "overview"
        ? courseDetails.overview
        : activeTab === "jobs"
        ? courseDetails.jobs
        : activeTab === "salary"
        ? courseDetails.salary
        : "";

    if (!content) {
      return <p>No content available</p>;
    }

    const parsedHtml = parseHtml(content);
    return renderContent(parsedHtml);
  };

  return (
    <>
      <div>
        <section className='py-8 mt-[40px] border-b-2 bg-[rgba(0,0,0,0.5)]  border-gray-200 w-full'>
          <div className='container mx-auto px-4 text-white'>
            <h2 className='text-3xl font-semibold'>
              {courseName || courseDetails?.course_master_name}
            </h2>
          </div>
        </section>

        <div
          id='subheader'
          ref={subheaderRef}
          className={`subheader top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header bg-white shadow-md md:justify-center`}
          style={{
            position: isSticky ? "fixed" : "static",
            top: isSticky ? "0px" : "auto",
            width: "100%",
            zIndex: 3,
          }}
        >
          {["Overview", "Jobs", "Salary", "View all Courses"].map((tab) => (
            <button
              key={tab}
              onClick={() => {
                if (tab === "View all Courses") {
                  navigate("/courses/all");
                } else {
                  setActiveTab(tab.toLowerCase());
                }
              }}
              className={`py-2 px-4 ${
                activeTab === tab.toLowerCase()
                  ? "text-[#57CC02] border-b-2 border-[#57CC02]"
                  : "text-black"
              } font-dm-sans text-base font-bold transition-colors duration-200 hover:text-[#57CC02]`}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className='container mx-auto px-4 mt-6'>
          <div
            className='bg-[#FAFAFA] shadow-md rounded-lg p-8 max-w-[1000px] mx-auto'
            style={{
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <div className='prose max-w-none'>{renderTabContent()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetailPage;
