import axios from "axios";
import debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../apiConfig";
import Hat from "../assets/landing/Hat.png";
import home_top from "../assets/landing/home_top.webp";
import Authentication from "../components/Authentication/Authentication";
import ExpertHelp from "../components/Forms/ExpertHelp";
import CollegeBy from "../components/Landing/CollegeBy/CollegeBy";
import Colleges from "../components/Landing/Colleges/Colleges";
import LandingNewsEvents from "../components/Landing/LandingNewsEvents/LandingNewsEvents";
import Newsletter from "../components/Landing/Newsletter/Newsletter";
import Process from "../components/Landing/Process/Process";
import Reviews from "../components/Landing/Reviews/Reviews";
import TopColleges from "../components/Landing/TopColleges/TopColleges";
import "../styles/landing.css";
import StudyGoals from "./StudyGoals";
const MIN_LENGTH = 3;
// Make sure to set the app element for accessibility
Modal.setAppElement("#root");

const Landing = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [authMode, setAuthMode] = useState("login"); // 'login' or 'signup'
  const [placeholder, setPlaceholder] = useState(" College");
  const placeholders = [" College", " Exam", " Course"];
  const [universityName, setUniversityName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const { registration_id, token, name } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);

  const navigate = useNavigate();
  const newsletterRef = useRef(null);

  // Define debounced function for fetching universities
  const debouncedFetchUniversities = useRef(
    debounce(async (university_name) => {
      // Only proceed if the search term has enough characters (MIN_LENGTH)
      if (university_name.trim() && university_name.length >= MIN_LENGTH) {
        try {
          const response = await axios.get(
            `${Base_URL}/search/${university_name}`
          );
          if (response.data && Array.isArray(response.data)) {
            // Filter colleges that start with the search term (case-insensitive)
            const filteredResults = response.data.filter((college) =>
              college.university_name
                .toLowerCase()
                .startsWith(university_name.toLowerCase())
            );
            setSearchResults(filteredResults); // Set filtered results
          } else {
            setSearchResults([]); // If no results, clear the search
          }
        } catch (error) {
          console.error("Error fetching search results:", error);
          setSearchResults([]); // In case of error, clear results
        }
      } else {
        // Clear results when input is empty or too short
        setSearchResults([]);
      }
    }, 500) // 500ms debounce delay
  ).current;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Auto close modal when user has registration_id
  useEffect(() => {
    if (registration_id) {
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }, [registration_id]);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      index = (index + 1) % placeholders.length;
      setPlaceholder(placeholders[index]);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setUniversityName(value);
    setSelectedUniversity(null);

    if (value.trim()) {
      setDropdownVisible(true);
      debouncedFetchUniversities(value); // Call debounced function
    } else {
      setDropdownVisible(false);
      setSearchResults([]);
    }
  };

  const handleSearchSelect = async (college) => {
    try {
      const universityId = college.university_id;
      const universityNameSlug = encodeURIComponent(
        college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
      );

      // Hide dropdown and set selected university
      setDropdownVisible(false);
      setUniversityName(college.university_name);
      setSelectedUniversity(college);

      // Fetch complete college details and pictures in parallel
      const [detailsResponse, picturesResponse] = await Promise.all([
        axios.get(`${Base_URL}/universities/${universityId}`),
      ]);

      // Combine college details with pictures
      const completeCollegeDetails = { ...detailsResponse.data };

      // Navigate to college page with complete details
      navigate(`/college/${universityNameSlug}`, {});
    } catch (error) {
      console.error("Error fetching college details:", error);
    }
  };

  const handleSearchSubmit = async (e) => {
    if (e.key === "Enter") {
      try {
        let matchedCollege = null;

        // If a college is already selected from dropdown
        if (selectedUniversity) {
          matchedCollege = selectedUniversity;
        }
        // If user typed a name but didn't select from dropdown
        else if (universityName.trim()) {
          // First try to find an exact match in current search results
          matchedCollege = searchResults.find(
            (college) =>
              college.university_name.toLowerCase() ===
              universityName.toLowerCase()
          );

          // If no match in current results, make a new API call
          if (!matchedCollege) {
            const response = await axios.get(
              `${Base_URL}/search/${universityName}`
            );
            if (response.data && Array.isArray(response.data)) {
              matchedCollege = response.data.find(
                (college) =>
                  college.university_name.toLowerCase() ===
                  universityName.toLowerCase()
              );
            }
          }
        }

        if (matchedCollege) {
          const universityId = matchedCollege.university_id;
          const universityNameSlug = encodeURIComponent(
            matchedCollege.university_name
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "-")
          );

          // Fetch complete college details and pictures before navigation
          const [detailsResponse, picturesResponse] = await Promise.all([
            axios.get(`${Base_URL}/universities/${universityId}`),
          ]);

          const completeCollegeDetails = {
            ...detailsResponse.data,
          };

          // Navigate with complete details
          navigate(`/college/${universityNameSlug}`, {});
        } else {
          setUniversityName(""); // Clear input if no match found
        }
      } catch (error) {
        console.error("Error during search:", error);
        setUniversityName(""); // Clear input on error
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      border: "none",
      borderRadius: "10px", // Increased border radius
      overflow: "hidden",
      width: "60%",
      maxWidth: "1000px", // Increased max-width
      height: "auto", // Added max-height
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
  };

  useEffect(() => {});

  // Add this useEffect for scroll to top functionality
  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo(0, 0);

    // Add event listener for popstate (back/forward navigation)
    const handlePopState = () => {
      window.scrollTo(0, 0);
    };

    // Add event listener for page refresh
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    // Add event listeners
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Authentication Modal'
      >
        <Authentication initialMode={authMode} onClose={closeModal} />
      </Modal>

      {/* Top Section */}
      <div className='relative px-4 pb-10 pt-[150px] md:px-8 md:pt-[200px]'>
        <div className='absolute inset-0 z-[-1]'>
          <img
            src={home_top}
            alt='cover-image'
            className='h-full w-full object-cover'
          />
          <div className='absolute inset-0 bg-black opacity-30' />
        </div>

        <div className='relative flex flex-col items-center text-center'>
          <h1 className='text-2xl md:text-4xl text-white font-semibold mb-8 md:mb-12'>
            Your Journey our Guidance: From College Admission to Job Success
            through College Dwarka
          </h1>

          {/* Search Container */}
          <div className='relative w-full max-w-[300px] md:max-w-[400px] mb-8 md:mb-12'>
            <div className='relative flex justify-center items-center bg-white rounded-3xl px-2 py-2 md:px-6 md:py-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6 absolute left-6 text-gray-400'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
                />
              </svg>
              <input
                type='text'
                placeholder={`Find your '${placeholder}`}
                className='search-input w-[240px] md:w-[300px] h-[25px] border-0 pr-4 pl-10 rounded-full' // Added pl-10 for left padding
                value={universityName}
                onChange={handleSearchChange}
                onKeyDown={handleSearchSubmit}
              />

              <img
                src={Hat}
                alt='hat'
                className='w-16 h-16 absolute -right-4 -top-4 md:w-20 md:h-20 md:-right-6 md:-top-6 z-1'
              />
            </div>

            {/* Dropdown */}
            {dropdownVisible && searchResults.length > 0 && (
              <div className='absolute w-full bg-white shadow-lg rounded-lg overflow-hidden z-50 border border-gray-100 border-t-0'>
                <ul
                  className={`py-1 ${
                    searchResults.length > 3
                      ? "max-h-[150px] overflow-y-auto custom-scrollbar"
                      : ""
                  }`}
                >
                  {searchResults.map((college) => (
                    <li
                      key={college.university_id}
                      className='px-4 py-2.5 hover:bg-gray-50 cursor-pointer transition-colors duration-150 border-b border-gray-50 last:border-b-0'
                      onClick={() => handleSearchSelect(college)}
                    >
                      <div className='flex items-center gap-2'>
                        <div className='w-4 h-6 flex-shrink-0 bg-white rounded-full flex items-center justify-center'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-3 w-3 text-blue-500'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                          >
                            <path d='M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z' />
                          </svg>
                        </div>
                        <p className='text-sm font-medium text-gray-800 truncate'>
                          {college.university_name}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <p className='text-white text-lg md:text-xl font-medium mb-8 md:mb-10'>
            Navigate Your Path to Success: Discover Top Colleges and Make
            Informed Choices!
          </p>
          <div className='flex justify-center items-center gap-4 flex-wrap px-2'>
            {/* Button 1 */}
            <button
              className='h-10 md:h-12 w-[150px] md:w-[180px] text-white font-semibold text-sm md:text-base border-2 border-white rounded-full bg-[#3ACA2E] overflow-hidden whitespace-nowrap'
              onClick={openModal}
            >
              Get Started
            </button>

            {/* Button 2 */}
            <button
              className='h-10 md:h-12 w-[150px] md:w-[180px] text-sm md:text-base text-white font-semibold border-2 border-[#3ACA2E] rounded-full bg-[#FFFFFF4D] overflow-hidden whitespace-nowrap'
              onClick={openModal}
            >
              Expert Help
            </button>
          </div>
        </div>
      </div>

      {/* Other sections */}
      <div className='overflow-hidden'>
        <Process />
        <TopColleges />
        <CollegeBy />
        {/* <LandingVideo newsletterRef={newsletterRef} /> */}
        <StudyGoals />
        <Reviews />
        <Colleges />
        <LandingNewsEvents />
        <div ref={newsletterRef}>
          <Newsletter />
        </div>
      </div>

      {/* Add this CSS either in your stylesheet or in a style tag */}
      <style>
        {`
          .custom-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #CBD5E0 #EDF2F7;
          }
          
          .custom-scrollbar::-webkit-scrollbar {
            width: 4px;
          }
          
          .custom-scrollbar::-webkit-scrollbar-track {
            background: #EDF2F7;
            border-radius: 2px;
          }
          
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #CBD5E0;
            border-radius: 2px;
          }
        `}
      </style>
      {isModalOpen && <ExpertHelp isOpen={isModalOpen} onClose={closeModal} />}
    </div>
  );
};

export default Landing;
