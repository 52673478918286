import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import { useSelector } from "react-redux"; // Import useSelector

const EditLevels = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const levels = location.state;

  const [formData, setFormData] = useState({
    level_name: levels?.level_name || "",
  });
  const [loading, setLoading] = useState(false); // Track loading state

  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    try {
      await axios.put(
        `${Base_URL}/update/level_master/${levels.id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`, // Add token to authorization header
          },
          withCredentials: true, // Enable withCredentials
        }
      );
      toast.success("Level updated successfully!");
      setTimeout(() => {
        navigate("/admin/exams/allLevel");
      }, 2000);
    } catch (error) {
      toast.error("Failed to update level.");
      console.error(
        "Failed to update level:",
        error.response?.data || error.message
      );
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 h-screen'>
        <HeaderTitle mainTitle='Exams' subTitle='Edit Level' />
        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label htmlFor='level' className='mb-2 font-medium text-sm'>
                  Level Name
                </label>
                <input
                  type='text'
                  id='level'
                  name='level_name'
                  value={formData.level_name}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            >
              {loading ? (
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                "Update Level"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLevels;
