import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchNewsAnnouncements } from "../../api/services/NewsEventService";


// Async thunk to fetch news announcements
export const getNewsAnnouncements = createAsyncThunk(
  "newsEvents/fetchNews",
  async (_, { rejectWithValue }) => {
    try {
      const data =await fetchNewsAnnouncements();
      return data ;// Call the service using ApiClient
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch news announcements.");
    }
  }
);


const newsEventsSlice = createSlice({
  name: "newsEvents",
  initialState: {
    news: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // News Announcements
      .addCase(getNewsAnnouncements.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNewsAnnouncements.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload;
      })
      .addCase(getNewsAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Upcoming Events
  
  },
});

export default newsEventsSlice.reducer;
