import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import confirm from '../../../assets/landing/confirm.png';
import fill from '../../../assets/landing/fill.png';
import pay from '../../../assets/landing/pay.png';
import register from '../../../assets/landing/register.png';
import select from '../../../assets/landing/select.png';
import upload from '../../../assets/landing/upload.png';
import './process.css';

const Process = () => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStep((prevStep) => (prevStep < 6 ? prevStep + 1 : 0));
        }, 2000);

        return () => clearInterval(timer);
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const cards = [
        { title: "Discover Your Ideal Career", description: "Find the career path that perfectly aligns with your skills and interests." },
        { title: "Connect with Admission Advisors", description: "Get personalized advice from experts to make informed decisions." },
        { title: "Centralized Application Tracking", description: "Easily monitor the status of all your applications in one dashboard." },
        { title: "Earn Exciting Rewards", description: "Earn rewards and incentives as you progress through the application process." },
        { title: "Effortless Application Process", description: "Submit your applications quickly and effortlessly with our streamlined process." },
        { title: "Trusted College Listings", description: "Explore a curated list of trusted and verified colleges." },
    ];

    const groupedCards = [];
    for (let i = 0; i < cards.length; i += 2) {
        groupedCards.push(cards.slice(i, i + 2));
    }

    return (
        <div className="overflow-hidden">
  <div className="px-4 py-6 w-full md:px-6 md:py-8 lg:px-8 lg:py-10">
    <p className="text-[28px] md:text-[36px] font-sans font-[700] mb-12 md:mb-8 text-center">
      Apply to colleges quickly and easily through our streamlined platform.
    </p>
    <p className="text-base md:text-lg lg:text-xl text-center font-medium font-sans mb-12 md:mb-8">
      Navigate the application process effortlessly with our comprehensive guide, ensuring you meet every requirement with ease.
    </p>
    <div className="process-step-container flex flex-wrap items-center justify-center font-sans gap-2 mb-5">
      {[{ title: "Register", img: register }, { title: "Select Course", img: select }, { title: "Fill Application", img: fill }, { title: "Upload Documents", img: upload }, { title: "Make Payment", img: pay }, { title: "Admission Confirmed", img: confirm }].map((step, index) => (
        <React.Fragment key={index}>
          <div className={`flex items-center gap-2 border-2 process-step step-${index + 1} ${activeStep >= index + 1 ? 'filled' : ''}`}>
            <div className="text-container">
              <p className="text-base font-semibold">{step.title}</p>
              <div className="w-[15px] h-[15px]">
                <img src={step.img} alt={step.title} className="w-full h-full" />
              </div>
            </div>
          </div>
          {index < 5 && (
            <div className={`arrow-container ${activeStep > index ? 'show-arrow' : ''}`}>
              <div className="arrow"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>

    {/* Desktop Layout */}
    <div className="hidden lg:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 justify-center">
      {cards.map((card, index) => (
        <div
          key={index}
          className="px-4 py-5 bg-[#DBFDC3] rounded-xl"
          style={{ boxShadow: '0px 4px 8px rgba(0.2, 0.2, 0.2, 0.2)' }}
        >
          <p className="text-center text-base font-medium sm:text-left">{card.title}</p>
          <p className="text-center text-base font-medium sm:text-left">{card.description}</p>
        </div>
      ))}
    </div>

    {/* Mobile and Tablet Layout */}
    <div className="block lg:hidden">
      <Slider {...sliderSettings}>
        {groupedCards.map((group, index) => (
          <div key={index} className="flex flex-col items-center gap-4">
            {group.map((card, idx) => (
              <div
                key={idx}
                className="w-[90%] max-w-[340px] h-[130px] px-4 py-5 bg-[#DBFDC3] rounded-xl"
                style={{
                  margin: "20px auto",
                  boxShadow: "0px 4px 8px rgba(0.2, 0.2, 0.2, 0.2)",
                }}
              >
                <p className="text-center text-base font-medium">{card.title}</p>
                <p className="text-center text-sm font-medium">{card.description}</p>
              </div>
            ))}
          </div>
        ))}
      </Slider>
    </div>
  </div>
</div>

    );
};

export default Process;
