import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import HeaderTitle from '../../dashboard/HeaderTitle';


const AddExamLevel = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      level_name: '',
      // created_by: 1,
      
    });
    
    const location = useLocation();
    const { mainTitle, subTitle } = location.state || {};
    const [loading, setLoading] = useState(false); // Track loading state
    const { registration_id, token,name } = useSelector((state) => state.auth);
  
  

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
          const newLevel = { ...formData };
          await axios.post(`${Base_URL}/create/level_master`, newLevel, {
            headers: {
              'Content-Type': 'application/json',
               'authorization': `Bearer ${token}`
            },
            withCredentials: true // This allows cookies to be sent with the request
          });
          toast.success('Level added successfully!');
        } catch (error) {
          toast.error('Failed to add level');
          console.error('Failed to add level', error.response?.data || error.message);
          setLoading(false)
        }
        finally {
          setLoading(false); // Reset loading state after submission
        }
      };
  return (
    <div>
    <ToastContainer />
    <div className="bg-gray-100 p-6 h-screen">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label htmlFor="level_name" className="mb-2 font-medium text-sm">Create Exam Level</label>
              <input
                type="text"
                id="level_name"
                name="level_name"
                value={formData.level_name}
                onChange={handleInputChange}
                className="border border-gray-300 p-2 rounded"
                required
              />
            </div>

          </div>

          <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
          {loading ? ( // Show spinner when loading
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Add Level'
              )}
          </button>
        </form>
      </div>
    </div>
  </div>
  )
}

export default AddExamLevel