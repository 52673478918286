import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import { ReactComponent as Logo } from '../../../assets/landing/Logo_Green.svg';
import { loginSuccess } from '../../../redux/slice/collegeAuth';

const Authentication = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // To toggle password visibility
  const [loading, setLoading] = useState(false); // Track loading state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted

    try {
      const response = await fetch('https://api.collegedwarka.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Dispatch loginSuccess to save the whole response in Redux
        dispatch(loginSuccess(data)); // Saving the full response data

        // Show success toast
        toast.success('Login successful! Redirecting to dashboard...');

        // Optionally save the token or other important data in localStorage
        localStorage.setItem('authToken', data.token);

        // Navigate to the dashboard after a short delay
        setTimeout(() => {
          setLoading(false); // Stop loading before navigating
          navigate('/dashboard/analytics');
        }, 2000); // 2-second delay for user experience
      } else {
        // Show error toast
        toast.error(data.message || 'Login failed. Please try again.');
        setLoading(false); // Stop loading on error
      }
    } catch (error) {
      console.error('Error logging in:', error);
      // Show error toast
      toast.error('An error occurred. Please try again later.');
      setLoading(false); // Stop loading on error
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <ToastContainer /> {/* React Toastify container */}
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center items-center">
          <Logo className="w-24 h-24 md:w-32 md:h-32 lg:w-1/2 lg:h-36" />
        </div>
        <div className="text-center mb-6">
          <p className="text-gray-600">Sign in to your account</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              placeholder="demo@example.com"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 relative">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Password
            </label>
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between text and password
              placeholder="******"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {/* Eye Icon for Password Toggle */}
            <div
              className="absolute top-10 right-0 pr-3 flex items-center text-gray-500 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              className="h-4 w-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
            />
            <label className="ml-2 block text-gray-900">
              Remember my preference
            </label>
          </div>
          <div className="mb-6">
            <button
              type="submit"
              className="w-full bg-[#3ACA2E] text-white py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  {/* Spinner icon (using Font Awesome or any other method) */}
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Logging in...
                </div>
              ) : (
                'Sign In'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Authentication;