import React, { useState } from "react";
import { MdDashboard, MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Polygon } from "../../../assets/header/Polygon.svg";
import { logout } from "../../../redux/slice/authSlice";

const ProfileDropDown = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const items = [
    {
      name: "Dashboard",
      icon: <MdDashboard />,
      action: () => navigate("/admin"),
    },
    {
      name: isLoggingOut ? "Logging out..." : "Logout",
      icon:  <MdLogout />,
      action: () => {
        setIsLoggingOut(true);
        setTimeout(() => {
          dispatch(logout());
        navigate("/admin")
        }, 1000);
      },
    },
  ];

  return (
    <div
      className='absolute top-[90px] right-[-10px] bg-white shadow-lg border rounded-md p-4 text-sm z-50 flex'
      style={{ minWidth: "200px" }}
    >
      {/* Triangle */}
      <div className='absolute top-[-28px] right-[55px]'>
        <Polygon className='w-[30px] h-[30px]' />
      </div>

      {/* Left Section */}
      <div className='flex flex-col w-full space-y-4'>
        {items.map((item, index) => (
          <div
            key={index}
            className={`flex items-center space-x-2 text-primaryText hover:bg-gray-100 p-2 rounded-md cursor-pointer ${
              index < items.length - 1 ? "border-b" : ""
            }`}
            onClick={item.action}
          >
            <div className='text-lg'>{item.icon}</div>
            <div className='text-sm'>{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileDropDown;
