import React from 'react';
import './EventCard.css';

const EventCard = ({ title, subtitle, location, date, month, index }) => {
    // Apply bottom border only for the first two rows
    const hasBorderBottom = index < 2;

    return (
        <div 
            className={`flex bg-white ${hasBorderBottom ? 'border-b-2 border-gray' : ''}`}
            style={{ height: 'calc(3 * 3rem)' }} // Adjust this height according to your design requirements
        >
            <div className="flex-shrink-0 flex flex-col items-center justify-start p-2 event-card md:p-2 lg:p-6">
                <div className="w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 bg-[#3ACA2E] rounded-full flex items-center justify-center">
                    <p className="text-white md:mt-2 lg:mt-1 text-sm md:text-xs lg:text-sm font-bold">{month}</p>
                </div>
                <p className="text-xs md:text-sm lg:text-sm font-bold mt-1">{date}</p>
            </div>
            <div className="flex-1 p-2 md:p-2 lg:p-6">
                <p className="text-black text-base md:text-lg lg:text-lg font-bold mb-1 event-card-title leading-tight md:leading-normal lg:leading-[1.3]">
                    {title}
                </p>
                <p className="text-base md:text-sm lg:text-base mb-2 event-card-subtitle leading-tight md:mt-1 lg:mt-2">
                    {subtitle}
                </p>
                <div className="flex items-center gap-2 md:mt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#3ACA2E" className="w-4 h-4 md:w-5 md:h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                    <p className="text-base md:text-sm event-card-location lg:text-sm text-gray-700">{location}</p>
                </div>
            </div>
        </div>
    );
};

export default EventCard;
