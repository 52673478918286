import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // Import useSelector to access Redux state
import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";

const DashboardPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Use useSelector to access the collegeAuth data from Redux
  const collegeAuth = useSelector((state) => state.collegeAuth);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Log collegeAuth data when the component renders
  useEffect(() => {}, [collegeAuth]);

  return (
    <div className='flex h-screen'>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div
        className={`flex-1 flex flex-col transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : "ml-20"
        }`}
      >
        <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        <div className='flex-1 overflow-auto'>
          <div className='min-h-xs mt-12 relative z-0'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
