import React, { useState, useEffect, useMemo } from 'react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5';
import { FaSort } from 'react-icons/fa';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import { htmlToText } from 'html-to-text';
import { useSelector } from 'react-redux';

const AllPrograms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // Sorting state
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchPrograms = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}/programs`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in headers
          },
        });
        setPrograms(response.data);
      } catch (err) {
        toast.error('Failed to fetch Programs');
      } finally {
        setLoading(false);
      }
    };
    fetchPrograms();
  }, [token]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? (
        <IoArrowUpOutline />
      ) : (
        <IoArrowDownOutline />
      );
    }
    return <FaSort color="lightgrey" />;
  };

  const sortedPrograms = useMemo(() => {
    let sortablePrograms = [...programs];
    if (sortConfig.key) {
      sortablePrograms.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePrograms;
  }, [programs, sortConfig]);

  const filteredEntries = sortedPrograms.filter((program) =>
    program.program_master_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredEntries.length / entriesPerPage);

  const displayedPrograms = filteredEntries.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this program?');
    if (!confirmDelete) return;

    try {
      await axios.put(
        `${Base_URL}/update/delete/university_programs/${id}`,
        {}, // Empty body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in headers
          },
        }
      );
      toast.success('Program deleted successfully');
      setPrograms((prev) => prev.filter((program) => program.id !== id));
    } catch (err) {
      toast.error('Failed to delete program');
    }
  };

  return (
    <div className="bg-gray-100 p-6 min-h-screen overflow-auto">
      <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
      <div className="bg-white p-4 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
          <h2 className="text-xl font-semibold mb-4">All Programs</h2>
          <button
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
            onClick={() =>
              navigate('/admin/programs/add', { state: { subTitle: 'Add Programs', mainTitle: 'Program and Courses' } })
            }
          >
            + Add New
          </button>
        </div>


        <div className="flex justify-between items-center mb-4">
  {/* Total Count */}
  <div className="text-sm font-semibold text-tableTextColor">
    Total Programs: <span className="font-semibold">{filteredEntries.length}</span>
  </div>

  {/* Search Input */}
  <div className="flex items-center text-gray-600">
    <span className="mr-2 text-sm">Search:</span>
    <input
      type="text"
      className="px-3 py-1 border border-gray-300 rounded"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search by Program Name"
    />
  </div>
</div>

       

        {/* Table Headers with Sorting */}
        <div className="grid grid-cols-7 gap-2 font-semibold text-gray-600 bg-gray-100 p-3 rounded-md">
          <div onClick={() => handleSort('id')}>
            <div className="flex items-center space-x-1">
              <span>S.No</span>
              {getSortIcon('id')}
            </div>
          </div>
          <div onClick={() => handleSort('program_master_name')}>
            <div className="flex items-center space-x-1">
              <span>Program Name</span>
              {getSortIcon('program_master_name')}
            </div>
          </div>
          <div onClick={() => handleSort('university_name')}>
            <div className="flex items-center space-x-1">
              <span>University Name</span>
              {getSortIcon('university_name')}
            </div>
          </div>
          <div onClick={() => handleSort('created_on')}>
          <div className='flex items-center space-x-1'>
              <span> Created On</span>
              {getSortIcon('created_on')}
            </div>
              
            </div>
            <div onClick={() => handleSort('created_by')} >
            <div className='flex items-center space-x-1'>
              <span> Created By</span>
              {getSortIcon('created_by')}
            </div>
            </div>
          <div>Description</div>
          <div>Action</div>
        </div>

        {/* Table Body */}
        {displayedPrograms.length > 0 ? (
          displayedPrograms.map((program, index) => {
            const plainTextDetails = htmlToText(program.program_details, {
              wordwrap: 130,
            });

            return (
              <div
                key={program.id}
                className="grid grid-cols-7 gap-2 text-tableTextColor text-sm border-b border-gray-200 p-3 items-center hover:bg-gray-100"
              >
                <div>{index + 1}</div>
                <div className="">{program.program_master_name}</div>
                <div className="">{program.university_name}</div>
                <div>{program.created_on ?program.created_on.split('T')[0] : 'N/A'}</div>
                <div>{program.user_name}</div>
                <div className="truncate">
                  <p className="line-clamp-2">{plainTextDetails}</p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() =>
                      navigate(`/admin/programs/edit/${program.id}`, { state: program })
                    }
                    className="bg-cmsgreen hover:bg-hovergreen text-white p-2 rounded"
                  >
                    <HiPencilAlt />
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded"
                    onClick={() => handleDelete(program.id)}
                  >
                    <HiTrash />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center py-4">No programs found</div>
        )}

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center">
            <span className="text-sm text-gray-600 mr-2">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-cmsgreen text-white rounded-md disabled:bg-hovergreen text-sm"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default AllPrograms;
