import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { Base_URL } from "../../../apiConfig";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const EditCity = () => {
  const navigate = useNavigate();
  const { cityId } = useParams();
  const location = useLocation();
  const { item, mainTitle, subTitle } = location.state || {};
  const [formData, setFormData] = useState({
    name: item?.name || "",
    state_id: item?.state_id || "",
  });
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.auth); // Access the token

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/state`);
        const stateOptions = response.data.map((state) => ({
          value: state.id,
          label: state.name,
        }));
        setStates(stateOptions);
      } catch (error) {
        console.error("Failed to fetch states:", error);
        toast.error("Failed to load state options.");
      }
    };

    fetchState();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStateChange = (selectedOption) => {
    setFormData({ ...formData, state_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const updatedCity = { ...formData };
      await axios.put(`${Base_URL}/update/city/${cityId}`, updatedCity, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`, // Use token for authorization
        },
      });
      toast.success("City updated successfully!");
      setTimeout(() => {
        navigate("/admin/universityMaster/allMaster", {
          state: { selectedType: "city" },
        });
      }, 2000);
    } catch (error) {
      toast.error("Failed to update city.");
      console.error("Failed to update city:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 h-screen'>
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label htmlFor='name' className='mb-2 font-medium text-sm'>
                  Edit City
                </label>
                <input
                  type='text'
                  id='name'
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  required
                />
              </div>

              <div className='flex flex-col'>
                <label htmlFor='state' className='mb-2 font-medium text-sm'>
                  Select State
                </label>
                <Select
                  options={states}
                  value={states.find((state) => state.value === formData.state_id)}
                  onChange={handleStateChange}
                  placeholder={"Select State"}
                  className='basic-single'
                />
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            >
              {loading ? (
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Updating...
                </div>
              ) : (
                "Update City"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCity;
