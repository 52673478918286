import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as VerifiedGreen } from '../../../assets/cms/VerificationGreen.svg';
import { ReactComponent as VerifiedGrey } from '../../../assets/cms/VerificationGrey.svg';
import HeaderSection from '../headerSection/HeaderSection';
import './Details.css';

const LeadDetails = () => {
  const { state } = useLocation();
  const lead = state?.lead || {};
  const [current, setCurrent] = useState(lead.statusIndex || 0);
  const onChange = (value) => {
    if (value === current) {
    }
  };

  const steps = [
    { title: "Unverified", status: "Unverified", color: "#5BDE50" },
    { title: "Verified", status: "Verified", color: "#5BDE50" },
    { title: "Payment Approved", status: "Payment Approved", color: "#5BDE50" },
    { title: "Enrolled", status: "Enrolled", color: "#5BDE50" },
  ];

  return (
    <div className='p-6 bg-gray-100 min-h-screen'>
      <HeaderSection
        title='Lead Details'
        breadcrumb={["Lead Management", "Lead Details"]}
      />

      <div className='flex flex-col lg:flex-row justify-between mb-6 space-y-6 lg:space-y-0 lg:space-x-6'>
        <div className='flex-1 bg-white rounded-lg shadow-md p-4'>
          <h2 className='text-lg font-semibold mb-2'>{lead.name}</h2>

          <p className='text-black '>
            <span className='font-semibold'>Email:</span>
            {lead.email}
          </p>
          <p className='text-black'>
            <span className='font-semibold'>Mobile:</span>
            {lead.mobile}
          </p>
          <p className='text-black'>
            <span className='font-semibold'>Added on:</span>{" "}
            {lead.addedOn || "13 Oct 2024 06.23 PM"}
          </p>
          <p className='text-black'>
            <span className='font-semibold'>Last Contacted:</span>{" "}
            {lead.lastContacted || "14 Oct 2024 10.23 AM"}
          </p>
        </div>
        <div className='flex-1'>
          <div className='rounded-t-lg bg-red-500 justify-center p-2 flex items-center shadow-md '>
            {" "}
          </div>

          <div className='flex-1 bg-white rounded-b-lg  shadow-md p-4 text-center'>
            <h2 className='text-3xl font-semibold text-black py-4 rounded-lg'>
              {lead.score || "8"}
            </h2>
            <p className='text-black mt-2 text-3xl  mb-4 font-semibold'>
              Lead Score
            </p>
          </div>
        </div>

        <div className='flex-1 bg-white rounded-lg shadow-md p-4 flex flex-col justify-evenly'>
          <h3 className='text-lg font-semibold mb-2'>Communication Status</h3>
          <div>
            <p className='text-black'>
              {" "}
              <span className='font-semibold'>Assigned Owner -</span>{" "}
              {lead.assignedOwner || "Hamza"}
            </p>
            <p className='text-black'>
              {" "}
              <span className='font-semibold'> Email Sent 📧 -</span>{" "}
              {lead.emailsSent || "1"}
            </p>
            <p className='text-black'>
              <span className='font-semibold'>SMS Sent 📞 - </span>
              {lead.smsSent || "0"}
            </p>
          </div>

          <div>
            <p className='text-black'>
              <span className='font-semibold'> Upcoming Follow-up: </span>{" "}
              {lead.followUp || "15 Oct 2024 10.00 AM"}
            </p>
          </div>
        </div>
      </div>

      <div className='flex  justify-center mb-6 space-x-4'>
        {steps.map((step, index) => (
          <div key={index} className='flex  flex-col items-center'>
            {index <= current ? (
              <VerifiedGreen className='' />
            ) : (
              <VerifiedGrey className='' />
            )}
            <div className=' relative bottom-11 right-2 '>
              <p className={`font-semibold text-black font-semibold `}>
                {step.title}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className='bg-white rounded-lg shadow-md p-4  '>
        <h3 className='text-lg font-semibold mb-4'>Lead Details</h3>
        <p className='text-black'>
          <span className='font-semibold'>Email Address:</span>{" "}
          {lead.email || "********@gmail.com"}
        </p>
        <p className='text-black'>
          {" "}
          <span className='font-semibold'>Mobile Number:</span>{" "}
          {lead.mobile || "+91 **********"}
        </p>
        <p className='text-black'>
          <span className='font-semibold'>Alternate Mobile Number:</span>{" "}
          {lead.alternateMobile || "+91 **********"}
        </p>
        <p className='text-black'>
          <span className='font-semibold'>Name: </span>
          {lead.name || "Lead Name"}
        </p>
        <p className='text-black'>
          <span className='font-semibold'>State:</span>{" "}
          {lead.state || "Jammu & Kashmir"}
        </p>
        <p className='text-black'>
          <span className='font-semibold'>City:</span> {lead.city || "Badran"}
        </p>
        <p className='text-black'>
          <span className='font-semibold'>Course:</span>{" "}
          {lead.course || "B.E - Chemical Engineering"}
        </p>
      </div>
    </div>
  );
};

export default LeadDetails;
