import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import user1 from '../../../assets/user1.png';
import ReviewCard from '../../College/ReviewCard/ReviewCard';

const Reviews = () => {
    const sliderRef = useRef(null);

    const goToPrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev(); // Navigate to the previous slide
        }
    };

    const goToNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext(); // Navigate to the next slide
        }
    };

    const SamplePrevArrow = ({ className, style }) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="#000000"
                className={`${className} absolute left-2 top-1/2 transform -translate-y-1/2 z-10`}
                style={{ ...style, display: "block", cursor: "pointer" }}
                onClick={goToPrev}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        );
    };

    const SampleNextArrow = ({ className, style }) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="#000000"
                className={`${className} absolute right-2 top-1/2 transform -translate-y-1/2 z-10`}
                style={{ ...style, display: "block", cursor: "pointer" }}
                onClick={goToNext}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
        );
    };

    const settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 3, // Default for larger screens
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024, // Adjust behavior for tablets between 768-1024px
            settings: {
              slidesToShow: 2,
             
            },
          },
          {
            breakpoint: 768, // Adjust behavior for smaller mobile screens
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
      
    
    
    const reviews = [
        {
            name: "Vansh",
            username: "Vansh_1",
            profile_img: user1,
            review: "My experience with College Dwarka has been nothing short of excellent. They provided personalized advice that helped me choose the perfect college and set me on the path for success. Their internship opportunities are great and have given me a competitive edge in the job market!"
        },
        {
            name: "Sudhanshu ",
            username: "Sudhanshu",
            profile_img: user1,
            review: "If you’re a student looking to advance your academic or career goals, College Dwarka is the perfect guide. Their attention to detail, experience in the industry, and network of opportunities have truly set me on the path toward a successful career. Thank you for all the support!"
        },
        {
            name: "Diksha ",
            username: "Diksha",
            profile_img: user1,
            review: "College Dwarka offers amazing services! They have the best resources to help students choose their dream colleges, find quality internships, and land the best job opportunities. Their support system is strong, and they care about their students' futures. I'm beyond grateful!"
        },
        {
            name: "Hamza ",
            username: "Hamza",
            profile_img: user1,
            review: "College Dwarka is an absolute game-changer for students seeking guidance. Their expertise in helping students find the best colleges suited to their aspirations is unmatched. They also have incredible resources for securing internships and job placements. Highly recommend!"
        },
    ];

    return (
        <div className="px-5 pt-10 pb-14 bg-[#F2FCEB] relative">
            <p className="text-center md:text-lg mb-2 font-[500] text-[#666666]">STUDENT EXPERIENCE</p>
            <p className="text-black text-xl text-center font-medium mb-10">
                Real Voices, Real Stories: See How Our Students Are Shaping Their Futures!
            </p>

            <Slider ref={sliderRef} {...settings}>
                {reviews.map((review, index) => (
                    <div key={index} className="p-5 md:p-10">
                        <ReviewCard
                            name={review.name}
                            username={review.username}
                            profile_img={review.profile_img}
                            review={review.review}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Reviews;
