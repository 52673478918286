import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top on route change or page refresh
    window.scrollTo(0, 0);
  }, [pathname]);

  // Add event listener for browser back/forward buttons
  useEffect(() => {
    const handlePopState = () => {
      window.scrollTo(0, 0);
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return null;
};

export default ScrollToTop; 