// src/store/masterDataSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MasterDataService from '../../api/services/MasterDataService';

// Async thunks for fetching data
export const fetchDegrees = createAsyncThunk('masterData/fetchDegrees', async () => {
  return await MasterDataService.fetchDegrees();
});

export const fetchCities = createAsyncThunk('masterData/fetchCities', async () => {
  return await MasterDataService.fetchCities();
});

export const fetchStates = createAsyncThunk('masterData/fetchStates', async () => {
  return await MasterDataService.fetchStates();
});

export const fetchSpecializations = createAsyncThunk('masterData/fetchSpecializations', async () => {
  return await MasterDataService.fetchSpecializations();
});

// Master data slice
const masterDataSlice = createSlice({
  name: 'masterData',
  initialState: {
    degrees: [],
    cities: [],
    states: [],
    specializations: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDegrees.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDegrees.fulfilled, (state, action) => {
        state.degrees = action.payload;
        state.loading = false;
      })
      .addCase(fetchDegrees.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      // Repeat for other fetch actions
      .addCase(fetchCities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.loading = false;
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.states = action.payload;
        state.loading = false;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchSpecializations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSpecializations.fulfilled, (state, action) => {
        state.specializations = action.payload;
        state.loading = false;
      })
      .addCase(fetchSpecializations.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default masterDataSlice.reducer;
