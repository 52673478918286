import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CollegeListService from "../../api/services/CollegeListService"; // Import the service

// Async thunk to fetch featured colleges
export const getFeaturedColleges = createAsyncThunk(
  "colleges/fetchFeatured",
  async (streamId, { rejectWithValue }) => {
    try {
      const data = await CollegeListService.FeaturedColleges();
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch featured colleges"
      );
    }
  }
);

// Async thunk to fetch popular colleges
export const getPopularColleges = createAsyncThunk(
  "colleges/fetchPopular",
  async (_, { rejectWithValue }) => {
    try {
      const data = await CollegeListService.PopularColleges();
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch popular colleges"
      );
    }
  }
);

// Async thunk to fetch all colleges
export const getAllColleges = createAsyncThunk(
  "colleges/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const data = await CollegeListService.AllColleges();
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch all colleges"
      );
    }
  }
);

const collegesSlice = createSlice({
  name: "colleges",
  initialState: {
    featuredColleges: [],
    popularColleges: [],
    allColleges: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Featured Colleges
    builder
      .addCase(getFeaturedColleges.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFeaturedColleges.fulfilled, (state, action) => {
        state.loading = false;
        state.featuredColleges = action.payload;
      })
      .addCase(getFeaturedColleges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Popular Colleges
    builder
      .addCase(getPopularColleges.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPopularColleges.fulfilled, (state, action) => {
        state.loading = false;
        state.popularColleges = action.payload;
      })
      .addCase(getPopularColleges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // All Colleges
    builder
      .addCase(getAllColleges.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllColleges.fulfilled, (state, action) => {
        state.loading = false;
        state.allColleges = action.payload;
      })
      .addCase(getAllColleges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default collegesSlice.reducer;
