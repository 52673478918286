import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; // Import Redux
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import HeaderSection from '../headerSection/HeaderSection';

const AddNews = () => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    announcement_date: "",
  });
  const [picture, setPicture] = useState(null);
  const [news, setNews] = useState([]);
  const [imageName, setImageName] = useState("No file chosen");
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false); // Track loading state

  // Fetch login_id from Redux
  const loginData = useSelector((state) => state.collegeAuth?.data);
  const login_id = loginData?.login_id || 1;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${Base_URL}/news-announcements/university/1`
        );

        setNews(response.data);
      } catch (error) {
        console.error("Error fetching News:", error);
      }
    };

    fetchNews();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageName(file.name);
    } else {
      setImageName("No file chosen");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const data = new FormData();
      data.append("university_id", login_id); // Use login_id dynamically
      // Append other form data
      for (const key in formData) {
        data.append(key, formData[key]);
      }
      // Append image file if it exists
      if (image) {
        data.append("picture", image);
      }

      const response = await axios.post(
        `${Base_URL}/news-announcements`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const imageUrl = response.data.picture;
      if (imageUrl) {
      } else {
        console.error("Image URL not found in the response");
      }

      toast.success("News added successfully!");
      setFormData({
        title: "",
        content: "",
        announcement_date: "",
        university_id: login_id,
      });
      setImage(null);
      setImageName("No file chosen");
      setError(null);
      setLoading(false); // Stop loading after success
    } catch (error) {
      console.error(
        "Failed to add news:",
        error.response?.data || error.message
      );
      toast.error("Failed to add news. Please try again.");
      setLoading(false); // Stop loading after error
    }
  };

  return (
    <div className='bg-gray-100 p-6 rounded-lg shadow-md h-screen'>
      <HeaderSection title='Add News' breadcrumb={["News", "Add News"]} />
      <div className='border border-gray-300 bg-white p-4 rounded-md'>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex flex-col'>
              <label
                htmlFor='title'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Title
              </label>
              <input
                type='text'
                id='title'
                name='title'
                value={formData.title}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>
            <div className='flex flex-col'>
              <label
                htmlFor='content'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Content
              </label>
              <textarea
                id='content'
                name='content'
                value={formData.content}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>
            <div className='flex flex-col'>
              <label
                htmlFor='announcement_date'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Announcement Date
              </label>
              <input
                type='date'
                id='announcement_date'
                name='announcement_date'
                value={formData.announcement_date}
                onChange={handleChange}
                className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                required
              />
            </div>
            <div className='flex flex-col'>
              <label
                htmlFor='picture'
                className='text-[#312a2a] font-poppins font-[500] text-[0.875rem]'
              >
                Upload Picture
              </label>
              <div className='flex border border-gray-300 rounded-md mt-2 overflow-hidden'>
                <label className='flex items-center justify-center bg-gray-200 text-gray-700 px-4 py-2 cursor-pointer text-[12.25px]'>
                  <span>Choose File</span>
                  <input
                    type='file'
                    id='picture'
                    name='picture'
                    onChange={handlePictureChange}
                    className='hidden'
                  />
                </label>
                <div className='flex-grow border-l border-gray-300 p-2 text-gray-700 flex items-center justify-center text-[12.25px]'>
                  {imageName || "No file chosen"}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button with spinner */}
          <button
            type='submit'
            className='mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md  flex items-center justify-center'
            disabled={loading}
          >
            {loading ? (
              <div className='flex justify-center items-center'>
                <svg
                  className='animate-spin h-5 w-5 text-white mr-3'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              "Submit"
            )}
          </button>

          {error && <div className='text-red-600 mt-2'>{error}</div>}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddNews;
