export const API_ENDPOINT = {
    FEATURED_COLLEGES: "/universities/featured/colleges",
    ALL_COLLEGES: "/universities",
    POPULAR_COLLEGES: "/universities/popular/colleges",
    NEWS_ANNOUNCEMENTS: "/get/news_announcements",
    LOGIN: "/auth/login",
    SIGNUP: "/auth/signup",
    STATE: "/get/state",
    CITY: "/get/city",
    PROGRAM: "/get/program_master",
    SPECIALIZATION: "/get/sub_courses_master",
    FILTER_COLLEGES: "/universities/filter/name",
    FILTER_EXAM: "/exam-master/filter/name",
    MODE_MASTER: "/get/mode_master",
    LEVEL_MASTER: "/get/level_master",
    STREAMS: "/get/streams",
    EXAM_MASTER:"/get/exam_master",
    JOIN_WHERE:'/get-join-where',
     COLLEGE_BY_NAME: "/universities/name",
     REFER_AND_EARN :"/create/refer_and_earn",
     FILTER_COURSES: "/course-master/filter/name",
     COURSE_DETAILS: "/course-master/details",
      DURATION_MASTER: "/get/duration_master"
  };
  