import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'; // Import useSelector

const AddCity = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth); // Retrieve token from Redux store
  const [formData, setFormData] = useState({
    name: '',
    state_id: '' 
  });
  const [states, setStates] = useState([]); // To store the fetched state values
  const [loading, setLoading] = useState(false); // Track loading state
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    // Fetch state data from the API
    const fetchState = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/state`);
        const stateOptions = response.data.map((state) => ({
          value: state.id, 
          label: state.name, 
        }));
        setStates(stateOptions);
      } catch (error) {
        console.error('Failed to fetch states:', error);
        toast.error('Failed to load state options.');
      }
    };

    fetchState();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleStateChange = (selectedOption) => {
    setFormData({ ...formData, state_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting
    try {
      const newCity = { ...formData };
      await axios.post(`${Base_URL}/create/city`, newCity, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });
      toast.success('City added successfully!');
    } catch (error) {
      toast.error('Failed to add city.');
      console.error('Failed to add city:', error.response?.data || error.message);
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="name" className="mb-2 font-medium text-sm">Create City</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="name" className="mb-2 font-medium text-sm">Select State</label>
                <Select
                  options={states} 
                  onChange={handleStateChange}
                  placeholder={'Select State'}
                  className="basic-single"
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
              {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Add City'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCity;
