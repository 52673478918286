import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from "react-redux";

const EditCredentials = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const credentials = location.state; // Get credentials data passed from AllCredentials

  const [formData, setFormData] = useState({
    email: credentials?.email || '',
    password: credentials?.password || '',
    phone_number: credentials?.phone_number || '',
    status: credentials?.status || 'active',
  });
  const [loading, setLoading] = useState(false); // Track loading state
  const { registration_id, token,name } = useSelector((state) => state.auth);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    try {
      await axios.put(`${Base_URL}/credentials/${credentials.id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
        withCredentials: true // This allows cookies to be sent with the request
      });
      toast.success('Credentials updated successfully!');
      navigate('/admin/credentials/all'); // Navigate back to the list after success
    } catch (error) {
      toast.error('Failed to update credentials.');
      console.error('Failed to update credentials:', error.response?.data || error.message);
      setLoading(false)
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle="Credentials" subTitle="Edit Credentials" />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="email" className="mb-2 font-medium text-sm">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="password" className="mb-2 font-medium text-sm">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="phone_number" className="mb-2 font-medium text-sm">Phone Number</label>
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="status" className="mb-2 font-medium text-sm">Status</label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
              {loading ? ( // Show spinner when loading
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Update Credentials'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCredentials;