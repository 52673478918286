import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { htmlToText } from "html-to-text";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Img_URL } from "../../../apiConfig";
import college_logo from "../../../assets/college_logo.png";
import CU_logo from "../../../assets/landing/CU_logo.png";
import GE_logo from "../../../assets/landing/GE_logo.png";
import LPU_logo from "../../../assets/landing/LPU_logo.png";
import Tula_logo from "../../../assets/landing/Tula_logo.png";
import { getFeaturedColleges } from "../../../redux/slice/collegesSlice";
import "./top-colleges.css";

const logoMap = {
  1: LPU_logo,
  2: Tula_logo,
  3: GE_logo,
  4: CU_logo,
};

const TopColleges = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sliderRef = useRef(null); // Added ref for Slider

  // Access Redux state for colleges
  const { featuredColleges, loading, error } = useSelector(
    (state) => state.colleges
  );

  useEffect(() => {
    dispatch(getFeaturedColleges());
  }, [dispatch]);

  const getImageUrl = (universityId) => {
    return logoMap[universityId] || college_logo;
  };

  const handleExploreClick = (college) => {
    const universityNameSlug = encodeURIComponent(
      college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
    );
    navigate(`/college/${universityNameSlug}`);
  };

  const handleViewMoreClick = () => {
    const featuredCollege = featuredColleges.filter(
      (college) => college.is_active === 1
    );
    navigate("/colleges/featured-colleges", {
      state: { featuredCollege, isFeatured: true },
    });
  };

  const goToPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev(); // Navigate to the previous slide
    }
  };

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Navigate to the next slide
    }
  };
  const SamplePrevArrow = ({ className, style }) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='3'
        stroke='#000000'
        className={`${className} absolute left-2 top-1/2 transform -translate-y-1/2 z-10`}
        style={{ ...style, display: "block", cursor: "pointer" }}
        onClick={goToPrev}
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15.75 19.5 8.25 12l7.5-7.5'
        />
      </svg>
    );
  };

  const SampleNextArrow = ({ className, style }) => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth='3'
        stroke='#000000'
        className={`${className} absolute right-2 top-1/2 transform -translate-y-1/2 z-10`}
        style={{ ...style, display: "block", cursor: "pointer" }}
        onClick={goToNext}
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M8.25 4.5l7.5 7.5-7.5 7.5'
        />
      </svg>
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className='py-8 px-8'>
      <p className='text-center text-[28px] md:text-[36px] font-sans font-[700] mb-5'>
        Top Featured Colleges
      </p>
      <p className='text-center text-[16px] md:text-[24px] font-sans mb-5'>
        Discover our selection of top colleges and universities renowned for
        their excellence and innovation.
      </p>
      <div className='py-8 flex justify-center relative'>
        <div className='w-full md:w-[90%] lg:w-[90%]'>
          {loading ? (
            <p>Loading colleges...</p>
          ) : error ? (
            <p>Error loading colleges: {error.message}</p>
          ) : featuredColleges &&
            featuredColleges.filter((college) => college.is_active === 1)
              .length > 0 ? (
            <div className='relative'>
              <Slider {...settings} ref={sliderRef}>
                {featuredColleges
                  .filter((college) => college.is_active === 1)
                  .map((college, index) => {
                    const imageUrl = getImageUrl(college.university_id);

                    return (
                      <div
                        key={index}
                        className='flex justify-center items-center mt-6 md:ml-7'
                      >
                        <div className='flex items-center md:flex-row flex-col'>
                          <div className='w-[350px] h-[350px] flex-shrink-0 rounded-[20px] overflow-hidden hidden md:block border-4 border-gray-200'>
                            <img
                              src={`${Img_URL}${college.pictures[0]}`}
                              alt={college.university_name || "College Image"}
                              className='w-full h-full object-cover'
                              onError={(e) => {
                                e.target.src = college_logo;
                              }}
                            />
                          </div>
                          <div className='w-[300px] h-[300px] flex-shrink-0 rounded-[20px] overflow-hidden block md:hidden border-4 border-gray-200'>
                            <img
                              src={`${Img_URL}${college.pictures[0]}`}
                              alt={college.university_name || "College Image"}
                              className='w-full h-full object-cover'
                              onError={(e) => {
                                e.target.src = college_logo;
                              }}
                            />
                          </div>

                          <div className='mb-[10px] overflow-hidden justify-evenly p-5 flex flex-col gap-3 bg-[#F2FCEB] rounded-2xl shadow-lg md:transform md:-translate-x-14 -mt-3.5 md:relative relative md:mt-0 md:w-[90%] w-[278.51px] h-[267px] shadow-custom'>
                            <p className='text-sm font-bold md:text-2xl'>
                              {college.university_name ||
                                "University Name Not Available"}
                            </p>
                            <p className='text-[#757575] font-medium overflow-hidden text-[11px] md:text-lg'>
                              {(() => {
                                const intro =
                                  college.sub_intro ||
                                  "Introduction not available.";
                                const words = htmlToText(intro, {
                                  wordwrap: 130,
                                }).split(" ");
                                const slicedIntro = words
                                  .slice(0, 30)
                                  .join(" ");
                                return words.length > 30
                                  ? `${slicedIntro}...`
                                  : slicedIntro;
                              })()}
                            </p>
                            <div>
                              <button
                                className='py-[3px] px-5 bg-[#3ACA2E] h-[30px] w-[232px] text-white md:w-[150px] md:h-[40px] font-medium rounded-[20px]'
                                onClick={() => handleExploreClick(college)}
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          ) : (
            <p>No colleges available at the moment.</p>
          )}
        </div>
      </div>
      <p
        className='text-[#57CC02] font-dm-sans sm:text-[12px] md:text-[10px] lg:text-[16px] font-[500] leading-[20.83px] cursor-pointer text-center flex justify-center mt-4'
        onClick={handleViewMoreClick}
      >
        View More
      </p>
    </div>
  );
};

export default TopColleges;
