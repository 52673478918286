import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderTitle from "../../dashboard/HeaderTitle";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../apiConfig";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux"; // Import useSelector

const EditExamSectionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const details = location.state;

  const [formData, setFormData] = useState({
    exam_master_name: details?.exam_master_name || "",
    exam_master_id: details?.exam_master_id,
    overview: details?.overview,
    syllabus: details?.syllabus,
    exam_pattern: details?.exam_pattern,
    eligibility: details?.eligibility,
    how_to_prepare: details?.how_to_prepare,
    application_form: details?.application_form,
    admit_card: details?.admit_card,
    prev_year_paper: details?.prev_year_paper,
    counselling_process: details?.counselling_process,
    stream_id: details?.stream_id,
    mode_id: details?.mode_master_id,
    level_id: details?.level_master_id,
  });

  const [loading, setLoading] = useState(false); // Track loading state
  const [exams, setExams] = useState([]);
  const [streams, setStreams] = useState([]);
  const [modes, setModes] = useState([]);
  const [levels, setLevels] = useState([]);

  const { token } = useSelector((state) => state.auth); // Retrieve token from Redux store

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const examResponse = await axios.get(`${Base_URL}/get/exam_master`);
        setExams(
          examResponse.data.map((exam) => ({
            value: exam.id,
            label: exam.exam_master_name,
          }))
        );

        const streamResponse = await axios.get(`${Base_URL}/get/streams`);
        setStreams(
          streamResponse.data.map((stream) => ({
            value: stream.id,
            label: stream.stream_name,
          }))
        );

        const modeResponse = await axios.get(`${Base_URL}/get/mode_master`);
        setModes(
          modeResponse.data.map((mode) => ({
            value: mode.id,
            label: mode.mode_title,
          }))
        );

        const levelResponse = await axios.get(`${Base_URL}/get/level_master`);
        setLevels(
          levelResponse.data.map((level) => ({
            value: level.id,
            label: level.level_name,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch options:", error);
        toast.error("Failed to load options.");
      }
    };

    fetchOptions();
  }, []);

  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption.value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    const payload = { ...formData };
    delete payload.exam_master_name;

    try {
      await axios.put(
        `${Base_URL}/update/exam_master_description/${details.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`, // Add token to authorization header
          },
          withCredentials: true, // Enable withCredentials
        }
      );
      toast.success("Details updated successfully!");
      setTimeout(() => {
        navigate("/admin/exams/allSectionDetails");
      }, 2000);
    } catch (error) {
      toast.error("Failed to update Details.");
      console.error(
        "Failed to update Details:",
        error.response?.data || error.message
      );
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className='bg-gray-100 p-6 h-screen'>
        <HeaderTitle mainTitle='Exams' subTitle='Edit Level' />
        <div className='bg-white p-4 rounded-md'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label htmlFor='exam' className='mb-2 font-medium text-sm'>
                  Exam Name
                </label>
                <input
                  type='text'
                  id='exam'
                  name='exam_master_name'
                  value={formData.exam_master_name}
                  onChange={handleInputChange}
                  className='border border-gray-300 p-2 rounded'
                  disabled
                />
              </div>

              {/* Stream Dropdown */}
              <div className='flex flex-col'>
                <label htmlFor='stream' className='mb-2 font-medium text-sm'>
                  Select Stream
                </label>
                <Select
                  options={streams}
                  value={
                    streams.find((s) => s.value === formData.stream_id) || null
                  }
                  onChange={handleSelectChange("stream_id")}
                  placeholder='Select Stream'
                  className='basic-single'
                />
              </div>

              {/* Mode Dropdown */}
              <div className='flex flex-col'>
                <label htmlFor='mode' className='mb-2 font-medium text-sm'>
                  Select Mode
                </label>
                <Select
                  options={modes}
                  value={
                    modes.find((m) => m.value === formData.mode_id) || null
                  }
                  onChange={handleSelectChange("mode_id")}
                  placeholder='Select Mode'
                  className='basic-single'
                />
              </div>

              {/* Level Dropdown */}
              <div className='flex flex-col'>
                <label htmlFor='level' className='mb-2 font-medium text-sm'>
                  Select Level
                </label>
                <Select
                  options={levels}
                  value={
                    levels.find((l) => l.value === formData.level_id) || null
                  }
                  onChange={handleSelectChange("level_id")}
                  placeholder='Select Level'
                  className='basic-single'
                />
              </div>

              {/* CKEditor fields remain the same */}
              {/* Overview */}
              <div className='flex flex-col md:col-span-2'>
                <label htmlFor='overview' className='mb-2 font-medium text-sm'>
                  Exam Overview
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.overview}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      overview: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label htmlFor='syllabus' className='mb-2 font-medium text-sm'>
                  Exam Syllabus
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.syllabus}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      syllabus: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label
                  htmlFor='exam_pattern'
                  className='mb-2 font-medium text-sm'
                >
                  Exam Pattern
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.exam_pattern}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      exam_pattern: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label
                  htmlFor='eligibility'
                  className='mb-2 font-medium text-sm'
                >
                  Eligibility Details
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.eligibility}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      eligibility: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label
                  htmlFor='how_to_prepare'
                  className='mb-2 font-medium text-sm'
                >
                  How to Prepare
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.how_to_prepare}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      how_to_prepare: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label
                  htmlFor='application_form'
                  className='mb-2 font-medium text-sm'
                >
                  Application Form
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.application_form}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      application_form: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label
                  htmlFor='admit_card'
                  className='mb-2 font-medium text-sm'
                >
                  Admit Card
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.admit_card}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      admit_card: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label
                  htmlFor='prev_year_papers'
                  className='mb-2 font-medium text-sm'
                >
                  Previous Year Papers
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.prev_year_paper}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      prev_year_paper: editor.getData(),
                    }))
                  }
                />
              </div>

              <div className='flex flex-col md:col-span-2'>
                <label
                  htmlFor='counselling_process'
                  className='mb-2 font-medium text-sm'
                >
                  Counselling Process
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.counselling_process}
                  onChange={(event, editor) =>
                    setFormData((prev) => ({
                      ...prev,
                      counselling_process: editor.getData(),
                    }))
                  }
                />
              </div>
            </div>

            <button
              type='submit'
              className='mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen'
            >
              {loading ? (
                <div className='flex justify-center items-center'>
                  <svg
                    className='animate-spin h-5 w-5 text-white mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                "Update Exam Details"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditExamSectionDetails;
