import React, { useEffect, useState } from 'react';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpertHelp from '../Forms/ExpertHelp';
const AboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* Banner Section */}
      <section className="flex py-8 mt-[40px] border-b-2 border-gray-200 bg-[rgba(0,0,0,0.5)] items-center justify-center text-2xl text-white font-bold"
      >
        About College Dwarka
      </section>

     {/* Founders Section */}
      {/* <section className="text-center py-10">
        <h2 className="text-2xl font-medium mb-6">OUR FOUNDERS</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden p-4">
              <img src="/path/to/image.jpg" alt="Founder" className="w-full h-48 object-cover" />
              <h3 className="text-lg font-semibold mt-4">Name</h3>
              <p className="text-gray-600">Founder / Co-Founder</p>
            </div>
          ))}
        </div>
      </section> */}

      {/* Our Story Section */}
      <section className="bg-[#DBFDC3] p-8 mt-20">
        <h2 className="text-center text-2xl font-medium mb-4">OUR STORY</h2>
        <p className="text-left text-2xl text-gray-700 max-w-2xl mx-auto">
          Welcome to College Dwarka – Your Guide from College Admissions to Career Success!
          College Dwarka is an all-in-one platform designed to empower students in finding the right college, gaining essential skills, and securing meaningful careers. Our mission is to make higher education accessible to students across India, from remote locations to urban centers, offering everything needed to navigate the path from admission to professional success.
        </p>
      </section>

      {/* Team Section */}
      {/* <section className="text-center py-10">
        <h2 className="text-2xl font-medium mb-6">Meet Our Team</h2>
        <div className="flex overflow-x-scroll space-x-4 p-4">
          {[1, 2, 3, 4].map((_, index) => (
            <div key={index} className="min-w-[200px] bg-white shadow-lg rounded-lg overflow-hidden p-4">
              <img src="/path/to/image.jpg" alt="Team Member" className="w-full h-48 object-cover" />
              <h3 className="text-lg font-semibold mt-4">Name</h3>
              <p className="text-gray-600">Designation</p>
            </div>
          ))}
        </div>
      </section> */}

      {/* Offerings Section */}
      <section className="bg-[#DBFDC3] p-8  mt-20">
        <h2 className="text-center text-2xl text-textGray font-medium mb-6">Our Offerings</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
          <div className="">
            <h3 className="text-lg  text-textGray font-semibold mb-2">For Students</h3>
            <p className="text-gray-700">
  <ul className="list-disc list-inside space-y-2">
    <li>
      <strong>Find Your Ideal College</strong> <br />
      College Dwarka helps you discover the best colleges that match your academic goals and career aspirations, with in-depth information on programs, campus life, and scholarships.
    </li>
    <li>
      <strong>Internships and Skill-Building Training</strong> <br />
      Gain real-world experience through internships and continuous training programs designed to equip you with the skills needed in today’s job market.
    </li>
    <li>
      <strong>Personalized Webinars and Continuous Support</strong> <br />
      Stay connected through expert-led webinars and receive ongoing support from our team to ensure you’re on track during your college journey.
    </li>
    <li>
      <strong>Job Placement Assistance</strong> <br />
      We support you even after graduation, with resume-building, interview preparation, and job placement services to help you land your dream job.
    </li>
  </ul>
</p>

          </div>
          <div className="">
            <h3 className="text-lg text-textGray font-medium mb-2">For Colleges</h3>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
        <li>
          <strong>Advanced Admin Dashboard</strong> <br />
          College Dwarka provides an advanced admin dashboard to manage and showcase their offerings effectively.
        </li>
        <li>
          <strong>Enhanced Visibility</strong> <br />
          The platform enhances visibility, streamlines information management, and introduces new ways to attract and engage with prospective students.
        </li>
        <li>
          <strong>Showcase Unique Offerings</strong> <br />
          Institutions can effortlessly highlight their unique programs, campus life, and success stories, ensuring they stand out in the competitive educational landscape.
        </li>
      </ul>
          </div>
        </div>
      </section>


      

      {/* Life at CollegeDwarka Section */}
      {/* <section className="text-center py-10 font-medium">
        <h2 className="text-2xl font-bold mb-6">Life at CollegeDwarka</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
          <div className="h-32 bg-gray-300"></div>
        </div>
      </section> */}

        {/* Our Vision Section */}
        <section className="bg-[#DBFDC3] p-8 mt-20">
        <h2 className="text-center text-2xl text-textGray font-medium mb-4">Our Vision: Shaping Tomorrow’s Leaders</h2>
        <p className=" text-gray-700  text-left">
        At College Dwarka, we’re committed to empowering
         students not only as job seekers but as future leaders and entrepreneurs.
          Over the next two years, our vision is to provide in-depth technology and 
          entrepreneurship training to prepare students for a future where they can master technology and contribute to India’s growth in the tech industry.
        </p>
        
       <h3 className="mt-5 text-textGray"> Why Choose College Dwarka?</h3>
       <p className="text-gray-700 text-left">
  <ul className="list-disc list-inside space-y-2">
    <li>
      <strong>End-to-End Support:</strong> We guide students from choosing a college to building a career.
    </li>
    <li>
      <strong>Scholarship Opportunities:</strong> Helping economically disadvantaged students overcome financial barriers.
    </li>
    <li>
      <strong>Industry Connections:</strong> Connecting you with internships and employers for career success.
    </li>
    <li>
      <strong>Advanced Tech Features:</strong> Including virtual tours, AI chat support, and interactive dashboards.
    </li>
  </ul>
  <p className="mt-4">
    College Dwarka is more than a platform—it’s a journey. Join us and discover a future filled with possibilities!
  </p>
</p>

      </section>

      {/* Contact Us Section */}
  
        {/* Call to Action Section */}
        <section className="flex mt-10 justify-center items-center p-8"
          style={{ background: 'linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)' }}>
          <p className="text-center text-lg font-medium">Get in touch with our Experts.</p>
          <button className="ml-4 text-white bg-[#3ACA2E] font-bold rounded-lg py-2 px-4 rounded" onClick={openModal}>
            Get Expert Help
          </button>
        </section>


      {/* Expert Help Modal */}
      {isModalOpen && (
        <ExpertHelp
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={() => {}}
        />
      )}
        <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        style={{
          zIndex: 10000,
          marginTop: "90px",
        }}
      />
    </div>
  );
};

export default AboutUs;