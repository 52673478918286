import React, { useEffect, useState } from 'react'
//import Rankings from './Rankings/Rankings'
import { htmlToText } from 'html-to-text'
import { Parser } from 'htmlparser2'
import CampusFacilities from './CampusFacilities/CampusFacilities'

const Overview =({ collegeDetails,logoPath }) =>{
  const [parsedContent, setParsedContent] = useState('');

  useEffect(() => {
    if (collegeDetails?.main_intro) {
      const parser = new Parser({
        onopentag(name) {
          // Handle opening tags if needed
        },
        ontext(text) {
          // Handle text content
          setParsedContent((prev) => prev + text);
        },
        onclosetag(tagname) {
          // Handle closing tags if needed
        },
      });

      parser.write(collegeDetails.main_intro);
      parser.end();
    }
  }, [collegeDetails]);

  return(
        <div className="overflow-hidden">
        <div className="bg-[#DCFFC4] px-5 py-10 w-full">
        <div className="flex flex-col items-center justify-center gap-3 px-5 py-2 md:flex-row">
          <div className="md:h-[80px] md:w-[80px] h-[50px] w-[50px] flex-shrink-0">
            <img src={logoPath} alt="college-logo" className="h-full w-full object-cover" />
          </div>
          <p className="text-[24px] text-black font-semibold text-center w-full md:w-auto md:text-left font-bold md:text-3xl">
          {(collegeDetails?.university_name)}
          </p>
        </div>
        
        <div className="py-5 text-black md:text-base text-left font-medium flex flex-col gap-5 w-full">
          <p>{parsedContent}</p>
         
        </div>
      </div>
      

            {/* Rankings */}
            {/* <Rankings /> */}
            <CampusFacilities collegeDetails={collegeDetails} />

          
<div className="py-10 px-5 bg-[#DCFFC4] text-black text-base font-medium flex mt-7 flex-col gap-5">
    <p>{htmlToText(collegeDetails?.sub_intro )}</p>
   
</div>
</div>
    )
}

export default Overview;

